import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../../API/api-urls";
import { apiCall } from "../../../API/index";
import { Enums } from "../../../helper/constants/enums";

export const getAllSettingsRoles = createAsyncThunk(
    "SettingsRoles/getAllSettingsRoles",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                CenterID: data.centerid ? data.centerid : null,
                RoleName: data.rolename ? data.rolename : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null
            }
        };

        return apiCall(`${apiUrls.settingsRoles.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getSettingsRolesAction = createAsyncThunk(
    "SettingsRoles/getSettingsRoles",
    async (data) => {
        let d = {
            Device: 1,
            Id: data.Id ? data.Id : 0,
            CenterID: data.centerID ? data.centerID : 0,
        };
        return apiCall(`${apiUrls.settingsRoles.getRoles}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateSettingsRolesAction = createAsyncThunk(
    "SettingsRoles/settingsRolesInsertUpdate",
    async (data) => {
        return apiCall(`${apiUrls.settingsRoles.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusSettingsRolesAction = createAsyncThunk(
    "SettingsRoles/changeStatusSettingsRoles",
    async (data) => {
        // let d = {
        //     Device: 1,
        //     Id: data.roleID,
        //     Statustype: 3
        //   };
        return apiCall(`${apiUrls.settingsRoles.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingSettingsRoles = createAsyncThunk(
    "SettingsRoles/getAllSortingSettingsRoles",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.settingsRoles.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);