

const apiUrls = {
    clients: {
        getAll: "client/getall",
        getClient: "client/get",
        insertupdate: "client/insertupdate",
        changeStatus: 'client/changestatus'
    },
    
    location: {
        getAll: "location/getall",
        getLocation: "location/get",
        insertupdate: "location/insertupdate",
        changeStatus: 'location/changestatus'
    },
    patients: {
        getAll: "patient/getall",
        getPatients: "patient/get",
        insertupdate: "patient/insertupdate",
        changeStatus: 'patient/changestatus'
    },
    partners: {
        getAll: "partners/getall",
        getPartners: "partners/get",
        insertupdate: "partners/insertupdate",
        changeStatus: 'partners/changestatus'
    },
    center: {
        getAll: "center/getall",
        getCenter: "center/get",
        insertupdate: "center/insertupdate",
        changeStatus: 'center/changestatus'
    },
    embryoPgtResult: {
        getAll: "settings/embryo/pgtresult/getall",
        getEmbryoPgtResult: "settings/embryo/pgtresult/get",
        insertupdate: "settings/embryo/pgtresult/insertupdate",
        changeStatus: 'settings/embryo/pgtresult/changestatus',
        updateDisplayOrder :"settings/embryo/pgtresult/updatedisplayorder"
    },
    embryoFreezeMethod : {
        getAll: "settings/embryo/freezemethod/getall",
        getFreezeMethod: "settings/embryo/freezemethod/get",
        insertupdate: "settings/embryo/freezemethod/insertupdate",
        changeStatus: 'settings/embryo/freezemethod/changestatus',
        updateDisplayOrder :"settings/embryo/freezemethod/updatedisplayorder"
    },
    embryoDeviceType : {
        getAll: "settings/embryo/devicetype/getall",
        getEmbryoDeviceType: "settings/embryo/devicetype/get",
        insertupdate: "settings/embryo/devicetype/insertupdate",
        changeStatus: 'settings/embryo/devicetype/changestatus',
        updateDisplayOrder :"settings/embryo/devicetype/updatedisplayorder"
    },

    embryoEggSource: {
        getAll: "settings/embryo/eggsource/getall",
        getEmbryoEggSource: "settings/embryo/eggsource/get",
        insertupdate: "settings/embryo/eggsource/insertupdate",
        changeStatus: 'settings/embryo/eggsource/changestatus',
        updateDisplayOrder :"settings/embryo/eggsource/updatedisplayorder"
    },

    embryoSpermSource: {
        getAll: "settings/embryo/spermsource/getall",
        getEmbryoSpermSource: "settings/embryo/spermsource/get",
        insertupdate: "settings/embryo/spermsource/insertupdate",
        changeStatus: 'settings/embryo/spermsource/changestatus',
        updateDisplayOrder :"settings/embryo/spermsource/updatedisplayorder"
    },

    embryoPgtLab: {
        getAll: "settings/embryo/pgtlab/getall",
        getEmbryoPgtLab: "settings/embryo/pgtlab/get",
        insertupdate: "settings/embryo/pgtlab/insertupdate",
        changeStatus: 'settings/embryo/pgtlab/changestatus',
        updateDisplayOrder :"settings/embryo/pgtlab/updatedisplayorder"
    },

    embryoCryoTech: {
        getAll: "settings/embryo/cryotech/getall",
        getEmbryoCryoTech: "settings/embryo/cryotech/get",
        insertupdate: "settings/embryo/cryotech/insertupdate",
        changeStatus: 'settings/embryo/cryotech/changestatus',
        updateDisplayOrder :"settings/embryo/cryotech/updatedisplayorder"
    },

    embryoBXTech: {
        getAll: "settings/embryo/bxtech/getall",
        getEmbryoBXTech: "settings/embryo/bxtech/get",
        insertupdate: "settings/embryo/bxtech/insertupdate",
        changeStatus: 'settings/embryo/bxtech/changestatus',
        updateDisplayOrder :"settings/embryo/bxtech/updatedisplayorder"
    },

    embryoTubingTech: {
        getAll: "settings/embryo/tubingtech/getall",
        getEmbryoTubingTech: "settings/embryo/tubingtech/get",
        insertupdate: "settings/embryo/tubingtech/insertupdate",
        changeStatus: 'settings/embryo/tubingtech/changestatus',
        updateDisplayOrder :"settings/embryo/tubingtech/updatedisplayorder"
    },
    oocyteOocyteStage: {
        getAll: "settings/oocyte/stage/getall",
        getOocyteStage: "settings/oocyte/stage/get",
        insertupdate: "settings/oocyte/stage/insertupdate",
        changeStatus: 'settings/oocyte/stage/changestatus',
        updateDisplayOrder :"settings/oocyte/stage/updatedisplayorder"
    },
    oocyteOocyteDay: {
        getAll: "settings/oocyte/day/getall",
        getOocyteDay: "settings/oocyte/day/get",
        insertupdate: "settings/oocyte/day/insertupdate",
        changeStatus: 'settings/oocyte/day/changestatus',
        updateDisplayOrder :"settings/oocyte/day/updatedisplayorder"
    },
    oocyteEggSource: {
        getAll: "settings/oocyte/eggsource/getall",
        getOocyteEggSource: "settings/oocyte/eggsource/get",
        insertupdate: "settings/oocyte/eggsource/insertupdate",
        changeStatus: 'settings/oocyte/eggsource/changestatus',
        updateDisplayOrder :"settings/oocyte/eggsource/updatedisplayorder"
    },
    oocyteFreezeMethod: {
        getAll: "settings/oocyte/freezemethod/getall",
        getOocyteFreezeMethod: "settings/oocyte/freezemethod/get",
        insertupdate: "settings/oocyte/freezemethod/insertupdate",
        changeStatus: 'settings/oocyte/freezemethod/changestatus',
        updateDisplayOrder :"settings/oocyte/freezemethod/updatedisplayorder"
    },
    oocyteDeviceType: {
        getAll: "settings/oocyte/devicetype/getall",
        getOocyteDeviceType: "settings/oocyte/devicetype/get",
        insertupdate: "settings/oocyte/devicetype/insertupdate",
        changeStatus: 'settings/oocyte/devicetype/changestatus',
        updateDisplayOrder :"settings/oocyte/devicetype/updatedisplayorder"
    },
    oocyteCaneLabel: {
        getAll: "settings/oocyte/canelabel/getall",
        getOocyteCaneLabel: "settings/oocyte/canelabel/get",
        insertupdate: "settings/oocyte/canelabel/insertupdate",
        changeStatus: 'settings/oocyte/canelabel/changestatus',
        updateDisplayOrder :"settings/oocyte/canelabel/updatedisplayorder"
    },
    oocyteCryoTech: {
        getAll: "settings/oocyte/cryotech/getall",
        getOocyteCryoTech: "settings/oocyte/cryotech/get",
        insertupdate: "settings/oocyte/cryotech/insertupdate",
        changeStatus: 'settings/oocyte/cryotech/changestatus',
        updateDisplayOrder :"settings/oocyte/cryotech/updatedisplayorder"
    },
    spermSampleType: {
        getAll: "settings/sperm/sampletype/getall",
        getSpermSampleType: "settings/sperm/sampletype/get",
        insertupdate: "settings/sperm/sampletype/insertupdate",
        changeStatus: 'settings/sperm/sampletype/changestatus',
        updateDisplayOrder :"settings/sperm/sampletype/updatedisplayorder"
    },
    spermSpermSource: {
        getAll: "settings/sperm/spermsource/getall",
        getSpermSpermSource: "settings/sperm/spermsource/get",
        insertupdate: "settings/sperm/spermsource/insertupdate",
        changeStatus: 'settings/sperm/spermsource/changestatus',
        updateDisplayOrder :"settings/sperm/spermsource/updatedisplayorder"
    },
    spermFreezeMethod: {
        getAll: "settings/sperm/freezemethod/getall",
        getSpermFreezeMethod: "settings/sperm/freezemethod/get",
        insertupdate: "settings/sperm/freezemethod/insertupdate",
        changeStatus: 'settings/sperm/freezemethod/changestatus',
        updateDisplayOrder :"settings/sperm/freezemethod/updatedisplayorder"
    },
    spermVialType: {
        getAll: "settings/sperm/vialtype/getall",
        getSpermVialType: "settings/sperm/vialtype/get",
        insertupdate: "settings/sperm/vialtype/insertupdate",
        changeStatus: 'settings/sperm/vialtype/changestatus',
        updateDisplayOrder :"settings/sperm/vialtype/updatedisplayorder"
    },
    spermCryoTech: {
        getAll: "settings/sperm/cryotech/getall",
        getSpermCryoTech: "settings/sperm/cryotech/get",
        insertupdate: "settings/sperm/cryotech/insertupdate",
        changeStatus: 'settings/sperm/cryotech/changestatus',
        updateDisplayOrder :"settings/sperm/cryotech/updatedisplayorder"
    },
    settingsJobTitle: {
        getAll: "settings/jobtitle/getall",
        getSettingsJobTitle: "settings/jobtitle/get",
        insertupdate: "settings/jobtitle/insertupdate",
        changeStatus: 'settings/jobtitle/changestatus',
        updateDisplayOrder :"settings/jobtitle/updatedisplayorder"
    },
    settingsPatientLanguage: {
        getAll: "settings/patientlanguage/getall",
        getSettingsPatientLanguage: "settings/patientlanguage/get",
        insertupdate: "settings/patientlanguage/insertupdate",
        changeStatus: 'settings/patientlanguage/changestatus',
        updateDisplayOrder :"settings/patientlanguage/updatedisplayorder"
    },
    settingsSpecimenStatus: {
        getAll: "settings/statusmaster/getall",
        getSettingsSpecimenStatus: "settings/statusmaster/get",
        insertupdate: "settings/statusmaster/insertupdate",
        changeStatus: 'settings/statusmaster/changestatus',
        updateDisplayOrder :"settings/statusmaster/updatedisplayorder"
    },
    settingsAdminUsers: {
        getAll: "users/admin/user/getall",
        getSettingsAdminUsers: "users/admin/user/get",
        insertupdate: "users/admin/user/insertupdate",
        changeStatus: 'users/admin/user/changestatus'
    },
    settingsCane: {
        getAll: "cane/getall",
        getSettingsCane: "cane/get",
        insertupdate: "cane/insertupdate",
        changeStatus: 'cane/changestatus'
    },
    settingsTank: {
        getAll: "tank/getall",
        getSettingsTank: "tank/get",
        insertupdate: "tank/insertupdate",
        changeStatus: 'tank/changestatus'
    },
    settingsCannister: {
        getAll: "canister/getall",
        getSettingsCannister: "canister/get",
        insertupdate: "canister/insertupdate",
        changeStatus: 'canister/changestatus'
    },
    settingsRoles: {
        getAll: "role/getall",
        getRoles: "role/get",
        insertupdate: "role/insertupdate",
        changeStatus: 'role/changestatus'
    },
    settingsEmployees: {
        getAll: "users/employee/user/getall",
        getEmployees: "users/employee/user/get",
        insertupdate: "users/employee/user/insertupdate",
        changeStatus: 'users/employee/user/changestatus'
    },
    settingsInsurance: {
        getAll: "settings/insurance/getall",
        getSettingsInsurance: "settings/insurance/get",
        insertupdate: "settings/insurance/insertupdate",
        changeStatus: 'settings/insurance/changestatus',
        updateDisplayOrder :"settings/insurance/updatedisplayorder"
    },
};

export default apiUrls;
