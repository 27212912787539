import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../../API/api-urls";
import { apiCall } from "../../../API/index";
import { Enums } from "../../../helper/constants/enums";


export const getAllSettingsTank = createAsyncThunk(
    "SettingsTank/getAllSettingsTank",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                // ClientID: null,
                Name: data.name ? data.name : null,
                centerID : data.centerID ? data.centerID : null,
                LocationID: data.locationID ? data.locationID : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null
            }
        };

        return apiCall(`${apiUrls.settingsTank.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getSettingsTankAction = createAsyncThunk(
    "SettingsTank/getSettingsTank",
    async (data) => {
        let d = {
            Device: 1,
            // Id: data,
            TankID: data.tankID,
            // ClientID: data.clientID,
            CenterID: data.centerID,
            LocationID: data.locationID
        };
        return apiCall(`${apiUrls.settingsTank.getSettingsTank}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateSettingsTankAction = createAsyncThunk(
    "SettingsTank/settingsTankInsertUpdate",
    async (data, {rejectWithValue}) => {
        return apiCall(`${apiUrls.settingsTank.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.tankID ? false : true
                        }
                    } else {
                        // if (!response.data.message && response.data.errors && response.data.errors.length) {
                        //     response.data.message = response.data.errors[0];
                        // }
                        // return Promise.reject({
                        //     ...response.data.message,
                        // });
                        return rejectWithValue(response.data)
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusSettingsTankAction = createAsyncThunk(
    "SettingsTank/changeStatusSettingsTank",
    async (data) => {
        // let d = {
        //     Device: 1,
        //     Id: data.tankID,
        //     Statustype: 3
        //   };
        return apiCall(`${apiUrls.settingsTank.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingSettingsTank = createAsyncThunk(
    "SettingsTank/getAllSortingSettingsTank",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.settingsTank.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const getAllCopySettingsTank = createAsyncThunk(
    "SettingsTank/getAllCopySettingsTank",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                // ClientID: null,
                Name: data.name ? data.name : null,
                centerID : data.centerID ? data.centerID : null,
                LocationID: data.locationID ? data.locationID : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null
            }
        };

        return apiCall(`${apiUrls.settingsTank.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);