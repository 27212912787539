import axios from 'axios';
import { setLocalStore, getLocalStore, clearLocalStore } from '../LocalStorage/index';
import apiUrls from './api-urls';
import { UserType } from '../helper/constants/enums';
const APIURL = process.env.REACT_APP_BACKEND_URL;
const LOCAL_STORAGE_KEY_USER = process.env.REACT_APP_LOCAL_STORAGE_KEY_USER;
var headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
}


if (getLocalStore(LOCAL_STORAGE_KEY_USER)) {

    axios.defaults.headers = {
        Accept: "application/json",
        Authorization: `Bearer ${getLocalStore(LOCAL_STORAGE_KEY_USER).tokenDetails.token}`,
    };
}

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        clearLocalStore(true);
        window.location = '/admin/auth/login';
    } else {
        return Promise.reject(error);
    }
});


export const apiCall = async (path, method, data, sizesCheck) => {
    // let ClientID = getLocalStore(LOCAL_STORAGE_KEY_USER).user.clientID;
    const user = getLocalStore(LOCAL_STORAGE_KEY_USER);
    let clientID = null;
    if (user && user.user) {
        clientID = user.user.clientID;
    }
    if (data && data.Filter) {
        if (typeof (data.Filter.clientID) == 'undefined' && window.location.pathname != '/clients') {
            if (user && user.user) {
                data.Filter.clientID = clientID;
            }
        }
        if (window.location.pathname === '/clients') {
            if (typeof (data.Filter.code) == 'undefined') {
                if (user && user.user) {
                    data.Filter.code = clientID;
                }
            }
        }

    }
    else {
        if (data && typeof (data.clientID) == 'undefined' && window.location.pathname != '/clients') {
            if (user && user.user) {
                data.clientID = clientID;
            }
        }
    }

    if (method === 'POST') {
        headers["Content-Type"] = 'application/json';
        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${APIURL}${path}`,
            headers,
            data: data,
        }).then(response => {
            if (path === 'admin/account/login') {
                if (!response.data.hasError) {
                    axios.defaults.headers = {
                        Authorization: `Bearer ${response.data.data.tokenDetails.token}`,
                    };
                    if (response.data.data.user.userType !== UserType.LocationEmployee) {
                        setLocalStore(LOCAL_STORAGE_KEY_USER, response.data.data);
                    }
                }

            }
            // console.log("API Response", response)
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }
    else {

        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${APIURL}${path}`,
            headers,

        }).then(response => {

            // console.log("API Response", response)
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }

    return res;
}

export const logoutUser = async () => {
    axios.defaults.headers = {

    };
    clearLocalStore(true);

    return true;
}