import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingsTank, getAllSortingSettingsTank, insertUpdateSettingsTankAction, changeStatusSettingsTankAction, getSettingsTankAction, getAllCopySettingsTank } from "../../actions/settingsAction/tankAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    settingsTank: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingsTank: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingsTank: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingsTank: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingsTankData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    copySettingsTank: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const settingsTankReducer = createSlice({
    name: "settingsTankdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingsTankState(state, action) {
            state.selectedSettingsTank = INITIAL_STATE.selectedSettingsTank;
            state.changeStatusSettingsTank = INITIAL_STATE.changeStatusSettingsTank;
        },
        ResetSettingsTankAllDataState(state, action) {
            state.settingsTank = INITIAL_STATE.settingsTank;
        },
        ResetSettingsTankInsertUpdateDetails(state, action) {
            state.insertUpdateSettingsTank = INITIAL_STATE.insertUpdateSettingsTank;
        },
        ResetCopySettingsTankState(state, action) {
            state.copySettingsTank = INITIAL_STATE.copySettingsTank;
        }
    },
    extraReducers: (builder) => {

        //  --------getAllSettingsTank start--------------------

        builder.addCase(getAllSettingsTank.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingsTank.data.records = action.payload.data.records;
            state.settingsTank.apiMsg.status = action.meta.requestStatus;
            state.settingsTank.apiMsg.message = action.payload.message.message;
            state.settingsTank.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsTank.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsTank.pending, (state, action) => {

            state.settingsTank.data.records = null;
            state.settingsTank.apiMsg.message = action.meta.requestStatus;;
            state.settingsTank.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsTank.rejected, (state, action) => {

            state.settingsTank.apiMsg.message = action.error.message;
            state.settingsTank.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingsTank Action end--------------------

        //   getClient start

        builder.addCase(getSettingsTankAction.fulfilled, (state, action) => {
            state.selectedSettingsTank.data = action.payload.data
            state.selectedSettingsTank.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingsTank.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsTankAction.pending, (state, action) => {
            state.selectedSettingsTank.data = null;
            state.selectedSettingsTank.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingsTank.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsTankAction.rejected, (state, action) => {
            state.selectedSettingsTank.apiMsg.message = action.error.message;
            state.selectedSettingsTank.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsTankAction start

        builder.addCase(
            insertUpdateSettingsTankAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingsTank.data = action.payload.data.data;
                state.insertUpdateSettingsTank.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingsTank.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingsTank.newRecord = !action?.meta?.arg[0]?.tankID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingsTank.data.records;


                    const index = existingRecords.findIndex(record => record.tankID === newData[0]?.tankID);
                    console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData[0]?.name,
                            tankID : newData[0]?.tankID,
                            centerID : newData[0]?.centerID,
                            locationID: newData[0]?.locationID,
                            active: newData[0]?.active
                        };
                    }
                    else {
                        state.settingsTank.data.totalRecords += newData?.length;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsTankAction.pending,
            (state, action) => {
                state.insertUpdateSettingsTank.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsTankAction.rejected,
            (state, action) => {
                state.insertUpdateSettingsTank.data = action.payload.data;
                state.insertUpdateSettingsTank.apiMsg.message = action.payload.errors[0].message;
                state.insertUpdateSettingsTank.apiMsg.status = action.meta.requestStatus;

                toast.error(action.payload.errors[0].message ?  action.payload.errors[0].message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsTankAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingsTank.data = action.payload.data;
                state.changeStatusSettingsTank.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingsTank.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Tank.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.TankID;
                    state.settingsTank.data.records = state.settingsTank.data.records.filter(record => record.tankID !== deletedId);
                    state.settingsTank.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Tank.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Tank.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Tank.ChangeStatus.Active) {
                        state.settingsTank.data.records = state.settingsTank.data.records.map(record => {
                            return {
                                ...record,
                                active: record.tankID === action.meta.arg.TankID ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Tank.ChangeStatus.Inactive) {
                        state.settingsTank.data.records = state.settingsTank.data.records.map(record => {
                            return {
                                ...record,
                                active: record.tankID === action.meta.arg.TankID ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsTankAction.pending,
            (state, action) => {
                state.changeStatusSettingsTank.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsTankAction.rejected,
            (state, action) => {
                state.changeStatusSettingsTank.apiMsg.message = action.error.message;
                state.changeStatusSettingsTank.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        // sortingTankdata started

        builder.addCase(getAllSortingSettingsTank.fulfilled, (state, action) => {
            state.sortingSettingsTankData.data.records = action.payload.data.records;
            state.sortingSettingsTankData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingsTankData.apiMsg.message = action.payload.message.message;
            state.sortingSettingsTankData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsTank.pending, (state, action) => {

            state.sortingSettingsTankData.data.records = null;
            state.sortingSettingsTankData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingsTankData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsTank.rejected, (state, action) => {

            state.sortingSettingsTankData.apiMsg.message = action.error.message;
            state.sortingSettingsTankData.apiMsg.status = action.meta.requestStatus;
        });

        // copy setting tank data started

        builder.addCase(getAllCopySettingsTank.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.copySettingsTank.data.records = action.payload.data.records;
            state.copySettingsTank.apiMsg.status = action.meta.requestStatus;
            state.copySettingsTank.apiMsg.message = action.payload.message.message;
            state.copySettingsTank.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.copySettingsTank.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllCopySettingsTank.pending, (state, action) => {

            state.copySettingsTank.data.records = null;
            state.copySettingsTank.apiMsg.message = action.meta.requestStatus;;
            state.copySettingsTank.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCopySettingsTank.rejected, (state, action) => {

            state.copySettingsTank.apiMsg.message = action.error.message;
            state.copySettingsTank.apiMsg.status = action.meta.requestStatus;
        });


    },
});
export const { RESET, ResetSettingsTankState, ResetSettingsTankAllDataState, ResetSettingsTankInsertUpdateDetails, ResetCopySettingsTankState } = settingsTankReducer.actions;
export default settingsTankReducer.reducer;