import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
// import { getAllEmbryoCryoTech, getEmbryoCryoTechAction, insertUpdateEmbryoCryoTechAction, changeStatusEmbryoCryoTechAction, updateCryoTechDisplayOrder, getAllSortingCryoTech } from "../../actions/embryoSetupAction/pgtResultAction";
import { getAllEmbryoCryoTech, getEmbryoCryoTechAction, insertUpdateEmbryoCryoTechAction, changeStatusEmbryoCryoTechAction, updateCryoTechDisplayOrder, getAllSortingCryoTech } from "../../actions/embryoSetupAction/cryoTechAction";
import { Enums } from "../../../helper/constants/enums";


const INITIAL_STATE = {
    embryoCryoTech: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedEmbryoCryoTech: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateEmbryoCryoTech: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusEmbryoCryoTech: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingCryoTechData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateCryoTechDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const embryoCryoTechReducer = createSlice({
    name: "embryoCryoTechdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmbryoCryoTechState(state, action) {
            state.selectedEmbryoCryoTech = INITIAL_STATE.selectedEmbryoCryoTech;
            state.changeStatusEmbryoCryoTech = INITIAL_STATE.changeStatusEmbryoCryoTech;
        },
        ResetEmbryoCryoTechAllDataState(state, action) {
            state.embryoCryoTech = INITIAL_STATE.embryoCryoTech;
        },
        ResetCryoTechInsertUpdateDetails(state, action) {
            state.insertUpdateEmbryoCryoTech = INITIAL_STATE.insertUpdateEmbryoCryoTech;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateCryoTechDisplayOrderData = INITIAL_STATE.updateCryoTechDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllEmbryoCryoTech start--------------------

        builder.addCase(getAllEmbryoCryoTech.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.embryoCryoTech.data.records = action.payload.data.records;
            state.embryoCryoTech.apiMsg.status = action.meta.requestStatus;
            state.embryoCryoTech.apiMsg.message = action.payload.message.message;
            state.embryoCryoTech.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.embryoCryoTech.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllEmbryoCryoTech.pending, (state, action) => {

            state.embryoCryoTech.data.records = null;
            state.embryoCryoTech.apiMsg.message = action.meta.requestStatus;;
            state.embryoCryoTech.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmbryoCryoTech.rejected, (state, action) => {

            state.embryoCryoTech.apiMsg.message = action.error.message;
            state.embryoCryoTech.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllEmbryoCryoTech Action end--------------------

        //   getClient start

        builder.addCase(getEmbryoCryoTechAction.fulfilled, (state, action) => {
            state.selectedEmbryoCryoTech.data = action.payload.data
            state.selectedEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;
            state.selectedEmbryoCryoTech.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getEmbryoCryoTechAction.pending, (state, action) => {
            state.selectedEmbryoCryoTech.data = null;
            state.selectedEmbryoCryoTech.apiMsg.message = action.meta.requestStatus;;
            state.selectedEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getEmbryoCryoTechAction.rejected, (state, action) => {
            state.selectedEmbryoCryoTech.apiMsg.message = action.error.message;
            state.selectedEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateEmbryoCryoTechAction start

        builder.addCase(
            insertUpdateEmbryoCryoTechAction.fulfilled,
            (state, action) => {
                state.insertUpdateEmbryoCryoTech.data = action.payload.data.data;
                state.insertUpdateEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateEmbryoCryoTech.apiMsg.message = action.payload.message.message;
                state.insertUpdateEmbryoCryoTech.newRecord = !action.meta.arg.cryoTechID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.embryoCryoTech.data.records;


                    const index = existingRecords.findIndex(record => record.cryoTechID === newData.cryoTechID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.embryoCryoTech.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.embryoCryoTech.data.records = state.embryoCryoTech.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.cryoTechID === newData.cryoTechID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateEmbryoCryoTechAction.pending,
            (state, action) => {
                state.insertUpdateEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateEmbryoCryoTechAction.rejected,
            (state, action) => {

                state.insertUpdateEmbryoCryoTech.apiMsg.message = action.error.message;
                state.insertUpdateEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusEmbryoCryoTechAction.fulfilled,
            (state, action) => {

                state.changeStatusEmbryoCryoTech.data = action.payload.data;
                state.changeStatusEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;
                state.changeStatusEmbryoCryoTech.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.embryoCryoTech.data.records = state.embryoCryoTech.data.records.filter(record => record.cryoTechID !== deletedId);
                    state.embryoCryoTech.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.embryoCryoTech.data.records = state.embryoCryoTech.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.cryoTechID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.embryoCryoTech.data.records = state.embryoCryoTech.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.cryoTechID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.embryoCryoTech.data.records = state.embryoCryoTech.data.records.map(record => {
                            return {
                                ...record,
                                active: record.cryoTechID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.embryoCryoTech.data.records = state.embryoCryoTech.data.records.map(record => {
                            return {
                                ...record,
                                active: record.cryoTechID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusEmbryoCryoTechAction.pending,
            (state, action) => {
                state.changeStatusEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusEmbryoCryoTechAction.rejected,
            (state, action) => {
                state.changeStatusEmbryoCryoTech.apiMsg.message = action.error.message;
                state.changeStatusEmbryoCryoTech.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateCryoTechDisplayOrder.fulfilled, (state, action) => {
            state.updateCryoTechDisplayOrderData.data = action.payload.data
            state.updateCryoTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateCryoTechDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateCryoTechDisplayOrder.pending, (state, action) => {
            state.updateCryoTechDisplayOrderData.data = null;
            state.updateCryoTechDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateCryoTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateCryoTechDisplayOrder.rejected, (state, action) => {
            state.updateCryoTechDisplayOrderData.apiMsg.message = action.error.message;
            state.updateCryoTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingCryoTech.fulfilled, (state, action) => {
            state.sortingCryoTechData.data.records = action.payload.data.records;
            state.sortingCryoTechData.apiMsg.status = action.meta.requestStatus;
            state.sortingCryoTechData.apiMsg.message = action.payload.message.message;
            state.sortingCryoTechData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingCryoTech.pending, (state, action) => {

            state.sortingCryoTechData.data.records = null;
            state.sortingCryoTechData.apiMsg.message = action.meta.requestStatus;;
            state.sortingCryoTechData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingCryoTech.rejected, (state, action) => {

            state.sortingCryoTechData.apiMsg.message = action.error.message;
            state.sortingCryoTechData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetEmbryoCryoTechState, ResetEmbryoCryoTechAllDataState, ResetCryoTechInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = embryoCryoTechReducer.actions;
export default embryoCryoTechReducer.reducer;