import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllEmbryoFreezeMethod, getEmbryoFreezeMethodAction, insertUpdateEmbryoFreezeMethodAction, changeStatusEmbryoFreezeMethodAction, getAllSortingFreezeMethod, updateFreezeMethodDisplayOrder } from "../../actions/embryoSetupAction/freezeMethodAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    embryoFreezeMethods: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedEmbryoFreezeMethod: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateEmbryoFreezeMethod: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusEmbryoFreezeMethod: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    sortingFreezeMethodData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    updateFreezeMethodDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const embryoFreezeMethodReducer = createSlice({
    name: "embryoFreezeMethoddata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmbryoFreezeMethodState(state, action) {
            state.selectedEmbryoFreezeMethod = INITIAL_STATE.selectedEmbryoFreezeMethod;
            state.changeStatusEmbryoFreezeMethod = INITIAL_STATE.changeStatusEmbryoFreezeMethod
        },
        ResetEmbryoFreezemethodAllDataState(state, action) {
            state.embryoFreezeMethods = INITIAL_STATE.embryoFreezeMethods;
        },
        ResetFreezeMethodInsertUpdateDetails(state, action) {
            state.insertUpdateEmbryoFreezeMethod = INITIAL_STATE.insertUpdateEmbryoFreezeMethod;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateFreezeMethodDisplayOrderData = INITIAL_STATE.updateFreezeMethodDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllEmbryoFreezeMethod start--------------------

        builder.addCase(getAllEmbryoFreezeMethod.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.embryoFreezeMethods.data.records = action.payload.data.records;
            state.embryoFreezeMethods.apiMsg.status = action.meta.requestStatus;
            state.embryoFreezeMethods.apiMsg.message = action.payload.message.message;
            state.embryoFreezeMethods.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.embryoFreezeMethods.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllEmbryoFreezeMethod.pending, (state, action) => {

            state.embryoFreezeMethods.data.records = null;
            state.embryoFreezeMethods.apiMsg.message = action.meta.requestStatus;;
            state.embryoFreezeMethods.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmbryoFreezeMethod.rejected, (state, action) => {

            state.embryoFreezeMethods.apiMsg.message = action.error.message;
            state.embryoFreezeMethods.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllEmbryoFreezeMethod Action end--------------------

        //   getClient start

        builder.addCase(getEmbryoFreezeMethodAction.fulfilled, (state, action) => {
            state.selectedEmbryoFreezeMethod.data = action.payload.data
            state.selectedEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;
            state.selectedEmbryoFreezeMethod.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getEmbryoFreezeMethodAction.pending, (state, action) => {
            state.selectedEmbryoFreezeMethod.data = null;
            state.selectedEmbryoFreezeMethod.apiMsg.message = action.meta.requestStatus;;
            state.selectedEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getEmbryoFreezeMethodAction.rejected, (state, action) => {
            state.selectedEmbryoFreezeMethod.apiMsg.message = action.error.message;
            state.selectedEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateEmbryoFreezeMethodAction start

        builder.addCase(
            insertUpdateEmbryoFreezeMethodAction.fulfilled,
            (state, action) => {
                state.insertUpdateEmbryoFreezeMethod.data = action.payload.data.data;
                state.insertUpdateEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateEmbryoFreezeMethod.apiMsg.message = action.payload.message.message;
                state.insertUpdateEmbryoFreezeMethod.newRecord = !action.meta.arg.freezeMethodID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.embryoFreezeMethods.data.records;


                    const index = existingRecords.findIndex(record => record.freezeMethodID === newData.freezeMethodID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.embryoFreezeMethods.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.embryoFreezeMethods.data.records = state.embryoFreezeMethods.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.freezeMethodID === newData.freezeMethodID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateEmbryoFreezeMethodAction.pending,
            (state, action) => {
                state.insertUpdateEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateEmbryoFreezeMethodAction.rejected,
            (state, action) => {

                state.insertUpdateEmbryoFreezeMethod.apiMsg.message = action.error.message;
                state.insertUpdateEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusEmbryoFreezeMethodAction.fulfilled,
            (state, action) => {

                state.changeStatusEmbryoFreezeMethod.data = action.payload.data;
                state.changeStatusEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;
                state.changeStatusEmbryoFreezeMethod.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.embryoFreezeMethods.data.records = state.embryoFreezeMethods.data.records.filter(record => record.freezeMethodID !== deletedId);
                    state.embryoFreezeMethods.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.embryoFreezeMethods.data.records = state.embryoFreezeMethods.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.freezeMethodID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.embryoFreezeMethods.data.records = state.embryoFreezeMethods.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.freezeMethodID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.embryoFreezeMethods.data.records = state.embryoFreezeMethods.data.records.map(record => {
                            return {
                                ...record,
                                active: record.freezeMethodID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.embryoFreezeMethods.data.records = state.embryoFreezeMethods.data.records.map(record => {
                            return {
                                ...record,
                                active: record.freezeMethodID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusEmbryoFreezeMethodAction.pending,
            (state, action) => {
                state.changeStatusEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusEmbryoFreezeMethodAction.rejected,
            (state, action) => {
                state.changeStatusEmbryoFreezeMethod.apiMsg.message = action.error.message;
                state.changeStatusEmbryoFreezeMethod.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

// ----------------------- Freeze Method  display order ---------------------//

builder.addCase(updateFreezeMethodDisplayOrder.fulfilled, (state, action) => {
    state.updateFreezeMethodDisplayOrderData.data = action.payload.data
    state.updateFreezeMethodDisplayOrderData.apiMsg.status = action.meta.requestStatus;
    state.updateFreezeMethodDisplayOrderData.apiMsg.message = action.payload.message.message;

    toast.success(action.payload.message.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success"
    });
});

builder.addCase(updateFreezeMethodDisplayOrder.pending, (state, action) => {
    state.updateFreezeMethodDisplayOrderData.data = null;
    state.updateFreezeMethodDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
    state.updateFreezeMethodDisplayOrderData.apiMsg.status = action.meta.requestStatus;
});

builder.addCase(updateFreezeMethodDisplayOrder.rejected, (state, action) => {
    state.updateFreezeMethodDisplayOrderData.apiMsg.message = action.error.message;
    state.updateFreezeMethodDisplayOrderData.apiMsg.status = action.meta.requestStatus;
    toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "error"
    });
});

// --------------------- Freze Method sorting --------------------------------//

builder.addCase(getAllSortingFreezeMethod.fulfilled, (state, action) => {
    state.sortingFreezeMethodData.data.records = action.payload.data.records;
    state.sortingFreezeMethodData.apiMsg.status = action.meta.requestStatus;
    state.sortingFreezeMethodData.apiMsg.message = action.payload.message.message;
    state.sortingFreezeMethodData.data.totalRecords = action.payload.data.totalRecords;

});
builder.addCase(getAllSortingFreezeMethod.pending, (state, action) => {

    state.sortingFreezeMethodData.data.records = null;
    state.sortingFreezeMethodData.apiMsg.message = action.meta.requestStatus;;
    state.sortingFreezeMethodData.apiMsg.status = action.meta.requestStatus;
});
builder.addCase(getAllSortingFreezeMethod.rejected, (state, action) => {

    state.sortingFreezeMethodData.apiMsg.message = action.error.message;
    state.sortingFreezeMethodData.apiMsg.status = action.meta.requestStatus;
});

    },
});
export const { RESET, ResetEmbryoFreezeMethodState, ResetDisplayOrderUpdateDetails,ResetEmbryoFreezemethodAllDataState,ResetFreezeMethodInsertUpdateDetails } = embryoFreezeMethodReducer.actions;
export default embryoFreezeMethodReducer.reducer;