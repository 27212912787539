import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingPatientLanguage, getSettingsPatientLanguageAction, insertUpdateSettingsPatientLanguageAction, changeStatusSettingsPatientLanguageAction, updateSettingsPatientLanguageDisplayOrder, getAllSortingSettingsPatientLanguage } from "../../actions/settingsAction/patientLanguageAction";
import { Enums } from "../../../helper/constants/enums";


const INITIAL_STATE = {
    settingPatientLanguage: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingPatientLanguage: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingPatientLanguage: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingPatientLanguage: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingPatientLanguageData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateSettingPatientLanguageDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const settingPatientLanguageReducer = createSlice({
    name: "settingPatientLanguagedata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingPatientLanguageState(state, action) {
            state.selectedSettingPatientLanguage = INITIAL_STATE.selectedSettingPatientLanguage;
            state.changeStatusSettingPatientLanguage = INITIAL_STATE.changeStatusSettingPatientLanguage;
        },
        ResetSettingPatientLanguageAllDataState(state, action) {
            state.settingPatientLanguage = INITIAL_STATE.settingPatientLanguage;
        },
        ResetSettingPatientLanguageInsertUpdateDetails(state, action) {
            state.insertUpdateSettingPatientLanguage = INITIAL_STATE.insertUpdateSettingPatientLanguage;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateSettingPatientLanguageDisplayOrderData = INITIAL_STATE.updateSettingPatientLanguageDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllSettingPatientLanguage start--------------------

        builder.addCase(getAllSettingPatientLanguage.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingPatientLanguage.data.records = action.payload.data.records;
            state.settingPatientLanguage.apiMsg.status = action.meta.requestStatus;
            state.settingPatientLanguage.apiMsg.message = action.payload.message.message;
            state.settingPatientLanguage.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingPatientLanguage.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingPatientLanguage.pending, (state, action) => {

            state.settingPatientLanguage.data.records = null;
            state.settingPatientLanguage.apiMsg.message = action.meta.requestStatus;;
            state.settingPatientLanguage.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingPatientLanguage.rejected, (state, action) => {

            state.settingPatientLanguage.apiMsg.message = action.error.message;
            state.settingPatientLanguage.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingPatientLanguage Action end--------------------

        //   getClient start

        builder.addCase(getSettingsPatientLanguageAction.fulfilled, (state, action) => {
            state.selectedSettingPatientLanguage.data = action.payload.data
            state.selectedSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingPatientLanguage.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsPatientLanguageAction.pending, (state, action) => {
            state.selectedSettingPatientLanguage.data = null;
            state.selectedSettingPatientLanguage.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsPatientLanguageAction.rejected, (state, action) => {
            state.selectedSettingPatientLanguage.apiMsg.message = action.error.message;
            state.selectedSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsPatientLanguageAction start

        builder.addCase(
            insertUpdateSettingsPatientLanguageAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingPatientLanguage.data = action.payload.data.data;
                state.insertUpdateSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingPatientLanguage.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingPatientLanguage.newRecord = !action.meta.arg.languageID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingPatientLanguage.data.records;


                    const index = existingRecords.findIndex(record => record.languageID === newData.languageID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            isActive: newData.isActive
                            // defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.settingPatientLanguage.data.totalRecords += 1;
                    }


                    // if (newData.defaultSelected === true) {
                    //     state.settingPatientLanguage.data.records = state.settingPatientLanguage.data.records.map(record => ({
                    //         ...record,
                    //         defaultSelected: record.languageID === newData.languageID
                    //     }));
                    // }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsPatientLanguageAction.pending,
            (state, action) => {
                state.insertUpdateSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsPatientLanguageAction.rejected,
            (state, action) => {

                state.insertUpdateSettingPatientLanguage.apiMsg.message = action.error.message;
                state.insertUpdateSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsPatientLanguageAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingPatientLanguage.data = action.payload.data;
                state.changeStatusSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingPatientLanguage.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.settingPatientLanguage.data.records = state.settingPatientLanguage.data.records.filter(record => record.languageID !== deletedId);
                    state.settingPatientLanguage.data.totalRecords -= 1;
                }
                // else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                //     if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                //         state.settingPatientLanguage.data.records = state.settingPatientLanguage.data.records.map(record => {
                //             return {
                //                 ...record,
                //                 defaultSelected: record.languageID === action.meta.arg.Id ? true : false
                //             };
                //         });
                //     }
                    // else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                    //     state.settingPatientLanguage.data.records = state.settingPatientLanguage.data.records.map(record => {
                    //         return {
                    //             ...record,
                    //             defaultSelected: record.languageID === action.meta.arg.Id ? false : record.defaultSelected
                    //         };
                    //     });
                    // }
                // }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.settingPatientLanguage.data.records = state.settingPatientLanguage.data.records.map(record => {
                            return {
                                ...record,
                                isActive: record.languageID === action.meta.arg.Id ? true : record.isActive
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.settingPatientLanguage.data.records = state.settingPatientLanguage.data.records.map(record => {
                            return {
                                ...record,
                                isActive: record.languageID === action.meta.arg.Id ? false : record.isActive
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsPatientLanguageAction.pending,
            (state, action) => {
                state.changeStatusSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsPatientLanguageAction.rejected,
            (state, action) => {
                state.changeStatusSettingPatientLanguage.apiMsg.message = action.error.message;
                state.changeStatusSettingPatientLanguage.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateSettingsPatientLanguageDisplayOrder.fulfilled, (state, action) => {
            state.updateSettingPatientLanguageDisplayOrderData.data = action.payload.data
            state.updateSettingPatientLanguageDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateSettingPatientLanguageDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateSettingsPatientLanguageDisplayOrder.pending, (state, action) => {
            state.updateSettingPatientLanguageDisplayOrderData.data = null;
            state.updateSettingPatientLanguageDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateSettingPatientLanguageDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateSettingsPatientLanguageDisplayOrder.rejected, (state, action) => {
            state.updateSettingPatientLanguageDisplayOrderData.apiMsg.message = action.error.message;
            state.updateSettingPatientLanguageDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingSettingsPatientLanguage.fulfilled, (state, action) => {
            state.sortingSettingPatientLanguageData.data.records = action.payload.data.records;
            state.sortingSettingPatientLanguageData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingPatientLanguageData.apiMsg.message = action.payload.message.message;
            state.sortingSettingPatientLanguageData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsPatientLanguage.pending, (state, action) => {

            state.sortingSettingPatientLanguageData.data.records = null;
            state.sortingSettingPatientLanguageData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingPatientLanguageData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsPatientLanguage.rejected, (state, action) => {

            state.sortingSettingPatientLanguageData.apiMsg.message = action.error.message;
            state.sortingSettingPatientLanguageData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetSettingPatientLanguageState, ResetSettingPatientLanguageAllDataState, ResetSettingPatientLanguageInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = settingPatientLanguageReducer.actions;
export default settingPatientLanguageReducer.reducer;