import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";

import WithLoading from './components/shared/WithLoading';
import RouteLoader from './components/shared/RouteLoader';
import Layout from './components/Layout';
import { Menus, UserType } from './helper/constants/enums';
import { useSelector } from 'react-redux';

const RequireAuth = (props) => {
    const {  user } = useSelector((state) => state.auth);
    
    return <> {props.active ? props.children : <Navigate to="/auth/login" replace />} </>;
};


function AppRoutes(props) {
    const LoginPage = WithLoading(React.lazy(() => import("./pages/Login/Login")));
    const LocationSelectionPage = WithLoading(React.lazy(() => import("./pages/Login/LocationSelection")));
    
    const DashboardPage = WithLoading(React.lazy(() => import("./pages/Dashboard/Dashboard")));

    const AccessDeniedPage = WithLoading(React.lazy(() => import("./components/shared/AccessDenied")));

    const ClientsPage = WithLoading(React.lazy(() => import("./pages/Clients/Clients")));


    const CenterPage = WithLoading(React.lazy(() => import("./pages/Center/Center")));

    const PatientPage = WithLoading(React.lazy(() => import("./pages/Patients/Patients")));

    const LocationPage = WithLoading(React.lazy(() => import("./pages/Location/Location")));

    const SampleCollectionPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/SampleCollection/SampleCollection")));
    const SampleCollectionAddPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/SampleCollection/SampleCollectionForm")));
    const SampleConfirmationPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/SampleConfirmation/SampleConfirmation")));
    const UpdateStatusPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/UpdateStatus/UpdateStatus")));
    const LandingScreenPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/LandingScreen/LandingScreen")));
    const LandingScreenEditPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/LandingScreen/LandingScreenForm")));
    const LandingScreenDocumentsPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/LandingScreen/LandingScreenDocuments")));
    const CaneSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Cane/Cane")));
    const TankSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Tank/Tank")));
    const CannisterSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Cannister/Cannister")));
    const PatientLanguageSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/PatientLanguage/PatientLanguage")));
    const JobTitleSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/JobTitle/JobTitle")));  
    const SpecimenStatusSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/SpecimenStatus/SpecimenStatus")));
    const InsuranceSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Insurance/Insurance")));
    const AdminUsersSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/AdminUsers/AdminUsers")));
    const RolesSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Roles/Roles")));
    const EmployeesSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Employees/Employees")));
    const LookUpConfigurationPage = WithLoading(React.lazy(() => import("./pages/Configuration/LookUpMaster")));
    const EmbryoPage = WithLoading(React.lazy(() => import("./pages/InventoryManagement/Embryo/Embryo")));
    const OoctyePage = WithLoading(React.lazy(() => import("./pages/InventoryManagement/Oocyte/Ooctye")));
    const SpermPage = WithLoading(React.lazy(() => import("./pages/InventoryManagement/Sperm/Sperm")));


    const PgtResultPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTResult/PGTResult")));
    const PgtResultEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTResult/PGTResultEditForm")));
    const PgtResultSortPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTResult/PGTResultSort")));

    const EmbryoStatusPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EmbryoStatus/EmbryoStatus")));
    const EmbryoStatusAddPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EmbryoStatus/EmbryoStatusAddForm")));
    const EmbryoStatusEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EmbryoStatus/EmbryoStatusEditForm")));
    const EggSourcePage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EGGSource/EGGSource")));
    const EggSourceEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EGGSource/EGGSourceEditForm")));
    const SpermSourcePage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/SpermSource/SpermSource")));
    const SpermSourceEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/SpermSource/SpermSourceEditForm")));
    const FreezeMethodPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/FreezeMethod/FreezeMethod")));
    const FreezeMethodEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/FreezeMethod/FreezeMethodEditForm")));
    const DeviceTypePage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/DeviceType/DeviceType")));
    const DeviceTypeEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/DeviceType/DeviceTypeEditForm")));
    const PGTLabPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTLab/PGTLab")));
    const PGTLabEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTLab/PGTLabEditForm")));
    const CryoTechPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/CryoTech/CryoTech")));
    const CryoTechEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/CryoTech/CryoTechEditForm")));
    const BXTechPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/BXTech/BXTech")));
    const BXTechEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/BXTech/BXTechEditForm")));
    const TubingTechPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/TubingTech/TubingTech")));
    const TubingTechEditPage = WithLoading(React.lazy(() => import("./pages/EmbryoSetup/TubingTech/TubingTechEditform")));
    const OocyteStagePage = WithLoading(React.lazy(() => import("./pages/OocyteSetup/OocyteStage/OocyteStage")));
    const OocyteDayPage = WithLoading(React.lazy(() => import("./pages/OocyteSetup/OocyteDay/OocyteDay")));
    const OocyteEggSourcePage = WithLoading(React.lazy(() => import("./pages/OocyteSetup/EggSource/EggSource")));
    const OocyteFreezeMethodPage = WithLoading(React.lazy(() => import("./pages/OocyteSetup/FreezeMethod/FreezeMethod")));
    const OocyteDeviceTypePage = WithLoading(React.lazy(() => import("./pages/OocyteSetup/DeviceType/DeviceType")));
    const OocyteCaneLabelPage = WithLoading(React.lazy(() => import("./pages/OocyteSetup/CaneLabel/CaneLabel")));
    const OocyteCryoTechPage = WithLoading(React.lazy(() => import("./pages/OocyteSetup/CryoTech/CryoTech")));
    const SpermSampleTypePage = WithLoading(React.lazy(() => import("./pages/SpermSetup/SampleType/SampleType")));
    const SpermSpermSourcePage = WithLoading(React.lazy(() => import("./pages/SpermSetup/SpermSource/SpermSource")));
    const SpermFreezeMethodPage = WithLoading(React.lazy(() => import("./pages/SpermSetup/FreezeMethod/FreezeMethod")));
    const SpermVialTypePage = WithLoading(React.lazy(() => import("./pages/SpermSetup/VialType/VialType")));
    const SpermCryoTechPage = WithLoading(React.lazy(() => import("./pages/SpermSetup/CryoTech/CryoTech")));
    const AuditLogReportPage = WithLoading(React.lazy(() => import("./pages/Reports/AuditLogReport/AuditLogReport")));
    const InventoryReportPage = WithLoading(React.lazy(() => import("./pages/Reports/Inventory/Inventory")));

    const protectedLayout = (
        <>
            {
                <>

                    <RequireAuth active={props.active}>
                        <Layout />

                    </RequireAuth>

                </>

            }
        </>
    );
    return (
        <div>

            <Routes>
                <Route path='' element={<LoginPage />} />
                <Route path='/locationselection' element={<LocationSelectionPage />} />

                <Route path="/auth" >
                    <Route path='login' element={<LoginPage />} />
                </Route>
                <Route path='/accessdenied' element={<AccessDeniedPage />} />
                <Route path="/" element={protectedLayout} >
                    <Route path={`/${Menus.Dashboard.routeName.toLowerCase()}`} element={<DashboardPage />} />

                    <Route path={`/${Menus.Clients.routeName.toLowerCase()}`} element={<ClientsPage />} />


                    <Route path={`/${Menus.Centers.routeName.toLowerCase()}`} element={<CenterPage />} />

                    <Route path={`${Menus.Patients.routeName.toLowerCase()}`}>
                        <Route index element={<PatientPage />} />
                    </Route>

                    <Route path={`${(Menus.Locations.routeName).toLowerCase()}`}>
                        <Route index element={<LocationPage />} />
                    </Route>


                    {/* <Route path={`${(Menus.SampleMaster.routeName).toLowerCase()}`}>
                        <Route path={`${Menus.SampleCollection.routeName.toLowerCase()}`}>
                            <Route index element={<SampleCollectionPage />} />
                            <Route path='add' element={<SampleCollectionAddPage />} />
                        </Route>
                        <Route path={`${Menus.SampleConfirmation.routeName.toLowerCase()}`}>
                            <Route index element={<SampleConfirmationPage />} />
                        </Route>
                        <Route path={`${Menus.LandingScreen.routeName.toLowerCase()}`}>
                            <Route index element={<LandingScreenPage />} />
                            <Route path='edit' element={<LandingScreenEditPage />} />
                            <Route path='documents' element={<LandingScreenDocumentsPage />} />
                        </Route>
                        <Route path={`${Menus.ManageInventory.routeName.toLowerCase()}`}>
                            <Route index element={<UpdateStatusPage />} />
                        </Route>
                        
                    </Route> */}

                    <Route path={`${Menus.Settings.routeName.toLowerCase()}`}>
                        <Route path={`${Menus.SettingsCane.routeName.toLowerCase()}`}>
                            <Route index element={<CaneSettingPage />} />
                        </Route>

                        <Route path={`${Menus.SettingsTank.routeName.toLowerCase()}`}>
                            <Route index element={<TankSettingPage />} />
                        </Route>

                        <Route path={`${Menus.SettingsCannister.routeName.toLowerCase()}`}>
                            <Route index element={<CannisterSettingPage />} />
                        </Route>

                        <Route path={`${Menus.SettingsJobTitle.routeName.toLowerCase()}`}>
                            <Route index element={<JobTitleSettingPage />} />
                        </Route>

                        <Route path={`${Menus.SettingsPatientLanguage.routeName.toLowerCase()}`}>
                            <Route index element={<PatientLanguageSettingPage />} />
                        </Route>

                        <Route path={`${Menus.SettingsSpecimenTypeStatus.routeName.toLowerCase()}`}>
                            <Route index element={<SpecimenStatusSettingPage />} />
                        </Route>

                        <Route path={`${Menus.SettingsInsurance.routeName.toLowerCase()}`}>
                            <Route index element={<InsuranceSettingPage />} />
                        </Route>

                        <Route path={`${Menus.SettingsAdminUsers.routeName.toLowerCase()}`}>
                            <Route index element={<AdminUsersSettingPage />} />
                        </Route>

                        <Route path={`${Menus.SettingsRoles.routeName.toLowerCase()}`} element={<RolesSettingPage />} />

                        <Route path={`${Menus.SettingsEmployee.routeName.toLowerCase()}`} element={<EmployeesSettingPage />} />
                    </Route>

                    {/* <Route path={`${Menus.InventoryManagement.routeName.toLowerCase()}`}>
                        <Route path={`${Menus.InventoryManagementEmbryo.routeName.toLowerCase()}`}>
                            <Route index element={<EmbryoPage />} />
                        </Route>
                        <Route path={`${Menus.InventoryManagementOoctye.routeName.toLowerCase()}`}>
                            <Route index element={<OoctyePage />} />
                        </Route>
                        <Route path={`${Menus.InventoryManagementSperm.routeName.toLowerCase()}`}>
                            <Route index element={<SpermPage />} />
                        </Route>
                    </Route> */}

                    <Route path={`${Menus.EmbryoSetup.routeName.toLowerCase()}`}>
                        <Route path={`${Menus.EmbryoSetupPGTResult.routeName.toLowerCase()}`}>
                            <Route index element={<PgtResultPage />} />
                            <Route path='displayorder' element={<PgtResultSortPage />} />
                        </Route>
                        <Route path={`${Menus.EmbryoSetupEGGSource.routeName.toLowerCase()}`}>
                            <Route index element={<EggSourcePage />} />
                        </Route>
                        <Route path={`${Menus.EmbryoSetupSpermSource.routeName.toLowerCase()}`}>
                            <Route index element={<SpermSourcePage />} />
                        </Route>
                        <Route path={`${Menus.EmbryoSetupFreezeMethod.routeName.toLowerCase()}`}>
                            <Route index element={<FreezeMethodPage />} />
                        </Route>
                        <Route path={`${Menus.EmbryoSetupDeviceType.routeName.toLowerCase()}`}>
                            <Route index element={<DeviceTypePage />} />
                        </Route>
                        <Route path={`${Menus.EmbryoSetupPGTLab.routeName.toLowerCase()}`}>
                            <Route index element={<PGTLabPage />} />
                        </Route>
                        <Route path={`${Menus.EmbryoSetupCryoTech.routeName.toLowerCase()}`}>
                            <Route index element={<CryoTechPage />} />
                        </Route>
                        <Route path={`${Menus.EmbryoSetupBXTech.routeName.toLowerCase()}`}>
                            <Route index element={<BXTechPage />} />
                        </Route>
                        <Route path={`${Menus.EmbryoSetupTubingTech.routeName.toLowerCase()}`}>
                            <Route index element={<TubingTechPage />} />
                        </Route>
                    </Route>

                    <Route path={`${Menus.OocyteSetup.routeName.toLowerCase()}`}>
                        <Route path={`${Menus.OocyteSetupOocyteStage.routeName.toLowerCase()}`}>
                            <Route index element={<OocyteStagePage />} />
                        </Route>
                        <Route path={`${Menus.OocyteSetupOocyteDay.routeName.toLowerCase()}`}>
                            <Route index element={<OocyteDayPage />} />
                        </Route>
                        <Route path={`${Menus.OocyteSetupEggSource.routeName.toLowerCase()}`}>
                            <Route index element={<OocyteEggSourcePage />} />
                        </Route>
                        <Route path={`${Menus.OocyteSetupFreezeMethod.routeName.toLowerCase()}`}>
                            <Route index element={<OocyteFreezeMethodPage />} />
                        </Route>
                        <Route path={`${Menus.OocyteSetupDeviceType.routeName.toLowerCase()}`}>
                            <Route index element={<OocyteDeviceTypePage />} />
                        </Route>
                        <Route path={`${Menus.OocyteSetupCaneLabel.routeName.toLowerCase()}`}>
                            <Route index element={<OocyteCaneLabelPage />} />
                        </Route>
                        <Route path={`${Menus.OocyteSetupCryoTech.routeName.toLowerCase()}`}>
                            <Route index element={<OocyteCryoTechPage />} />
                        </Route>
                    </Route>

                    <Route path={`${Menus.SpermSetup.routeName.toLowerCase()}`}>
                        <Route path={`${Menus.SpermSetupSampleType.routeName.toLowerCase()}`}>
                            <Route index element={<SpermSampleTypePage />} />
                        </Route>
                        <Route path={`${Menus.SpermSetupSpermSource.routeName.toLowerCase()}`}>
                            <Route index element={<SpermSpermSourcePage />} />
                        </Route>
                        <Route path={`${Menus.SpermSetupFreezeMethod.routeName.toLowerCase()}`}>
                            <Route index element={<SpermFreezeMethodPage />} />
                        </Route>
                        <Route path={`${Menus.SpermSetupVialType.routeName.toLowerCase()}`}>
                            <Route index element={<SpermVialTypePage />} />
                        </Route>
                        <Route path={`${Menus.SpermSetupCryoTech.routeName.toLowerCase()}`}>
                            <Route index element={<SpermCryoTechPage />} />
                        </Route>
                    </Route>

                    {/* <Route path={`${Menus.Reports.routeName.toLowerCase()}`}>
                        <Route path={`${Menus.ReportsAuditLogReport.routeName.toLowerCase()}`}>
                            <Route index element={<AuditLogReportPage />} />
                        </Route>
                        <Route path={`${Menus.ReportsInventory.routeName.toLowerCase()}`}>
                            <Route index element={<InventoryReportPage />} />
                        </Route>
                    </Route> */}

                    {/* <Route path={`${Menus.Configuration.routeName.toLowerCase()}`}>
                        <Route path={`${Menus.ConfigurationLookupMaster.routeName.toLowerCase()}`}>
                            <Route index element={<LookUpConfigurationPage />} />
                        </Route>
                    </Route> */}

                </Route>
            </Routes>
        </div>
    )
}

export default AppRoutes