import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllLocations, getLocationAction, changeStatusLocationAction, insertUpdateLocationAction, getAllSortingLocation, getAllTimeZoneData } from '../actions/locationActions';
import { Enums } from "../../helper/constants/enums";

const INITIAL_STATE = {
    allLocations: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedLocations: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateLocation: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusLocation: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingLocationData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    allTimezoneData: {
        data: {
            records: null,
            totalRecords: 0
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

}

const locationReducer = createSlice({
    name: "locationdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetLocationState(state, action) {
            state.selectedLocations = INITIAL_STATE.selectedLocations;
            state.changeStatusLocation = INITIAL_STATE.changeStatusLocation;
        },
        ResetLocationAllDataState(state, action) {
            state.allLocations = INITIAL_STATE.allLocations;
        },
        ResetLocationInsertUpdateDetails(state, action) {
            state.insertUpdateLocation = INITIAL_STATE.insertUpdateLocation;
        },
        ResetTimeZoneList(state, action) {
            state.allTimezoneData = INITIAL_STATE.allTimezoneData;
        },
    },
    extraReducers: (builder) => {

        //  --------getAllLocations start--------------------

        builder.addCase(getAllLocations.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.allLocations.data.records = action.payload.data.records;
            state.allLocations.apiMsg.status = action.meta.requestStatus;
            state.allLocations.apiMsg.message = action.payload.message.message;
            state.allLocations.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.allLocations.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllLocations.pending, (state, action) => {

            state.allLocations.data.records = null;
            state.allLocations.apiMsg.message = action.meta.requestStatus;;
            state.allLocations.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllLocations.rejected, (state, action) => {

            state.allLocations.apiMsg.message = action.error.message;
            state.allLocations.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllLocations Action end--------------------

        //   getCenter start

        builder.addCase(getLocationAction.fulfilled, (state, action) => {
            state.selectedLocations.data = action.payload.data
            state.selectedLocations.apiMsg.status = action.meta.requestStatus;
            state.selectedLocations.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getLocationAction.pending, (state, action) => {
            state.selectedLocations.data = null;
            state.selectedLocations.apiMsg.message = action.meta.requestStatus;;
            state.selectedLocations.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getLocationAction.rejected, (state, action) => {
            state.selectedLocations.apiMsg.message = action.error.message;
            state.selectedLocations.apiMsg.status = action.meta.requestStatus;
        });


        //   getCenter end



        // insertUpdateLocationAction start

        builder.addCase(
            insertUpdateLocationAction.fulfilled,
            (state, action) => {
                state.insertUpdateLocation.data = action.payload.data.data;
                state.insertUpdateLocation.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateLocation.apiMsg.message = action.payload.message.message;
                state.insertUpdateLocation.newRecord = !action.meta.arg.locationID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.allLocations.data.records;


                    const index = existingRecords.findIndex(record => record.locationID === newData.locationID);
                    // console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            code: newData.code,
                            name: newData.name,
                            email: newData.email,
                            pointOfContact: newData.pointOfContact,
                            timeZoneID: newData.timeZoneID,
                            npi: newData.npi,
                            active: newData.active,
                            clientID: newData.clientID,
                            centerID : newData.centerID,
                        };
                    }
                    else {
                        state.allLocations.data.totalRecords += 1;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateLocationAction.pending,
            (state, action) => {
                state.insertUpdateLocation.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateLocationAction.rejected,
            (state, action) => {

                state.insertUpdateLocation.apiMsg.message = action.error.message;
                state.insertUpdateLocation.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update center action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusLocationAction.fulfilled,
            (state, action) => {

                state.changeStatusLocation.data = action.payload.data;
                state.changeStatusLocation.apiMsg.status = action.meta.requestStatus;
                state.changeStatusLocation.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Location.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.allLocations.data.records = state.allLocations.data.records.filter(record => record.locationID !== deletedId);
                    state.allLocations.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Location.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Location.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Location.ChangeStatus.Active) {
                        state.allLocations.data.records = state.allLocations.data.records.map(record => {
                            return {
                                ...record,
                                active: record.locationID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Location.ChangeStatus.Inactive) {
                        state.allLocations.data.records = state.allLocations.data.records.map(record => {
                            return {
                                ...record,
                                active: record.locationID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusLocationAction.pending,
            (state, action) => {
                state.changeStatusLocation.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusLocationAction.rejected,
            (state, action) => {
                state.changeStatusLocation.apiMsg.message = action.error.message;
                state.changeStatusLocation.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(getAllSortingLocation.fulfilled, (state, action) => {
            state.sortingLocationData.data.records = action.payload.data.records;
            state.sortingLocationData.apiMsg.status = action.meta.requestStatus;
            state.sortingLocationData.apiMsg.message = action.payload.message.message;
            state.sortingLocationData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingLocation.pending, (state, action) => {

            state.sortingLocationData.data.records = null;
            state.sortingLocationData.apiMsg.message = action.meta.requestStatus;;
            state.sortingLocationData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingLocation.rejected, (state, action) => {

            state.sortingLocationData.apiMsg.message = action.error.message;
            state.sortingLocationData.apiMsg.status = action.meta.requestStatus;
        });

        // ----------------------- getalltimezonedata --------------------------//

        builder.addCase(getAllTimeZoneData.fulfilled, (state, action) => {
            state.allTimezoneData.data.records = action.payload.data;
            state.allTimezoneData.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllTimeZoneData.pending, (state, action) => {
            state.allTimezoneData.data.records = null;
            state.allTimezoneData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllTimeZoneData.rejected, (state, action) => {
            state.allTimezoneData.apiMsg.status = action.meta.requestStatus;
        });

    },
});
export const { RESET, ResetLocationState, ResetLocationAllDataState, ResetLocationInsertUpdateDetails, ResetTimeZoneList } = locationReducer.actions;
export default locationReducer.reducer;