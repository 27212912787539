import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllOocyteOocyteStages,getOocyteOocyteStageAction, insertUpdateOocyteOocyteStageAction, changeStatusOocyteOocyteStageAction, updateOocyteOocyteStagesDisplayOrder, getAllSortingOocyteOocyteStages } from "../../actions/oocyteSetupAction/oocyteStageAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    oocyteOocyteStages: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedOocyteOocyteStage: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateOocyteOocyteStage: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusOocyteOocyteStage: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingOocyteOocyteStageData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateOocyteOocyteStageDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const oocyteOocyteStageReducer = createSlice({
    name: "oocyteOocyteStagedata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetOocyteOocyteStageState(state, action) {
            state.selectedOocyteOocyteStage = INITIAL_STATE.selectedOocyteOocyteStage;
            state.changeStatusOocyteOocyteStage = INITIAL_STATE.changeStatusOocyteOocyteStage;
        },
        ResetOocyteOocyteStageAllDataState(state, action) {
            state.oocyteOocyteStages = INITIAL_STATE.oocyteOocyteStages;
        },
        ResetOocyteOocyteStageInsertUpdateDetails(state, action) {
            state.insertUpdateOocyteOocyteStage = INITIAL_STATE.insertUpdateOocyteOocyteStage;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateOocyteOocyteStageDisplayOrderData = INITIAL_STATE.updateOocyteOocyteStageDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllOocyteOocyteStages start--------------------

        builder.addCase(getAllOocyteOocyteStages.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.oocyteOocyteStages.data.records = action.payload.data.records;
            state.oocyteOocyteStages.apiMsg.status = action.meta.requestStatus;
            state.oocyteOocyteStages.apiMsg.message = action.payload.message.message;
            state.oocyteOocyteStages.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.oocyteOocyteStages.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllOocyteOocyteStages.pending, (state, action) => {

            state.oocyteOocyteStages.data.records = null;
            state.oocyteOocyteStages.apiMsg.message = action.meta.requestStatus;;
            state.oocyteOocyteStages.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllOocyteOocyteStages.rejected, (state, action) => {

            state.oocyteOocyteStages.apiMsg.message = action.error.message;
            state.oocyteOocyteStages.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllOocyteOocyteStages Action end--------------------

        //   getClient start

        builder.addCase(getOocyteOocyteStageAction.fulfilled, (state, action) => {
            state.selectedOocyteOocyteStage.data = action.payload.data
            state.selectedOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;
            state.selectedOocyteOocyteStage.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getOocyteOocyteStageAction.pending, (state, action) => {
            state.selectedOocyteOocyteStage.data = null;
            state.selectedOocyteOocyteStage.apiMsg.message = action.meta.requestStatus;;
            state.selectedOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getOocyteOocyteStageAction.rejected, (state, action) => {
            state.selectedOocyteOocyteStage.apiMsg.message = action.error.message;
            state.selectedOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateOocyteOocyteStageAction start

        builder.addCase(
            insertUpdateOocyteOocyteStageAction.fulfilled,
            (state, action) => {
                state.insertUpdateOocyteOocyteStage.data = action.payload.data.data;
                state.insertUpdateOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateOocyteOocyteStage.apiMsg.message = action.payload.message.message;
                state.insertUpdateOocyteOocyteStage.newRecord = !action.meta.arg.stageID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.oocyteOocyteStages.data.records;


                    const index = existingRecords.findIndex(record => record.stageID === newData.stageID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.oocyteOocyteStages.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.oocyteOocyteStages.data.records = state.oocyteOocyteStages.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.stageID === newData.stageID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateOocyteOocyteStageAction.pending,
            (state, action) => {
                state.insertUpdateOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateOocyteOocyteStageAction.rejected,
            (state, action) => {

                state.insertUpdateOocyteOocyteStage.apiMsg.message = action.error.message;
                state.insertUpdateOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusOocyteOocyteStageAction.fulfilled,
            (state, action) => {

                state.changeStatusOocyteOocyteStage.data = action.payload.data;
                state.changeStatusOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;
                state.changeStatusOocyteOocyteStage.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.oocyteOocyteStages.data.records = state.oocyteOocyteStages.data.records.filter(record => record.stageID !== deletedId);
                    state.oocyteOocyteStages.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.oocyteOocyteStages.data.records = state.oocyteOocyteStages.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.stageID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.oocyteOocyteStages.data.records = state.oocyteOocyteStages.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.stageID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.oocyteOocyteStages.data.records = state.oocyteOocyteStages.data.records.map(record => {
                            return {
                                ...record,
                                active: record.stageID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.oocyteOocyteStages.data.records = state.oocyteOocyteStages.data.records.map(record => {
                            return {
                                ...record,
                                active: record.stageID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusOocyteOocyteStageAction.pending,
            (state, action) => {
                state.changeStatusOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusOocyteOocyteStageAction.rejected,
            (state, action) => {
                state.changeStatusOocyteOocyteStage.apiMsg.message = action.error.message;
                state.changeStatusOocyteOocyteStage.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateOocyteOocyteStagesDisplayOrder.fulfilled, (state, action) => {
            state.updateOocyteOocyteStageDisplayOrderData.data = action.payload.data
            state.updateOocyteOocyteStageDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateOocyteOocyteStageDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateOocyteOocyteStagesDisplayOrder.pending, (state, action) => {
            state.updateOocyteOocyteStageDisplayOrderData.data = null;
            state.updateOocyteOocyteStageDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateOocyteOocyteStageDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateOocyteOocyteStagesDisplayOrder.rejected, (state, action) => {
            state.updateOocyteOocyteStageDisplayOrderData.apiMsg.message = action.error.message;
            state.updateOocyteOocyteStageDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingOocyteOocyteStages.fulfilled, (state, action) => {
            state.sortingOocyteOocyteStageData.data.records = action.payload.data.records;
            state.sortingOocyteOocyteStageData.apiMsg.status = action.meta.requestStatus;
            state.sortingOocyteOocyteStageData.apiMsg.message = action.payload.message.message;
            state.sortingOocyteOocyteStageData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingOocyteOocyteStages.pending, (state, action) => {

            state.sortingOocyteOocyteStageData.data.records = null;
            state.sortingOocyteOocyteStageData.apiMsg.message = action.meta.requestStatus;;
            state.sortingOocyteOocyteStageData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingOocyteOocyteStages.rejected, (state, action) => {

            state.sortingOocyteOocyteStageData.apiMsg.message = action.error.message;
            state.sortingOocyteOocyteStageData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetOocyteOocyteStageState, ResetOocyteOocyteStageAllDataState, ResetOocyteOocyteStageInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = oocyteOocyteStageReducer.actions;
export default oocyteOocyteStageReducer.reducer;