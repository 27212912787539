import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingsCannister, getAllSortingSettingsCannister, insertUpdateSettingsCannisterAction, changeStatusSettingsCannisterAction, getSettingsCannisterAction, getAllCopySettingsCannister } from "../../actions/settingsAction/cannisterAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    settingsCannister: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingsCannister: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingsCannister: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingsCannister: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingsCannisterData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    copySettingsCannister: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const settingsCannisterReducer = createSlice({
    name: "settingsCannisterdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingsCannisterState(state, action) {
            state.selectedSettingsCannister = INITIAL_STATE.selectedSettingsCannister;
            state.changeStatusSettingsCannister = INITIAL_STATE.changeStatusSettingsCannister;
        },
        ResetSettingsCannisterAllDataState(state, action) {
            state.settingsCannister = INITIAL_STATE.settingsCannister;
        },
        ResetSettingsCannisterInsertUpdateDetails(state, action) {
            state.insertUpdateSettingsCannister = INITIAL_STATE.insertUpdateSettingsCannister;
        },
        ResetCopySettingsCannisterState(state, action){
            state.copySettingsCannister = INITIAL_STATE.copySettingsCannister;
        }
    },
    extraReducers: (builder) => {

        //  --------getAllSettingsCannister start--------------------

        builder.addCase(getAllSettingsCannister.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingsCannister.data.records = action.payload.data.records;
            state.settingsCannister.apiMsg.status = action.meta.requestStatus;
            state.settingsCannister.apiMsg.message = action.payload.message.message;
            state.settingsCannister.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsCannister.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsCannister.pending, (state, action) => {

            state.settingsCannister.data.records = null;
            state.settingsCannister.apiMsg.message = action.meta.requestStatus;;
            state.settingsCannister.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsCannister.rejected, (state, action) => {

            state.settingsCannister.apiMsg.message = action.error.message;
            state.settingsCannister.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingsCannister Action end--------------------

        //   getClient start

        builder.addCase(getSettingsCannisterAction.fulfilled, (state, action) => {
            state.selectedSettingsCannister.data = action.payload.data
            state.selectedSettingsCannister.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingsCannister.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsCannisterAction.pending, (state, action) => {
            state.selectedSettingsCannister.data = null;
            state.selectedSettingsCannister.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingsCannister.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsCannisterAction.rejected, (state, action) => {
            state.selectedSettingsCannister.apiMsg.message = action.error.message;
            state.selectedSettingsCannister.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsCannisterAction start

        builder.addCase(
            insertUpdateSettingsCannisterAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingsCannister.data = action.payload.data.data;
                state.insertUpdateSettingsCannister.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingsCannister.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingsCannister.newRecord = !action?.meta?.arg[0]?.canisterID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingsCannister.data.records;


                    const index = existingRecords.findIndex(record => record.canisterID === newData[0]?.canisterID);
                    console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData[0]?.name,
                            tankID : newData[0]?.tankID,
                            centerID : newData[0]?.centerID,
                            locationID: newData[0]?.locationID,
                            active: newData[0]?.active
                        };
                    }
                    else {
                        state.settingsCannister.data.totalRecords += newData?.length;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsCannisterAction.pending,
            (state, action) => {
                state.insertUpdateSettingsCannister.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsCannisterAction.rejected,
            (state, action) => {
                state.insertUpdateSettingsCannister.data = action.payload.data;
                state.insertUpdateSettingsCannister.apiMsg.message = action.payload.errors[0]?.message;
                state.insertUpdateSettingsCannister.apiMsg.status = action.meta.requestStatus;

                toast.error(action.payload.errors[0].message ?  action.payload.errors[0].message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsCannisterAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingsCannister.data = action.payload.data;
                state.changeStatusSettingsCannister.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingsCannister.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Cannister.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.CanisterID;
                    state.settingsCannister.data.records = state.settingsCannister.data.records.filter(record => record.canisterID !== deletedId);
                    state.settingsCannister.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Cannister.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Cannister.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Cannister.ChangeStatus.Active) {
                        state.settingsCannister.data.records = state.settingsCannister.data.records.map(record => {
                            return {
                                ...record,
                                active: record.canisterID === action.meta.arg.CanisterID ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Cannister.ChangeStatus.Inactive) {
                        state.settingsCannister.data.records = state.settingsCannister.data.records.map(record => {
                            return {
                                ...record,
                                active: record.canisterID === action.meta.arg.CanisterID ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsCannisterAction.pending,
            (state, action) => {
                state.changeStatusSettingsCannister.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsCannisterAction.rejected,
            (state, action) => {
                state.changeStatusSettingsCannister.apiMsg.message = action.error.message;
                state.changeStatusSettingsCannister.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(getAllSortingSettingsCannister.fulfilled, (state, action) => {
            state.sortingSettingsCannisterData.data.records = action.payload.data.records;
            state.sortingSettingsCannisterData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingsCannisterData.apiMsg.message = action.payload.message.message;
            state.sortingSettingsCannisterData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsCannister.pending, (state, action) => {

            state.sortingSettingsCannisterData.data.records = null;
            state.sortingSettingsCannisterData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingsCannisterData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsCannister.rejected, (state, action) => {

            state.sortingSettingsCannisterData.apiMsg.message = action.error.message;
            state.sortingSettingsCannisterData.apiMsg.status = action.meta.requestStatus;
        });

        // copysettignscannister start

        builder.addCase(getAllCopySettingsCannister.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.copySettingsCannister.data.records = action.payload.data.records;
            state.copySettingsCannister.apiMsg.status = action.meta.requestStatus;
            state.copySettingsCannister.apiMsg.message = action.payload.message.message;
            state.copySettingsCannister.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsCannister.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllCopySettingsCannister.pending, (state, action) => {

            state.copySettingsCannister.data.records = null;
            state.copySettingsCannister.apiMsg.message = action.meta.requestStatus;
            state.copySettingsCannister.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCopySettingsCannister.rejected, (state, action) => {

            state.copySettingsCannister.apiMsg.message = action.error.message;
            state.copySettingsCannister.apiMsg.status = action.meta.requestStatus;
        });

    },
});
export const { RESET, ResetSettingsCannisterState, ResetSettingsCannisterAllDataState, ResetSettingsCannisterInsertUpdateDetails, ResetCopySettingsCannisterState } = settingsCannisterReducer.actions;
export default settingsCannisterReducer.reducer;