export const Enums = {
    Settings: {

        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
            DefaultSelectedOn: 4,
            DefaultSelectedOff: 5
        },
        SortBy: {
            Id: 0,
            DisplayOrder: 1,
            Name: 2,
            Active: 3,
            DefaultSelected: 4,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },
    
    Roles : {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            Id: 0,
            RoleName: 1,
            ClientName: 2,
            CenterName: 3,
            LocationName: 4,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },

    AdminUsers: {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            Id: 0,
            FirstName: 1,
            LastName: 2,
            UserName: 3,
            EmailID: 4,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },

    Cane: {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            Id: 0,
            CenterID : 1,
            LocationID: 2,
            Cane: 3,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },

    Tank: {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            Id: 0,
            CenterID : 1,
            LocationID: 2,
            Tank: 3,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },

    Cannister: {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            Id: 0,
            CenterID : 1,
            LocationID: 2,
            TankID : 3,
            Cannister: 4,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },

    Employees: {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            Id: 0,
            EmpCode : 1,
            FirstName: 2,
            LastName: 3,
            UserName: 4,
            JobTitle: 5,
            DOB : 6,
            Gender : 7,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },
    Clients : {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            ClientID : 0,
            Name: 1,
            PointOfContact: 2,
            Email: 3,
            Username: 4,
            NPI: 5,
            Code : 6,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },
    SpecimenTypeStatus : {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
            DefaultSelectedOn: 4,
            DefaultSelectedOff: 5
        },
        SortBy: {
            StatusMasterID : 0,
            DisplayOrder: 1,
            Name : 2,
            Active : 3,
            DefaultSelected : 4,
            DisplayName: 5,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },
    Center : {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            CenterID : 0,
            Name: 1,
            PointOfContact: 2,
            Email: 3,
            ClientName: 4,
            NPI: 5,
            Code: 6,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },
    Location: {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            LocationID: 0,
            Code: 1,
            Name: 2,
            PointOfContact: 3,
            Email: 4,
            ClientName: 5,
            CenterName : 6,
            NPI: 7,
            CLIA : 8,
            TimeZone : 9,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },
    Patients: {
        ChangeStatus: {
            Active: 1,
            Inactive: 2,
            Delete: 3,
        },
        SortBy: {
            PatientID: 0,
            CenterID : 1,
            LocationID : 2,
            MRN: 3,
            Patient: 4,
            Provider: 5,
            DOB: 6,
            Cell: 7,
            EmailID : 8,
            FromDate : 9,
            ToDate : 10,
        },
        SortOrder: {
            Ascending: 0,
            Descending: 1
        }
    },

    PaginationSettings: {
        DefaultPageSize: 10,
    }
}

export const Menus = {
    // Dashboard
    Dashboard : {"route":"/dashboard","pageName":"Dashboard" , "routeName" : "Dashboard" } ,
    // Clients
    Clients : {"route":"/clients","pageName":"Clients" , "routeName" : "Clients" } ,
    // Center
    Centers : {"route":"/centers","pageName":"Centers" , "routeName" : "Centers" } ,
    // Patient
    Patients : {"route":"/patients","pageName":"Patients" , "routeName" : "Patients" } ,
    // Location
    Locations : {"route":"/locations","pageName":"Locations" , "routeName" : "Locations" } ,
    // Sample Master
    SampleMaster : {"route":"/samplemaster","pageName":"SampleMaster" , "routeName" : "SampleMaster" } ,
    SampleCollection : {"route":"/samplemaster/samplecollection","pageName":"SampleCollection" , "routeName" : "SampleCollection" } ,
    SampleConfirmation : {"route":"/samplemaster/sampleconfirmation","pageName":"SampleConfirmation" , "routeName" : "SampleConfirmation" } ,
    LandingScreen : {"route":"/samplemaster/landingscreen","pageName":"LandingScreen" , "routeName" : "LandingScreen" } ,
    ManageInventory : {"route":"/samplemaster/updatestatus","pageName":"ManageInventory" , "routeName" : "UpdateStatus" } ,
    // Inventory Management
    InventoryManagement : {"route":"/inventorymanagement","pageName":"InventoryManagement" , "routeName" : "InventoryManagement" } ,
    InventoryManagementEmbryo : {"route":"/inventorymanagement/embryo","pageName":"InventoryManagementEmbryo" , "routeName" : "Embryo" } ,
    InventoryManagementOoctye : {"route":"/inventorymanagement/oocyte","pageName":"InventoryManagementOoctye" , "routeName" : "Oocyte" } ,
    InventoryManagementSperm : {"route":"/inventorymanagement/sperm","pageName":"InventoryManagementSperm" , "routeName" : "Sperm" } ,
    // Long Term Facility
    LongTermFacility : {"route":"/longtermfacility","pageName":"LongTermFacility" , "routeName" : "LongTermFacility" } ,
    // Manage Shipment
    ManageShipment : {"route":"/manageshipment","pageName":"ManageShipment" , "routeName" : "ManageShipment" } ,
    // Settings 
    Settings : {"route":"/settings","pageName":"Settings" , "routeName" : "Settings" } ,
    SettingsAdminUsers : {"route":"/settings/user","pageName":"SettingsAdminUsers" , "routeName" : "User" } ,
    SettingsCane : {"route":"/settings/cane","pageName":"SettingsCane" , "routeName" : "Cane" } ,
    SettingsTank : {"route":"/settings/tank","pageName":"SettingsTank" , "routeName" : "Tank" } ,
    SettingsCannister : {"route":"/settings/canister","pageName":"SettingsCannister" , "routeName" : "Canister" } ,
    SettingsEmployee : {"route":"/settings/employee","pageName":"Employee" , "routeName" : "Employee" },
    SettingsRoles : {"route":"/settings/roles","pageName":"SettingsRoles" , "routeName" : "Roles" } ,
    SettingsPatientLanguage : {"route":"/settings/patientlanguage","pageName":"SettingsPatientLanguage" , "routeName" : "PatientLanguage" } ,
    SettingsJobTitle : {"route":"/settings/jobtitle","pageName":"SettingsJobTitle" , "routeName" : "JobTitle" } ,
    SettingsSpecimenTypeStatus : {"route":"/settings/specimentypestatus","pageName":"SettingsSpecimenTypeStatus" , "routeName" : "SpecimenTypeStatus" } ,
    SettingsInsurance : {"route":"/settings/insurance","pageName":"SettingsInsurance" , "routeName" : "Insurance" } ,
    // Embryo Setup
    EmbryoSetup : {"route":"/embryosetup","pageName":"EmbryoSetup" , "routeName" : "EmbryoSetup" } ,
    EmbryoSetupPGTResult:  {"route":"/embryosetup/pgtresult","pageName":"EmbryoSetupPGTResult", "routeName" : "PGTResult"} ,
    EmbryoSetupEGGSource:  {"route":"/embryosetup/eggsource","pageName":"EmbryoSetupEGGSource", "routeName" : "EggSource"} ,
    EmbryoSetupSpermSource:  {"route":"/embryosetup/spermsource","pageName":"EmbryoSetupSpermSource", "routeName" : "SpermSource"} ,
    EmbryoSetupFreezeMethod:  {"route":"/embryosetup/freezemethod","pageName":"EmbryoSetupFreezeMethod", "routeName" : "FreezeMethod"} ,
    EmbryoSetupDeviceType:  {"route":"/embryosetup/devicetype","pageName":"EmbryoSetupDeviceType", "routeName" : "DeviceType"} ,
    EmbryoSetupPGTLab:  {"route":"/embryosetup/pgtlab","pageName":"EmbryoSetupPGTLab", "routeName" : "PGTLab"} ,
    EmbryoSetupCryoTech:  {"route":"/embryosetup/cryotech","pageName":"EmbryoSetupCryoTech", "routeName" : "CryoTech"} ,
    EmbryoSetupBXTech:  {"route":"/embryosetup/bxtech","pageName":"EmbryoSetupBXTech", "routeName" : "BXTech"} ,
    EmbryoSetupTubingTech:  {"route":"/embryosetup/tubingtech","pageName":"EmbryoSetupTubingTech", "routeName" : "TubingTech"} ,
    // Oocyte Setup
    OocyteSetup : {"route":"/oocytesetup","pageName":"OocyteSetup" , "routeName" : "OocyteSetup" } ,
    OocyteSetupOocyteStage:  {"route":"/oocytesetup/oocytestage","pageName":"OocyteSetupOocyteStage", "routeName" : "OocyteStage"} ,
    OocyteSetupOocyteDay:  {"route":"/oocytesetup/oocyteday","pageName":"OocyteSetupOocyteDay", "routeName" : "OocyteDay"} ,
    OocyteSetupEggSource:  {"route":"/oocytesetup/eggsource","pageName":"OocyteSetupEggSource", "routeName" : "EGGSource"} ,
    OocyteSetupFreezeMethod:  {"route":"/oocytesetup/freezemethod","pageName":"OocyteSetupFreezeMethod", "routeName" : "FreezeMethod"} ,
    OocyteSetupDeviceType:  {"route":"/oocytesetup/devicetype","pageName":"OocyteSetupDeviceType", "routeName" : "DeviceType"} ,
    OocyteSetupCaneLabel:  {"route":"/oocytesetup/canelabel","pageName":"OocyteSetupCaneLabel", "routeName" : "CaneLabel"} ,
    OocyteSetupCryoTech:  {"route":"/oocytesetup/cryotech","pageName":"OocyteSetupCryoTech", "routeName" : "CryoTech"} ,
    // Sperm Setup
    SpermSetup : {"route":"/spermsetup","pageName":"SpermSetup" , "routeName" : "SpermSetup" } ,
    SpermSetupSampleType:  {"route":"/spermsetup/sampletype","pageName":"SpermSetupSampleType", "routeName" : "SampleType"} ,
    SpermSetupSpermSource:  {"route":"/spermsetup/spermsource","pageName":"SpermSetupSpermSource", "routeName" : "SpermSource"} ,
    SpermSetupFreezeMethod:  {"route":"/spermsetup/freezemethod","pageName":"SpermSetupFreezeMethod", "routeName" : "FreezeMethod"} , 
    SpermSetupVialType:  {"route":"/spermsetup/vialtype","pageName":"SpermSetupVialType", "routeName" : "VialType"} ,
    SpermSetupCryoTech:  {"route":"/spermsetup/cryotech","pageName":"SpermSetupCryoTech", "routeName" : "CryoTech"} ,    
    // Configuration
    Configuration : {"route":"/configuration","pageName":"Configuration" , "routeName" : "Configuration" } ,
    ConfigurationLookupMaster:  {"route":"/configuration/lookupmaster","pageName":"ConfigurationLookupMaster", "routeName" : "LookupMaster"} ,
    // Reports
    Reports : {"route":"/reports","pageName":"Reports" , "routeName" : "Reports" } ,
    ReportsAuditLogReport:  {"route":"/reports/auditlogreport","pageName":"ReportsAuditLogReport", "routeName" : "AuditLogReport"} ,
    ReportsInventory:  {"route":"/reports/inventory","pageName":"ReportsInventory", "routeName" : "Inventory"} ,
}

export const UserType = {
    MasterAdmin : 1,
    MasterAdminUser : 2,
    Clients : 3,
    LocationEmployee : 4,
    EndCustomer : 5,
}


