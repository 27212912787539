import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllOocyteOocyteDay, getOocyteOocyteDayAction, insertUpdateOocyteOocyteDayAction, changeStatusOocyteOocyteDayAction, updateOocyteOocyteDayDisplayOrder, getAllSortingOocyteOocyteDay } from "../../actions/oocyteSetupAction/oocyteDayAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    oocyteOocyteDay: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedOocyteOocyteDay: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateOocyteOocyteDay: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusOocyteOocyteDay: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingOocyteOocyteDayData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateOocyteOocyteDayDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const oocyteOocyteDayReducer = createSlice({
    name: "oocyteOocyteDaydata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetOocyteOocyteDayState(state, action) {
            state.selectedOocyteOocyteDay = INITIAL_STATE.selectedOocyteOocyteDay;
            state.changeStatusOocyteOocyteDay = INITIAL_STATE.changeStatusOocyteOocyteDay;
        },
        ResetOocyteOocyteDayAllDataState(state, action) {
            state.oocyteOocyteDay = INITIAL_STATE.oocyteOocyteDay;
        },
        ResetOocyteOocyteDayInsertUpdateDetails(state, action) {
            state.insertUpdateOocyteOocyteDay = INITIAL_STATE.insertUpdateOocyteOocyteDay;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateOocyteOocyteDayDisplayOrderData = INITIAL_STATE.updateOocyteOocyteDayDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllOocyteOocyteDay start--------------------

        builder.addCase(getAllOocyteOocyteDay.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.oocyteOocyteDay.data.records = action.payload.data.records;
            state.oocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
            state.oocyteOocyteDay.apiMsg.message = action.payload.message.message;
            state.oocyteOocyteDay.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.oocyteOocyteDay.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllOocyteOocyteDay.pending, (state, action) => {

            state.oocyteOocyteDay.data.records = null;
            state.oocyteOocyteDay.apiMsg.message = action.meta.requestStatus;;
            state.oocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllOocyteOocyteDay.rejected, (state, action) => {

            state.oocyteOocyteDay.apiMsg.message = action.error.message;
            state.oocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllOocyteOocyteDay Action end--------------------

        //   getClient start

        builder.addCase(getOocyteOocyteDayAction.fulfilled, (state, action) => {
            state.selectedOocyteOocyteDay.data = action.payload.data
            state.selectedOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
            state.selectedOocyteOocyteDay.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getOocyteOocyteDayAction.pending, (state, action) => {
            state.selectedOocyteOocyteDay.data = null;
            state.selectedOocyteOocyteDay.apiMsg.message = action.meta.requestStatus;;
            state.selectedOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getOocyteOocyteDayAction.rejected, (state, action) => {
            state.selectedOocyteOocyteDay.apiMsg.message = action.error.message;
            state.selectedOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateOocyteOocyteDayAction start

        builder.addCase(
            insertUpdateOocyteOocyteDayAction.fulfilled,
            (state, action) => {
                state.insertUpdateOocyteOocyteDay.data = action.payload.data.data;
                state.insertUpdateOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateOocyteOocyteDay.apiMsg.message = action.payload.message.message;
                state.insertUpdateOocyteOocyteDay.newRecord = !action.meta.arg.dayID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.oocyteOocyteDay.data.records;


                    const index = existingRecords.findIndex(record => record.dayID === newData.dayID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.oocyteOocyteDay.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.oocyteOocyteDay.data.records = state.oocyteOocyteDay.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.dayID === newData.dayID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateOocyteOocyteDayAction.pending,
            (state, action) => {
                state.insertUpdateOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateOocyteOocyteDayAction.rejected,
            (state, action) => {

                state.insertUpdateOocyteOocyteDay.apiMsg.message = action.error.message;
                state.insertUpdateOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusOocyteOocyteDayAction.fulfilled,
            (state, action) => {

                state.changeStatusOocyteOocyteDay.data = action.payload.data;
                state.changeStatusOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
                state.changeStatusOocyteOocyteDay.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.oocyteOocyteDay.data.records = state.oocyteOocyteDay.data.records.filter(record => record.dayID !== deletedId);
                    state.oocyteOocyteDay.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.oocyteOocyteDay.data.records = state.oocyteOocyteDay.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.dayID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.oocyteOocyteDay.data.records = state.oocyteOocyteDay.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.dayID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.oocyteOocyteDay.data.records = state.oocyteOocyteDay.data.records.map(record => {
                            return {
                                ...record,
                                active: record.dayID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.oocyteOocyteDay.data.records = state.oocyteOocyteDay.data.records.map(record => {
                            return {
                                ...record,
                                active: record.dayID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusOocyteOocyteDayAction.pending,
            (state, action) => {
                state.changeStatusOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusOocyteOocyteDayAction.rejected,
            (state, action) => {
                state.changeStatusOocyteOocyteDay.apiMsg.message = action.error.message;
                state.changeStatusOocyteOocyteDay.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateOocyteOocyteDayDisplayOrder.fulfilled, (state, action) => {
            state.updateOocyteOocyteDayDisplayOrderData.data = action.payload.data
            state.updateOocyteOocyteDayDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateOocyteOocyteDayDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateOocyteOocyteDayDisplayOrder.pending, (state, action) => {
            state.updateOocyteOocyteDayDisplayOrderData.data = null;
            state.updateOocyteOocyteDayDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateOocyteOocyteDayDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateOocyteOocyteDayDisplayOrder.rejected, (state, action) => {
            state.updateOocyteOocyteDayDisplayOrderData.apiMsg.message = action.error.message;
            state.updateOocyteOocyteDayDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingOocyteOocyteDay.fulfilled, (state, action) => {
            state.sortingOocyteOocyteDayData.data.records = action.payload.data.records;
            state.sortingOocyteOocyteDayData.apiMsg.status = action.meta.requestStatus;
            state.sortingOocyteOocyteDayData.apiMsg.message = action.payload.message.message;
            state.sortingOocyteOocyteDayData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingOocyteOocyteDay.pending, (state, action) => {

            state.sortingOocyteOocyteDayData.data.records = null;
            state.sortingOocyteOocyteDayData.apiMsg.message = action.meta.requestStatus;;
            state.sortingOocyteOocyteDayData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingOocyteOocyteDay.rejected, (state, action) => {

            state.sortingOocyteOocyteDayData.apiMsg.message = action.error.message;
            state.sortingOocyteOocyteDayData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetOocyteOocyteDayState, ResetOocyteOocyteDayAllDataState, ResetOocyteOocyteDayInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = oocyteOocyteDayReducer.actions;
export default oocyteOocyteDayReducer.reducer;