import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../../API/api-urls";
import { apiCall } from "../../../API/index";
import { Enums } from "../../../helper/constants/enums";

export const getAllEmbryoSpermSource = createAsyncThunk(
    "EmbryoSpermSource/getAllEmbryoSpermSource",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                Name: data.name ? data.name : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null,
                DefaultSelected: data.defaultSelected !== undefined && data.defaultSelected !== "" ? JSON.parse(data.defaultSelected) : null
            }
        };
        return apiCall(`${apiUrls.embryoSpermSource.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response,'pgt result response');
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getEmbryoSpermSourceAction = createAsyncThunk(
    "EmbryoSpermSource/getEmbryoSpermSource",
    async (data) => {
        let d = {
            Device: 1,
            Id: data
        };
        return apiCall(`${apiUrls.embryoSpermSource.getEmbryoSpermSource}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateEmbryoSpermSourceAction = createAsyncThunk(
    "EmbryoSpermSource/embryoSpermSourceInsertUpdate",
    async (data) => {
        return apiCall(`${apiUrls.embryoSpermSource.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.pgtResultID ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusEmbryoSpermSourceAction = createAsyncThunk(
    "EmbryoSpermSource/changeStatusEmbryoSpermSource",
    async (data) => {

        return apiCall(`${apiUrls.embryoSpermSource.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingSpermSource = createAsyncThunk(
    "EmbryoSpermSource/getAllSortingSpermSource",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,  
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.embryoSpermSource.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const updateSpermSourceDisplayOrder = createAsyncThunk(
    "EmbryoSpermSource/insertupdatedisplayorder",
    async (data) => {
        return apiCall(`${apiUrls.embryoSpermSource.updateDisplayOrder}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
