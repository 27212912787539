import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllEmbryoPgtResults, getEmbryoPgtResultAction, insertUpdateEmbryoPgtResultAction, changeStatusEmbryoPgtResultAction, updatePGTResultDisplayOrder, getAllSortingPgtResults } from "../../actions/embryoSetupAction/pgtResultAction";
import { Enums } from "../../../helper/constants/enums";


const INITIAL_STATE = {
    embryoPgtResults: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedEmbryoPgtResult: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateEmbryoPgtResult: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusEmbryoPgtResult: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingPGTResultsData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updatePGTResultDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const embryoPgtResultReducer = createSlice({
    name: "embryoPgtStatusdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmbryoPgtResultState(state, action) {
            state.selectedEmbryoPgtResult = INITIAL_STATE.selectedEmbryoPgtResult;
            state.changeStatusEmbryoPgtResult = INITIAL_STATE.changeStatusEmbryoPgtResult;
        },
        ResetEmbryoPgtResultsAllDataState(state, action) {
            state.embryoPgtResults = INITIAL_STATE.embryoPgtResults;
        },
        ResetPGTResultInsertUpdateDetails(state, action) {
            state.insertUpdateEmbryoPgtResult = INITIAL_STATE.insertUpdateEmbryoPgtResult;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updatePGTResultDisplayOrderData = INITIAL_STATE.updatePGTResultDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllEmbryoPgtResults start--------------------

        builder.addCase(getAllEmbryoPgtResults.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.embryoPgtResults.data.records = action.payload.data.records;
            state.embryoPgtResults.apiMsg.status = action.meta.requestStatus;
            state.embryoPgtResults.apiMsg.message = action.payload.message.message;
            state.embryoPgtResults.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.embryoPgtResults.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllEmbryoPgtResults.pending, (state, action) => {

            state.embryoPgtResults.data.records = null;
            state.embryoPgtResults.apiMsg.message = action.meta.requestStatus;;
            state.embryoPgtResults.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmbryoPgtResults.rejected, (state, action) => {

            state.embryoPgtResults.apiMsg.message = action.error.message;
            state.embryoPgtResults.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllEmbryoPgtResults Action end--------------------

        //   getClient start

        builder.addCase(getEmbryoPgtResultAction.fulfilled, (state, action) => {
            state.selectedEmbryoPgtResult.data = action.payload.data
            state.selectedEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;
            state.selectedEmbryoPgtResult.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getEmbryoPgtResultAction.pending, (state, action) => {
            state.selectedEmbryoPgtResult.data = null;
            state.selectedEmbryoPgtResult.apiMsg.message = action.meta.requestStatus;;
            state.selectedEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getEmbryoPgtResultAction.rejected, (state, action) => {
            state.selectedEmbryoPgtResult.apiMsg.message = action.error.message;
            state.selectedEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateEmbryoPgtResultAction start

        builder.addCase(
            insertUpdateEmbryoPgtResultAction.fulfilled,
            (state, action) => {
                state.insertUpdateEmbryoPgtResult.data = action.payload.data.data;
                state.insertUpdateEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateEmbryoPgtResult.apiMsg.message = action.payload.message.message;
                state.insertUpdateEmbryoPgtResult.newRecord = !action.meta.arg.pgtResultID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.embryoPgtResults.data.records;


                    const index = existingRecords.findIndex(record => record.pgtResultID === newData.pgtResultID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.embryoPgtResults.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.embryoPgtResults.data.records = state.embryoPgtResults.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.pgtResultID === newData.pgtResultID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateEmbryoPgtResultAction.pending,
            (state, action) => {
                state.insertUpdateEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateEmbryoPgtResultAction.rejected,
            (state, action) => {

                state.insertUpdateEmbryoPgtResult.apiMsg.message = action.error.message;
                state.insertUpdateEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusEmbryoPgtResultAction.fulfilled,
            (state, action) => {

                state.changeStatusEmbryoPgtResult.data = action.payload.data;
                state.changeStatusEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;
                state.changeStatusEmbryoPgtResult.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.embryoPgtResults.data.records = state.embryoPgtResults.data.records.filter(record => record.pgtResultID !== deletedId);
                    state.embryoPgtResults.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.embryoPgtResults.data.records = state.embryoPgtResults.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.pgtResultID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.embryoPgtResults.data.records = state.embryoPgtResults.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.pgtResultID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.embryoPgtResults.data.records = state.embryoPgtResults.data.records.map(record => {
                            return {
                                ...record,
                                active: record.pgtResultID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.embryoPgtResults.data.records = state.embryoPgtResults.data.records.map(record => {
                            return {
                                ...record,
                                active: record.pgtResultID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusEmbryoPgtResultAction.pending,
            (state, action) => {
                state.changeStatusEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusEmbryoPgtResultAction.rejected,
            (state, action) => {
                state.changeStatusEmbryoPgtResult.apiMsg.message = action.error.message;
                state.changeStatusEmbryoPgtResult.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updatePGTResultDisplayOrder.fulfilled, (state, action) => {
            state.updatePGTResultDisplayOrderData.data = action.payload.data
            state.updatePGTResultDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updatePGTResultDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updatePGTResultDisplayOrder.pending, (state, action) => {
            state.updatePGTResultDisplayOrderData.data = null;
            state.updatePGTResultDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updatePGTResultDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updatePGTResultDisplayOrder.rejected, (state, action) => {
            state.updatePGTResultDisplayOrderData.apiMsg.message = action.error.message;
            state.updatePGTResultDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingPgtResults.fulfilled, (state, action) => {
            state.sortingPGTResultsData.data.records = action.payload.data.records;
            state.sortingPGTResultsData.apiMsg.status = action.meta.requestStatus;
            state.sortingPGTResultsData.apiMsg.message = action.payload.message.message;
            state.sortingPGTResultsData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingPgtResults.pending, (state, action) => {

            state.sortingPGTResultsData.data.records = null;
            state.sortingPGTResultsData.apiMsg.message = action.meta.requestStatus;;
            state.sortingPGTResultsData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingPgtResults.rejected, (state, action) => {

            state.sortingPGTResultsData.apiMsg.message = action.error.message;
            state.sortingPGTResultsData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetEmbryoPgtResultState, ResetEmbryoPgtResultsAllDataState, ResetPGTResultInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = embryoPgtResultReducer.actions;
export default embryoPgtResultReducer.reducer;