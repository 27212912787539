import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllEmbryoDeviceTypes, getEmbryoDeviceTypeAction, insertUpdateEmbryoDeviceTypeAction, changeStatusEmbryoDeviceTypeAction, getAllSortingDeviceType, updateDeviceTypeDisplayOrder } from "../../actions/embryoSetupAction/deviceTypeAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    embryoDeviceTypes: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedEmbryoDeviceType: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateEmbryoDeviceType: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusEmbryoDeviceType: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    sortingDeviceTypeData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateDeviceTypeDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const embryoDeviceTypeReducer = createSlice({
    name: "embryoDeviceTypedata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmbryoDeviceTypeState(state, action) {
            state.selectedEmbryoDeviceType = INITIAL_STATE.selectedEmbryoDeviceType;
            state.changeStatusEmbryoDeviceType = INITIAL_STATE.changeStatusEmbryoDeviceType;
        },
        ResetEmbryoDeviceTypeAllDataState(state, action) {
            state.embryoDeviceTypes = INITIAL_STATE.embryoDeviceTypes;
        },
        ResetDeviceTypeInsertUpdateDetails(state, action) {
            state.insertUpdateEmbryoDeviceType = INITIAL_STATE.insertUpdateEmbryoDeviceType;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateDeviceTypeDisplayOrderData = INITIAL_STATE.updateDeviceTypeDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllEmbryoDeviceTypes start--------------------

        builder.addCase(getAllEmbryoDeviceTypes.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.embryoDeviceTypes.data.records = action.payload.data.records;
            state.embryoDeviceTypes.apiMsg.status = action.meta.requestStatus;
            state.embryoDeviceTypes.apiMsg.message = action.payload.message.message;
            state.embryoDeviceTypes.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.embryoDeviceTypes.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllEmbryoDeviceTypes.pending, (state, action) => {

            state.embryoDeviceTypes.data.records = null;
            state.embryoDeviceTypes.apiMsg.message = action.meta.requestStatus;;
            state.embryoDeviceTypes.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmbryoDeviceTypes.rejected, (state, action) => {

            state.embryoDeviceTypes.apiMsg.message = action.error.message;
            state.embryoDeviceTypes.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllEmbryoDeviceTypes Action end--------------------

        //   getClient start

        builder.addCase(getEmbryoDeviceTypeAction.fulfilled, (state, action) => {
            state.selectedEmbryoDeviceType.data = action.payload.data
            state.selectedEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;
            state.selectedEmbryoDeviceType.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getEmbryoDeviceTypeAction.pending, (state, action) => {
            state.selectedEmbryoDeviceType.data = null;
            state.selectedEmbryoDeviceType.apiMsg.message = action.meta.requestStatus;;
            state.selectedEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getEmbryoDeviceTypeAction.rejected, (state, action) => {
            state.selectedEmbryoDeviceType.apiMsg.message = action.error.message;
            state.selectedEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateEmbryoDeviceTypeAction start

        builder.addCase(
            insertUpdateEmbryoDeviceTypeAction.fulfilled,
            (state, action) => {
                state.insertUpdateEmbryoDeviceType.data = action.payload.data.data;
                state.insertUpdateEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateEmbryoDeviceType.apiMsg.message = action.payload.message.message;
                state.insertUpdateEmbryoDeviceType.newRecord = !action.meta.arg.deviceTypeID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.embryoDeviceTypes.data.records;


                    const index = existingRecords.findIndex(record => record.deviceTypeID === newData.deviceTypeID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.embryoDeviceTypes.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.embryoDeviceTypes.data.records = state.embryoDeviceTypes.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.deviceTypeID === newData.deviceTypeID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateEmbryoDeviceTypeAction.pending,
            (state, action) => {
                state.insertUpdateEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateEmbryoDeviceTypeAction.rejected,
            (state, action) => {

                state.insertUpdateEmbryoDeviceType.apiMsg.message = action.error.message;
                state.insertUpdateEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusEmbryoDeviceTypeAction.fulfilled,
            (state, action) => {

                state.changeStatusEmbryoDeviceType.data = action.payload.data;
                state.changeStatusEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;
                state.changeStatusEmbryoDeviceType.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.embryoDeviceTypes.data.records = state.embryoDeviceTypes.data.records.filter(record => record.deviceTypeID !== deletedId);
                    state.embryoDeviceTypes.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.embryoDeviceTypes.data.records = state.embryoDeviceTypes.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.deviceTypeID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.embryoDeviceTypes.data.records = state.embryoDeviceTypes.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.deviceTypeID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.embryoDeviceTypes.data.records = state.embryoDeviceTypes.data.records.map(record => {
                            return {
                                ...record,
                                active: record.deviceTypeID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.embryoDeviceTypes.data.records = state.embryoDeviceTypes.data.records.map(record => {
                            return {
                                ...record,
                                active: record.deviceTypeID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusEmbryoDeviceTypeAction.pending,
            (state, action) => {
                state.changeStatusEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusEmbryoDeviceTypeAction.rejected,
            (state, action) => {
                state.changeStatusEmbryoDeviceType.apiMsg.message = action.error.message;
                state.changeStatusEmbryoDeviceType.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

//--------------------- Device Type Sorting Order ---------------------------//

builder.addCase(updateDeviceTypeDisplayOrder.fulfilled, (state, action) => {
    state.updateDeviceTypeDisplayOrderData.data = action.payload.data
    state.updateDeviceTypeDisplayOrderData.apiMsg.status = action.meta.requestStatus;
    state.updateDeviceTypeDisplayOrderData.apiMsg.message = action.payload.message.message;

    toast.success(action.payload.message.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success"
    });
});

builder.addCase(updateDeviceTypeDisplayOrder.pending, (state, action) => {
    state.updateDeviceTypeDisplayOrderData.data = null;
    state.updateDeviceTypeDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
    state.updateDeviceTypeDisplayOrderData.apiMsg.status = action.meta.requestStatus;
});

builder.addCase(updateDeviceTypeDisplayOrder.rejected, (state, action) => {
    state.updateDeviceTypeDisplayOrderData.apiMsg.message = action.error.message;
    state.updateDeviceTypeDisplayOrderData.apiMsg.status = action.meta.requestStatus;
    toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "error"
    });
});

//-------------------Device Type Get All Sorting-------------------------------//

builder.addCase(getAllSortingDeviceType.fulfilled, (state, action) => {
    state.sortingDeviceTypeData.data.records = action.payload.data.records;
    state.sortingDeviceTypeData.apiMsg.status = action.meta.requestStatus;
    state.sortingDeviceTypeData.apiMsg.message = action.payload.message.message;
    state.sortingDeviceTypeData.data.totalRecords = action.payload.data.totalRecords;

});
builder.addCase(getAllSortingDeviceType.pending, (state, action) => {
    
    state.sortingDeviceTypeData.data.records = null;
    state.sortingDeviceTypeData.apiMsg.message = action.meta.requestStatus;
    state.sortingDeviceTypeData.apiMsg.status = action.meta.requestStatus;
});
builder.addCase(getAllSortingDeviceType.rejected, (state, action) => {

    state.sortingDeviceTypeData.apiMsg.message = action.error.message;
    state.sortingDeviceTypeData.apiMsg.status = action.meta.requestStatus;
});


    },
});
export const { RESET, ResetEmbryoDeviceTypeState, ResetDeviceTypeInsertUpdateDetails, ResetDisplayOrderUpdateDetails, ResetEmbryoDeviceTypeAllDataState } = embryoDeviceTypeReducer.actions;
export default embryoDeviceTypeReducer.reducer;