import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllEmbryoPgtLabs, getEmbryoPgtLabAction, insertUpdateEmbryoPgtLabAction, changeStatusEmbryoPgtLabAction, updatePGTLabDisplayOrder, getAllSortingPgtLabs } from "../../actions/embryoSetupAction/pgtLabAction";
import { Enums } from "../../../helper/constants/enums";


const INITIAL_STATE = {
    embryoPgtLabs: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedEmbryoPgtLab: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateEmbryoPgtLab: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusEmbryoPgtLab: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingPGTLabsData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updatePGTLabDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const embryoPgtLabReducer = createSlice({
    name: "embryoPgtStatusdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmbryoPgtLabState(state, action) {
            state.selectedEmbryoPgtLab = INITIAL_STATE.selectedEmbryoPgtLab;
            state.changeStatusEmbryoPgtLab = INITIAL_STATE.changeStatusEmbryoPgtLab;
        },
        ResetEmbryoPgtLabsAllDataState(state, action) {
            state.embryoPgtLabs = INITIAL_STATE.embryoPgtLabs;
        },
        ResetPGTLabInsertUpdateDetails(state, action) {
            state.insertUpdateEmbryoPgtLab = INITIAL_STATE.insertUpdateEmbryoPgtLab;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updatePGTLabDisplayOrderData = INITIAL_STATE.updatePGTLabDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllEmbryoPgtLabs start--------------------

        builder.addCase(getAllEmbryoPgtLabs.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.embryoPgtLabs.data.records = action.payload.data.records;
            state.embryoPgtLabs.apiMsg.status = action.meta.requestStatus;
            state.embryoPgtLabs.apiMsg.message = action.payload.message.message;
            state.embryoPgtLabs.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.embryoPgtLabs.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllEmbryoPgtLabs.pending, (state, action) => {

            state.embryoPgtLabs.data.records = null;
            state.embryoPgtLabs.apiMsg.message = action.meta.requestStatus;;
            state.embryoPgtLabs.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmbryoPgtLabs.rejected, (state, action) => {

            state.embryoPgtLabs.apiMsg.message = action.error.message;
            state.embryoPgtLabs.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllEmbryoPgtLabs Action end--------------------

        //   getClient start

        builder.addCase(getEmbryoPgtLabAction.fulfilled, (state, action) => {
            state.selectedEmbryoPgtLab.data = action.payload.data
            state.selectedEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;
            state.selectedEmbryoPgtLab.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getEmbryoPgtLabAction.pending, (state, action) => {
            state.selectedEmbryoPgtLab.data = null;
            state.selectedEmbryoPgtLab.apiMsg.message = action.meta.requestStatus;;
            state.selectedEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getEmbryoPgtLabAction.rejected, (state, action) => {
            state.selectedEmbryoPgtLab.apiMsg.message = action.error.message;
            state.selectedEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end


        // insertUpdateEmbryoPgtLabAction start

        builder.addCase(
            insertUpdateEmbryoPgtLabAction.fulfilled,
            (state, action) => {
                state.insertUpdateEmbryoPgtLab.data = action.payload.data.data;
                state.insertUpdateEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateEmbryoPgtLab.apiMsg.message = action.payload.message.message;
                state.insertUpdateEmbryoPgtLab.newRecord = !action.meta.arg.pgtLabID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.embryoPgtLabs.data.records;

                    const index = existingRecords.findIndex(record => record.pgtLabID === newData.pgtLabID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.embryoPgtLabs.data.totalRecords += 1;
                    }

                    if (newData.defaultSelected === true) {
                        state.embryoPgtLabs.data.records = state.embryoPgtLabs.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.pgtLabID === newData.pgtLabID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });
            }
        );
        builder.addCase(
            insertUpdateEmbryoPgtLabAction.pending,
            (state, action) => {
                state.insertUpdateEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateEmbryoPgtLabAction.rejected,
            (state, action) => {

                state.insertUpdateEmbryoPgtLab.apiMsg.message = action.error.message;
                state.insertUpdateEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusEmbryoPgtLabAction.fulfilled,
            (state, action) => {

                state.changeStatusEmbryoPgtLab.data = action.payload.data;
                state.changeStatusEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;
                state.changeStatusEmbryoPgtLab.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.embryoPgtLabs.data.records = state.embryoPgtLabs.data.records.filter(record => record.pgtLabID !== deletedId);
                    state.embryoPgtLabs.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.embryoPgtLabs.data.records = state.embryoPgtLabs.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.pgtLabID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.embryoPgtLabs.data.records = state.embryoPgtLabs.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.pgtLabID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.embryoPgtLabs.data.records = state.embryoPgtLabs.data.records.map(record => {
                            return {
                                ...record,
                                active: record.pgtLabID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.embryoPgtLabs.data.records = state.embryoPgtLabs.data.records.map(record => {
                            return {
                                ...record,
                                active: record.pgtLabID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusEmbryoPgtLabAction.pending,
            (state, action) => {
                state.changeStatusEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusEmbryoPgtLabAction.rejected,
            (state, action) => {
                state.changeStatusEmbryoPgtLab.apiMsg.message = action.error.message;
                state.changeStatusEmbryoPgtLab.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updatePGTLabDisplayOrder.fulfilled, (state, action) => {
            state.updatePGTLabDisplayOrderData.data = action.payload.data
            state.updatePGTLabDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updatePGTLabDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updatePGTLabDisplayOrder.pending, (state, action) => {
            state.updatePGTLabDisplayOrderData.data = null;
            state.updatePGTLabDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updatePGTLabDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updatePGTLabDisplayOrder.rejected, (state, action) => {
            state.updatePGTLabDisplayOrderData.apiMsg.message = action.error.message;
            state.updatePGTLabDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingPgtLabs.fulfilled, (state, action) => {
            state.sortingPGTLabsData.data.records = action.payload.data.records;
            state.sortingPGTLabsData.apiMsg.status = action.meta.requestStatus;
            state.sortingPGTLabsData.apiMsg.message = action.payload.message.message;
            state.sortingPGTLabsData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingPgtLabs.pending, (state, action) => {

            state.sortingPGTLabsData.data.records = null;
            state.sortingPGTLabsData.apiMsg.message = action.meta.requestStatus;;
            state.sortingPGTLabsData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingPgtLabs.rejected, (state, action) => {

            state.sortingPGTLabsData.apiMsg.message = action.error.message;
            state.sortingPGTLabsData.apiMsg.status = action.meta.requestStatus;
        });

    },
});

export const { RESET, ResetEmbryoPgtLabState, ResetEmbryoPgtLabsAllDataState, ResetPGTLabInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = embryoPgtLabReducer.actions;
export default embryoPgtLabReducer.reducer;