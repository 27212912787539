import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
// import { getAllEmbryoBXTech, getEmbryoBXTechAction, insertUpdateEmbryoBXTechAction, changeStatusEmbryoBXTechAction, updateBXTechDisplayOrder, getAllSortingBXTech } from "../../actions/embryoSetupAction/pgtResultAction";
import { getAllEmbryoBXTech, getEmbryoBXTechAction, insertUpdateEmbryoBXTechAction, changeStatusEmbryoBXTechAction, updateBXTechDisplayOrder, getAllSortingBXTech } from "../../actions/embryoSetupAction/bxTechAction";
import { Enums } from "../../../helper/constants/enums";


const INITIAL_STATE = {
    embryoBXTech: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedEmbryoBXTech: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateEmbryoBXTech: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusEmbryoBXTech: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingBXTechData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateBXTechDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const embryoBXTechReducer = createSlice({
    name: "embryoBXTechdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmbryoBXTechState(state, action) {
            state.selectedEmbryoBXTech = INITIAL_STATE.selectedEmbryoBXTech;
            state.changeStatusEmbryoBXTech = INITIAL_STATE.changeStatusEmbryoBXTech;
        },
        ResetEmbryoBXTechAllDataState(state, action) {
            state.embryoBXTech = INITIAL_STATE.embryoBXTech;
        },
        ResetBXTechInsertUpdateDetails(state, action) {
            state.insertUpdateEmbryoBXTech = INITIAL_STATE.insertUpdateEmbryoBXTech;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateBXTechDisplayOrderData = INITIAL_STATE.updateBXTechDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllEmbryoBXTech start--------------------

        builder.addCase(getAllEmbryoBXTech.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.embryoBXTech.data.records = action.payload.data.records;
            state.embryoBXTech.apiMsg.status = action.meta.requestStatus;
            state.embryoBXTech.apiMsg.message = action.payload.message.message;
            state.embryoBXTech.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.embryoBXTech.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllEmbryoBXTech.pending, (state, action) => {

            state.embryoBXTech.data.records = null;
            state.embryoBXTech.apiMsg.message = action.meta.requestStatus;;
            state.embryoBXTech.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmbryoBXTech.rejected, (state, action) => {

            state.embryoBXTech.apiMsg.message = action.error.message;
            state.embryoBXTech.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllEmbryoBXTech Action end--------------------

        //   getClient start

        builder.addCase(getEmbryoBXTechAction.fulfilled, (state, action) => {
            state.selectedEmbryoBXTech.data = action.payload.data
            state.selectedEmbryoBXTech.apiMsg.status = action.meta.requestStatus;
            state.selectedEmbryoBXTech.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getEmbryoBXTechAction.pending, (state, action) => {
            state.selectedEmbryoBXTech.data = null;
            state.selectedEmbryoBXTech.apiMsg.message = action.meta.requestStatus;;
            state.selectedEmbryoBXTech.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getEmbryoBXTechAction.rejected, (state, action) => {
            state.selectedEmbryoBXTech.apiMsg.message = action.error.message;
            state.selectedEmbryoBXTech.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateEmbryoBXTechAction start

        builder.addCase(
            insertUpdateEmbryoBXTechAction.fulfilled,
            (state, action) => {
                state.insertUpdateEmbryoBXTech.data = action.payload.data.data;
                state.insertUpdateEmbryoBXTech.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateEmbryoBXTech.apiMsg.message = action.payload.message.message;
                state.insertUpdateEmbryoBXTech.newRecord = !action.meta.arg.bxTechID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.embryoBXTech.data.records;


                    const index = existingRecords.findIndex(record => record.bxTechID === newData.bxTechID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.embryoBXTech.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.embryoBXTech.data.records = state.embryoBXTech.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.bxTechID === newData.bxTechID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateEmbryoBXTechAction.pending,
            (state, action) => {
                state.insertUpdateEmbryoBXTech.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateEmbryoBXTechAction.rejected,
            (state, action) => {

                state.insertUpdateEmbryoBXTech.apiMsg.message = action.error.message;
                state.insertUpdateEmbryoBXTech.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusEmbryoBXTechAction.fulfilled,
            (state, action) => {

                state.changeStatusEmbryoBXTech.data = action.payload.data;
                state.changeStatusEmbryoBXTech.apiMsg.status = action.meta.requestStatus;
                state.changeStatusEmbryoBXTech.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.embryoBXTech.data.records = state.embryoBXTech.data.records.filter(record => record.bxTechID !== deletedId);
                    state.embryoBXTech.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.embryoBXTech.data.records = state.embryoBXTech.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.bxTechID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.embryoBXTech.data.records = state.embryoBXTech.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.bxTechID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.embryoBXTech.data.records = state.embryoBXTech.data.records.map(record => {
                            return {
                                ...record,
                                active: record.bxTechID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.embryoBXTech.data.records = state.embryoBXTech.data.records.map(record => {
                            return {
                                ...record,
                                active: record.bxTechID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusEmbryoBXTechAction.pending,
            (state, action) => {
                state.changeStatusEmbryoBXTech.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusEmbryoBXTechAction.rejected,
            (state, action) => {
                state.changeStatusEmbryoBXTech.apiMsg.message = action.error.message;
                state.changeStatusEmbryoBXTech.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateBXTechDisplayOrder.fulfilled, (state, action) => {
            state.updateBXTechDisplayOrderData.data = action.payload.data
            state.updateBXTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateBXTechDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateBXTechDisplayOrder.pending, (state, action) => {
            state.updateBXTechDisplayOrderData.data = null;
            state.updateBXTechDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateBXTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateBXTechDisplayOrder.rejected, (state, action) => {
            state.updateBXTechDisplayOrderData.apiMsg.message = action.error.message;
            state.updateBXTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingBXTech.fulfilled, (state, action) => {
            state.sortingBXTechData.data.records = action.payload.data.records;
            state.sortingBXTechData.apiMsg.status = action.meta.requestStatus;
            state.sortingBXTechData.apiMsg.message = action.payload.message.message;
            state.sortingBXTechData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingBXTech.pending, (state, action) => {

            state.sortingBXTechData.data.records = null;
            state.sortingBXTechData.apiMsg.message = action.meta.requestStatus;;
            state.sortingBXTechData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingBXTech.rejected, (state, action) => {

            state.sortingBXTechData.apiMsg.message = action.error.message;
            state.sortingBXTechData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetEmbryoBXTechState, ResetEmbryoBXTechAllDataState, ResetBXTechInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = embryoBXTechReducer.actions;
export default embryoBXTechReducer.reducer;