import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { Menus } from '../../helper/constants/enums';
import { menuList } from '../../helper/constants/menus';
import { allowViewMenu } from '../../helper/functions/general-func';
function SideMenu() {

  const location = useLocation();
  const navigate = useNavigate();
  const [displaySideBar, setDisplaySidebar] = useState();

  useLayoutEffect(() => {
    if (window.innerWidth > 991) {
      setDisplaySidebar(true);
      toggleSidebar();
    }
    else {
      setDisplaySidebar(false);
    }

  }, []);


  useEffect(() => {



    var sidemenuToggleBarHeadEle = document.getElementById("dc-sidemenu-togglebar-head");

    sidemenuToggleBarHeadEle.addEventListener("click", function () {
      toggleSidebar();
    });

    var menuItems = document.getElementsByClassName("sidebar-menu-item");
    for (var i = 0; i < menuItems.length; i++) {
      menuItems[i].addEventListener('click', handleDisplaySidebarOnMenuItemClick, false);
    }

    var subMenuItems = document.getElementsByClassName("sidebar-submenu-item");
    for (var j = 0; j < subMenuItems.length; j++) {
      subMenuItems[j].addEventListener('click', handleDisplaySidebarOnMenuItemClick, false);
    }


  }, [])


  // useEffect(() => {
  //   const menuLinks = document.querySelectorAll('.dc-submenu-link');
  //   menuLinks.forEach(link => {
  //     const parentDiv = link.closest('.dc-has-menu');
  //     // console.log(parentDiv,'parentdiv');
  //     const liTag = parentDiv && parentDiv.closest('.dc-sidemenu-li');
  //     // console.log(liTag,"litag");
  //     const anchorTag = liTag && liTag.querySelector('.dc-has-submenu');
  //     // console.log(anchorTag,'anchortag');
  //     if (link.getAttribute('href') === window.location.pathname) {
  //       if (parentDiv && liTag && anchorTag) {
  //         parentDiv.setAttribute('aria-expanded', 'true');
  //         parentDiv.classList.add('show');
  //         anchorTag.setAttribute('aria-expanded', 'true');
  //       }
  //     }
  //   });

  // }, [window.location.pathname]);


  useEffect(() => {
    const activeLink = document.querySelector(".dc-submenu-link.active .menu-title");
    if (activeLink) {
      const pageTitle = activeLink.textContent;
      // console.log(pageTitle);
      document.title = pageTitle;
    }

    const locationPathInfo = location.pathname.split("/");
    let parentMenuId = null;
    let childMenuId = null;
    let ActiveMenuId = null;
    if (locationPathInfo.length) {
      parentMenuId = locationPathInfo[1].toLowerCase();
    }
    if (locationPathInfo.length > 2) {
      childMenuId = locationPathInfo[2].toLowerCase();
    }

    ActiveMenuId = childMenuId ? childMenuId : parentMenuId ? parentMenuId : null;
    if (!allowViewMenu(location.pathname)) {
      navigate("/accessdenied");
    }

    const menuLinks = document.querySelectorAll('.dc-sidemenu-li');
    const activeElementLink = document.getElementById(`${parentMenuId}Link`);
      const activeElementMenu = document.getElementById(`${parentMenuId}`);   
      // activeElementLink.setAttribute("aria-expanded", "true");
      // activeElementMenu.classList.add("show");

    menuLinks.forEach(link => {
      const hasmenu = link.querySelector('.dc-has-submenu');
      const hasSubmenu = link.querySelector('.dc-has-menu');
      const activeSubmenuLink = hasSubmenu.querySelector('.dc-submenu-link.active');
      if(hasSubmenu && activeElementLink && activeElementMenu && activeSubmenuLink){
        hasmenu.setAttribute("aria-expanded", "true");
        hasSubmenu.classList.add("show");
      }
      else{
        hasmenu.setAttribute("aria-expanded", "false");
        hasSubmenu.classList.remove("show");
      }

    });


  }, [location.pathname])

  function toggleSidebar() {
    var sidemenuToggleBarEle = document.getElementsByClassName("dc-sidemenu-togglebar")[0];
    sidemenuToggleBarEle.click();
  }

  const isActive = (paths) => {
    return paths.some(path => location.pathname.includes(path)) ? 'active' : '';
  };

  function handleDisplaySidebarOnMenuItemClick() {
    if (!this.classList.contains("dc-has-submenu") && window.innerWidth < 992) {
      toggleSidebar();
    }
  }


  return (

    <div className={`sidebar-menu offcanvas offcanvas-start ${displaySideBar ? "show" : ""}`} tabIndex="-1" id="sidebarOffcanvas"
      aria-labelledby="sidebarOffcanvasLabel" data-bs-scroll="true" data-bs-backdrop="false">
      <div>
        <div className="d-flex align-items-center lef-logo-part sw-navbar-h">
          <div className="admin-logo">
            <span className="admin-logo-name">Encompasscryo</span>
            <i className="fa-solid fa-bars cursor-poiner ms-1 dc-sidemenu-togglebar" type="button"
              data-bs-toggle="offcanvas" data-bs-target="#sidebarOffcanvas" aria-controls="sidebarOffcanvas"></i>
          </div>

        </div>
        <hr className="m-0" />
        <ul className="list-unstyled ps-0">
          {menuList && menuList.length ? (
            menuList.map((menu, i) => {
              if (menu.HasSubMenu === false && menu.ParentId === null) {
                return (
                  allowViewMenu(Menus[menu.MenuId].route) ?
                    <li key={"menu-main-list" + i}>
                      <Link
                        to={Menus[menu.MenuId].route}
                        type="button"
                        className={`nav-link sidebar-menu-item dc-submenu-link ${isActive([Menus[menu.MenuId].route])}`}
                      >
                        <i className={menu.icon}></i>
                        <span className="menu-title">{menu.Name}</span>
                      </Link>
                    </li>
                    : null
                );
              }

              if (menu.HasSubMenu === true && menu.ParentId === null) {
                const hasVisibleSubMenu = menu.SubMenu.some((subMenu) =>
                  allowViewMenu(`/${menu.MenuId.toLowerCase()}/${subMenu.MenuId.toLowerCase()}`)
                );

                if (!hasVisibleSubMenu) {
                  return null;
                }
                return (
                  <li className="nav-item dc-sidemenu-li" key={"menu-main-list" + i}>
                    <a
                      className="nav-link sidebar-menu-item collapsed dc-has-submenu"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${menu.MenuId.toLowerCase()}`}
                      aria-expanded="false"
                      aria-controls={`${menu.MenuId.toLowerCase()}`}
                      id={`${menu.MenuId.toLowerCase()}Link`}
                    >
                      <i className={menu.icon}></i>
                      <span className="menu-title">{menu.Name}</span>
                    </a>
                    <div className="collapse sidebar-submenus dc-has-menu" id={`${menu.MenuId.toLowerCase()}`}>
                      <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        {menu.SubMenu.map((subMenu, j) => (
                          allowViewMenu(`/${menu.MenuId.toLowerCase()}/${subMenu.MenuId.toLowerCase()}`) ?
                            <li key={"sub-menu-list" + j}>
                              <Link
                                to={`/${menu.MenuId.toLowerCase()}/${subMenu.MenuId.toLowerCase()}`}
                                type="button"
                                className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive([`/${menu.MenuId.toLowerCase()}/${subMenu.MenuId.toLowerCase()}`])}`}
                              >
                                <i className={subMenu.icon}></i>
                                <span className="menu-title">{subMenu.Name}</span>
                              </Link>
                            </li> : null
                        ))}
                      </ul>
                    </div>
                  </li>
                );
              }

              return null;
            })
          ) : null}

        </ul>
      </div>
    </div>

  )
}

export default SideMenu