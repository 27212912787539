import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingsCane, getAllSortingSettingsCane, insertUpdateSettingsCaneAction, changeStatusSettingsCaneAction, getSettingsCaneAction, getAllCopySettingsCane } from "../../actions/settingsAction/caneAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    settingsCane: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingsCane: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingsCane: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingsCane: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingsCaneData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    copySettingsCane: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const settingsCaneReducer = createSlice({
    name: "settingsCanedata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingsCaneState(state, action) {
            state.selectedSettingsCane = INITIAL_STATE.selectedSettingsCane;
            state.changeStatusSettingsCane = INITIAL_STATE.changeStatusSettingsCane;
        },
        ResetSettingsCaneAllDataState(state, action) {
            state.settingsCane = INITIAL_STATE.settingsCane;
        },
        ResetSettingsCaneInsertUpdateDetails(state, action) {
            state.insertUpdateSettingsCane = INITIAL_STATE.insertUpdateSettingsCane;
        },
        ResetSettingsCopyCaneState(state, action){
            state.copySettingsCane =INITIAL_STATE.copySettingsCane;
        }
    },
    extraReducers: (builder) => {

        //  --------getAllSettingsCane start--------------------

        builder.addCase(getAllSettingsCane.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingsCane.data.records = action.payload.data.records;
            state.settingsCane.apiMsg.status = action.meta.requestStatus;
            state.settingsCane.apiMsg.message = action.payload.message.message;
            state.settingsCane.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsCane.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsCane.pending, (state, action) => {

            state.settingsCane.data.records = null;
            state.settingsCane.apiMsg.message = action.meta.requestStatus;;
            state.settingsCane.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsCane.rejected, (state, action) => {

            state.settingsCane.apiMsg.message = action.error.message;
            state.settingsCane.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingsCane Action end--------------------

        //   getClient start

        builder.addCase(getSettingsCaneAction.fulfilled, (state, action) => {
            state.selectedSettingsCane.data = action.payload.data
            state.selectedSettingsCane.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingsCane.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsCaneAction.pending, (state, action) => {
            state.selectedSettingsCane.data = null;
            state.selectedSettingsCane.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingsCane.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsCaneAction.rejected, (state, action) => {
            state.selectedSettingsCane.apiMsg.message = action.error.message;
            state.selectedSettingsCane.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsCaneAction start

        builder.addCase(
            insertUpdateSettingsCaneAction.fulfilled,
            (state, action) => {                
                state.insertUpdateSettingsCane.data = action.payload.data.data;
                state.insertUpdateSettingsCane.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingsCane.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingsCane.newRecord = !action?.meta?.arg[0]?.caneID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingsCane.data.records;


                    const index = existingRecords.findIndex(record => record.caneID === newData[0]?.caneID);
                    // console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData[0]?.name,
                            caneID : newData[0]?.caneID,
                            centerID : newData[0]?.centerID,
                            locationID: newData[0]?.locationID,
                            active: newData[0]?.active
                        };
                    }
                    else {
                        state.settingsCane.data.totalRecords += newData?.length;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsCaneAction.pending,
            (state, action) => {
                state.insertUpdateSettingsCane.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsCaneAction.rejected,
            (state, action) => {
                // console.log(action);
                state.insertUpdateSettingsCane.data = action.payload.data;
                state.insertUpdateSettingsCane.apiMsg.message = action.payload.errors[0].message;
                state.insertUpdateSettingsCane.apiMsg.status = action.meta.requestStatus;

                toast.error( action.payload.errors[0].message ?  action.payload.errors[0].message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsCaneAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingsCane.data = action.payload.data;
                state.changeStatusSettingsCane.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingsCane.apiMsg.message = action.payload.message.message;
                
                if (action.meta.arg && action.meta.arg.StatusType === Enums.Cane.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.CaneID;
                    state.settingsCane.data.records = state.settingsCane.data.records.filter(record => record.caneID !== deletedId);
                    state.settingsCane.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Cane.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Cane.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Cane.ChangeStatus.Active) {
                        state.settingsCane.data.records = state.settingsCane.data.records.map(record => {
                            return {
                                ...record,
                                active: record.caneID === action.meta.arg.CaneID ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Cane.ChangeStatus.Inactive) {
                        state.settingsCane.data.records = state.settingsCane.data.records.map(record => {
                            return {
                                ...record,
                                active: record.caneID === action.meta.arg.CaneID ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsCaneAction.pending,
            (state, action) => {
                state.changeStatusSettingsCane.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsCaneAction.rejected,
            (state, action) => {
                state.changeStatusSettingsCane.apiMsg.message = action.error.message;
                state.changeStatusSettingsCane.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

// sorting cane started

        builder.addCase(getAllSortingSettingsCane.fulfilled, (state, action) => {
            state.sortingSettingsCaneData.data.records = action.payload.data.records;
            state.sortingSettingsCaneData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingsCaneData.apiMsg.message = action.payload.message.message;
            state.sortingSettingsCaneData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsCane.pending, (state, action) => {

            state.sortingSettingsCaneData.data.records = null;
            state.sortingSettingsCaneData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingsCaneData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsCane.rejected, (state, action) => {

            state.sortingSettingsCaneData.apiMsg.message = action.error.message;
            state.sortingSettingsCaneData.apiMsg.status = action.meta.requestStatus;
        });


        // copy settings cane started

        builder.addCase(getAllCopySettingsCane.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.copySettingsCane.data.records = action.payload.data.records;
            state.copySettingsCane.apiMsg.status = action.meta.requestStatus;
            state.copySettingsCane.apiMsg.message = action.payload.message.message;
            state.copySettingsCane.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.copySettingsCane.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllCopySettingsCane.pending, (state, action) => {

            state.copySettingsCane.data.records = null;
            state.copySettingsCane.apiMsg.message = action.meta.requestStatus;;
            state.copySettingsCane.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCopySettingsCane.rejected, (state, action) => {

            state.copySettingsCane.apiMsg.message = action.error.message;
            state.copySettingsCane.apiMsg.status = action.meta.requestStatus;
        });


    },
});
export const { RESET, ResetSettingsCaneState, ResetSettingsCaneAllDataState, ResetSettingsCaneInsertUpdateDetails, ResetSettingsCopyCaneState } = settingsCaneReducer.actions;
export default settingsCaneReducer.reducer;