import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { logoutACTION } from '../../redux-store/actions/authAction/index';
import { StateCompleted } from '../../helper/functions/general-func';
import { getLocalStore } from '../../LocalStorage';
import { UserType } from '../../helper/constants/enums';

function Header() {
  const stateAuth = useSelector((state) => state.auth);

  const [displayName, setDisplayName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [centerName, setCenterName] = useState("");


  useEffect(() => {
    if (stateAuth.isLoggedIn === true) {
      let displayName = ""

      if (stateAuth.user.user.userType === UserType.Clients) {
        displayName = `${stateAuth.user.user.firstName}`
      }
      else if (stateAuth.user.user.userType === UserType.LocationEmployee) {
        displayName = `${stateAuth.user.user.lastName} ${stateAuth.user.user.firstName}`
      }
      else if (stateAuth.user.user.userType === UserType.MasterAdmin) {
        displayName = `${stateAuth.user.user.lastName} ${stateAuth.user.user.firstName}`
      }
      setDisplayName(`${displayName}`)
      setCenterName(`${getLocalStore(
        process.env.REACT_APP_LOCAL_STORAGE_KEY_USER
      )?.user.centerName}`)
      setLocationName(`${getLocalStore(
        process.env.REACT_APP_LOCAL_STORAGE_KEY_USER
      )?.user.locationName}`);
    }
  }, [stateAuth])

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const LogoutUser = () => {
    dispatch(logoutACTION())
      .then((d) => {
        navigate('/auth/login');
      })
      .catch(() => {

      });
  }

  const CenterLocationValue = () => {
    return (
      <>
        <div>
          Center : <span className='fw-bold'> {centerName ? centerName : null}</span>
        </div>
        <div>
          Location : <span className='fw-bold'> {locationName ? locationName : null}</span>
        </div>
      </>
    )
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg nav-header border-bottom border-body sw-navbar-h" data-bs-theme="dark">
        <div className="container-fluid">
          <a className="navbar-brand d-lg-none mobile-logo-name" type="button" id="header-sidemenu-toggle">Encompasscryo
            <i className="fa-solid fa-bars cursor-poiner ms-1" type="button" id='dc-sidemenu-togglebar-head'></i>
          </a>
          {stateAuth.user.user.userType === UserType.LocationEmployee ?
            <div className='text-white ms-auto mb-lg-0 me-4 d-none d-sm-block'>
              <CenterLocationValue />
            </div> : null

          }


          <ul className={`navbar-nav mb-lg-0 ${stateAuth.user.user.userType !== UserType.LocationEmployee ? "ms-auto" : ""}`}>

            <li className="nav-item dropdown nav-acc-dropdown">
              <a className="nav-link dropdown-toggle p-0" type='button' role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <div className="nav-profile-img float-start pe-2">
                  <i className="fa-solid fa-user-tie profile-icon"></i>
                  {/* <img src={FaceImg} alt="image" className="profile-img"/> */}
                </div>
                <span className="float-start">
                  {displayName ?
                    (displayName.length <= 10 ? displayName : `${displayName.substring(0, 10)}...`)
                    : "John Carter"}
                </span>


              </a>
              <ul className="dropdown-menu nav-acc-dropdown-menu">
                {/* <li><a type="button" className="dropdown-item">Profile</a></li>
              <li><a type="button" className="dropdown-item" >Settings</a></li>
              <li><a type="button" className="dropdown-item">Change Password</a></li> */}
                <li>
                  <hr className="dropdown-divider mb-0 mt-0" />
                </li>
                <li><Link className="dropdown-item" onClick={() => LogoutUser()}><i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Sign
                  Out</Link></li>
              </ul>
            </li>
          </ul>

        </div>
      </nav>
      <div className='d-block d-sm-none'>
        <div className='center-location-section'>
          <CenterLocationValue />
        </div>
      </div>
    </>
  )
}

export default Header