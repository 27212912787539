import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import Header from "./shared/Header";
import SideMenu from "./shared/SideMenu";
import { faL } from "@fortawesome/free-solid-svg-icons";
function Layout() {
   

    return (


        <div className='layout-wrapper d-flex h-100'>
            <SideMenu />
            <div className="right-content">
                <Header />
                <div className="page-content">
                    <Outlet />
                </div>
            </div>
        </div>


    )
}

export default Layout