import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../API/api-urls";
import { apiCall } from "../../API/index";
import { Enums } from "../../helper/constants/enums";

export const getAllPatients = createAsyncThunk(
    "Patients/getAllPatients",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                CenterID: data.centerid ? data.centerid : null,
                LocationID: data.locationid ? data.locationid : null,
                UserID : data.userID ? data.userID : null,
                Mrn: data.mrn ? data.mrn : null,
                Patient : data.patient ? data.patient : null,
                Provider : data.provider ? data.provider : null,
                Cell :  data.cell ? data.cell : null,
                Email: data.email ? data.email : null,
                FromDOB : data.fromdob ? data.fromdob : null,
                ToDOB : data.todob ? data.todob : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null

            }
        };

        return apiCall(`${apiUrls.patients.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getPatientsAction = createAsyncThunk(
    "Patients/getPatients",
    async (data) => {
        let d = {
            PatientID: data.Id ? data.Id : 0,
            CenterID: data.centerID ? data.centerID : 0,
            LocationID: data.locationID ? data.locationID : 0,
            ParentPatientID : 0,
        };
        return apiCall(`${apiUrls.patients.getPatients}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdatePatientsAction = createAsyncThunk(
    "Patients/patientsInsertUpdate",
    async (data) => {
        return apiCall(`${apiUrls.patients.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.patientID ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusPatientsAction = createAsyncThunk(
    "Patients/changeStatusPatients",
    async (data) => {
        // let d = {
        //     Device: 1,
        //     Id: data.patientID,
        //     Statustype: 3
        //   };
        return apiCall(`${apiUrls.patients.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingPatients = createAsyncThunk(
    "Patients/getAllSortingPatients",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.patients.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);