import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingsAdminUsers, getAllSortingSettingsAdminUsers, insertUpdateSettingsAdminUsersAction, changeStatusSettingsAdminUsersAction, getSettingsAdminUsersAction } from "../../actions/settingsAction/adminUsersAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    settingsAdminUsers: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingsAdminUsers: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingsAdminUsers: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingsAdminUsers: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingsAdminUsersData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const settingsAdminUsersReducer = createSlice({
    name: "settingsAdminUsersdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingsAdminUsersState(state, action) {
            state.selectedSettingsAdminUsers = INITIAL_STATE.selectedSettingsAdminUsers;
            state.changeStatusSettingsAdminUsers = INITIAL_STATE.changeStatusSettingsAdminUsers;
        },
        ResetSettingsAdminUsersAllDataState(state, action) {
            state.settingsAdminUsers = INITIAL_STATE.settingsAdminUsers;
        },
        ResetSettingsAdminUsersInsertUpdateDetails(state, action) {
            state.insertUpdateSettingsAdminUsers = INITIAL_STATE.insertUpdateSettingsAdminUsers;
        }
    },
    extraReducers: (builder) => {

        //  --------getAllSettingsAdminUsers start--------------------

        builder.addCase(getAllSettingsAdminUsers.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingsAdminUsers.data.records = action.payload.data.records;
            state.settingsAdminUsers.apiMsg.status = action.meta.requestStatus;
            state.settingsAdminUsers.apiMsg.message = action.payload.message.message;
            state.settingsAdminUsers.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsAdminUsers.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsAdminUsers.pending, (state, action) => {

            state.settingsAdminUsers.data.records = null;
            state.settingsAdminUsers.apiMsg.message = action.meta.requestStatus;;
            state.settingsAdminUsers.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsAdminUsers.rejected, (state, action) => {

            state.settingsAdminUsers.apiMsg.message = action.error.message;
            state.settingsAdminUsers.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingsAdminUsers Action end--------------------

        //   getClient start

        builder.addCase(getSettingsAdminUsersAction.fulfilled, (state, action) => {
            state.selectedSettingsAdminUsers.data = action.payload.data
            state.selectedSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingsAdminUsers.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsAdminUsersAction.pending, (state, action) => {
            state.selectedSettingsAdminUsers.data = null;
            state.selectedSettingsAdminUsers.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsAdminUsersAction.rejected, (state, action) => {
            state.selectedSettingsAdminUsers.apiMsg.message = action.error.message;
            state.selectedSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsAdminUsersAction start

        builder.addCase(
            insertUpdateSettingsAdminUsersAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingsAdminUsers.data = action.payload.data.data;
                state.insertUpdateSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingsAdminUsers.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingsAdminUsers.newRecord = !action.meta.arg.userID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingsAdminUsers.data.records;


                    const index = existingRecords.findIndex(record => record.userID === newData.userID);
                    console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            firstName: newData.firstName,
                            lastName: newData.lastName,
                            userName: newData.userName,
                            emailID: newData.emailID,
                            active: newData.active
                        };
                    }
                    else {
                        state.settingsAdminUsers.data.totalRecords += 1;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsAdminUsersAction.pending,
            (state, action) => {
                state.insertUpdateSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsAdminUsersAction.rejected,
            (state, action) => {

                state.insertUpdateSettingsAdminUsers.apiMsg.message = action.error.message;
                state.insertUpdateSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsAdminUsersAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingsAdminUsers.data = action.payload.data;
                state.changeStatusSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingsAdminUsers.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.AdminUsers.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.settingsAdminUsers.data.records = state.settingsAdminUsers.data.records.filter(record => record.userID !== deletedId);
                    state.settingsAdminUsers.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.AdminUsers.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.AdminUsers.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.AdminUsers.ChangeStatus.Active) {
                        state.settingsAdminUsers.data.records = state.settingsAdminUsers.data.records.map(record => {
                            return {
                                ...record,
                                active: record.userID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.AdminUsers.ChangeStatus.Inactive) {
                        state.settingsAdminUsers.data.records = state.settingsAdminUsers.data.records.map(record => {
                            return {
                                ...record,
                                active: record.userID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsAdminUsersAction.pending,
            (state, action) => {
                state.changeStatusSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsAdminUsersAction.rejected,
            (state, action) => {
                state.changeStatusSettingsAdminUsers.apiMsg.message = action.error.message;
                state.changeStatusSettingsAdminUsers.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(getAllSortingSettingsAdminUsers.fulfilled, (state, action) => {
            state.sortingSettingsAdminUsersData.data.records = action.payload.data.records;
            state.sortingSettingsAdminUsersData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingsAdminUsersData.apiMsg.message = action.payload.message.message;
            state.sortingSettingsAdminUsersData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsAdminUsers.pending, (state, action) => {

            state.sortingSettingsAdminUsersData.data.records = null;
            state.sortingSettingsAdminUsersData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingsAdminUsersData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsAdminUsers.rejected, (state, action) => {

            state.sortingSettingsAdminUsersData.apiMsg.message = action.error.message;
            state.sortingSettingsAdminUsersData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetSettingsAdminUsersState, ResetSettingsAdminUsersAllDataState, ResetSettingsAdminUsersInsertUpdateDetails } = settingsAdminUsersReducer.actions;
export default settingsAdminUsersReducer.reducer;