import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getSettingsRolesAction, getAllSettingsRoles, getAllSortingSettingsRoles, insertUpdateSettingsRolesAction, changeStatusSettingsRolesAction } from "../../actions/settingsAction/rolesAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    settingsRoles: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingsRoles: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingsRoles: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingsRoles: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingsRoles: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const settingsRolesReducer = createSlice({
    name: "settingsRolesdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingsRolesState(state, action) {
            state.selectedSettingsRoles = INITIAL_STATE.selectedSettingsRoles;
            state.changeStatusSettingsRoles = INITIAL_STATE.changeStatusSettingsRoles;
        },
        ResetSettingsRolesAllDataState(state, action) {
            state.settingsRoles = INITIAL_STATE.settingsRoles;
        },
        ResetSettingsRolesInsertUpdateDetails(state, action) {
            state.insertUpdateSettingsRoles = INITIAL_STATE.insertUpdateSettingsRoles;
        }
    },
    extraReducers: (builder) => {

        //  --------getAllSettingsRoles start--------------------

        builder.addCase(getAllSettingsRoles.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingsRoles.data.records = action.payload.data.records;
            state.settingsRoles.apiMsg.status = action.meta.requestStatus;
            state.settingsRoles.apiMsg.message = action.payload.message.message;
            state.settingsRoles.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsRoles.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsRoles.pending, (state, action) => {

            state.settingsRoles.data.records = null;
            state.settingsRoles.apiMsg.message = action.meta.requestStatus;;
            state.settingsRoles.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsRoles.rejected, (state, action) => {

            state.settingsRoles.apiMsg.message = action.error.message;
            state.settingsRoles.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingsRoles Action end--------------------

        //   getClient start

        builder.addCase(getSettingsRolesAction.fulfilled, (state, action) => {
            state.selectedSettingsRoles.data = action.payload.data;
            state.selectedSettingsRoles.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingsRoles.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsRolesAction.pending, (state, action) => {
            state.selectedSettingsRoles.data = null;
            state.selectedSettingsRoles.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingsRoles.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsRolesAction.rejected, (state, action) => {
            state.selectedSettingsRoles.apiMsg.message = action.error.message;
            state.selectedSettingsRoles.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsRolesAction start

        builder.addCase(
            insertUpdateSettingsRolesAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingsRoles.data = action.payload.data.data;
                state.insertUpdateSettingsRoles.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingsRoles.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingsRoles.newRecord = !action.meta.arg.roleID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingsRoles.data.records;


                    const index = existingRecords.findIndex(record => record.roleID === newData.roleID);
                    // console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            roleName: newData.roleName,
                            centerName: newData.centerName,
                            active: newData.active,
                            roles: newData.roles,
                        };
                    }
                    else {
                        state.settingsRoles.data.totalRecords += 1;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsRolesAction.pending,
            (state, action) => {
                state.insertUpdateSettingsRoles.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsRolesAction.rejected,
            (state, action) => {

                state.insertUpdateSettingsRoles.apiMsg.message = action.error.message;
                state.insertUpdateSettingsRoles.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsRolesAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingsRoles.data = action.payload.data;
                state.changeStatusSettingsRoles.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingsRoles.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Roles.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.settingsRoles.data.records = state.settingsRoles.data.records.filter(record => record.roleID !== deletedId);
                    state.settingsRoles.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Roles.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Roles.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Roles.ChangeStatus.Active) {
                        state.settingsRoles.data.records = state.settingsRoles.data.records.map(record => {
                            return {
                                ...record,
                                active: record.roleID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Roles.ChangeStatus.Inactive) {
                        state.settingsRoles.data.records = state.settingsRoles.data.records.map(record => {
                            return {
                                ...record,
                                active: record.roleID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            changeStatusSettingsRolesAction.pending,
            (state, action) => {
                state.changeStatusSettingsRoles.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsRolesAction.rejected,
            (state, action) => {
                state.changeStatusSettingsRoles.apiMsg.message = action.error.message;
                state.changeStatusSettingsRoles.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        //------------------------------- changeStatusAction end -------------------------



        builder.addCase(getAllSortingSettingsRoles.fulfilled, (state, action) => {
            state.sortingSettingsRoles.data.records = action.payload.data.records;
            state.sortingSettingsRoles.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingsRoles.apiMsg.message = action.payload.message.message;
            state.sortingSettingsRoles.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsRoles.pending, (state, action) => {

            state.sortingSettingsRoles.data.records = null;
            state.sortingSettingsRoles.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingsRoles.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsRoles.rejected, (state, action) => {

            state.sortingSettingsRoles.apiMsg.message = action.error.message;
            state.sortingSettingsRoles.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetSettingsRolesState, ResetSettingsRolesAllDataState, ResetSettingsRolesInsertUpdateDetails } = settingsRolesReducer.actions;
export default settingsRolesReducer.reducer;