import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllOocyteCaneLabel, getOocyteCaneLabelAction, insertUpdateOocyteCaneLabelAction, changeStatusOocyteCaneLabelAction, updateOocyteCaneLabelDisplayOrder, getAllSortingOocyteCaneLabel } from "../../actions/oocyteSetupAction/caneLabelAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    oocyteCaneLabel: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedOocyteCaneLabel: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateOocyteCaneLabel: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusOocyteCaneLabel: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingOocyteCaneLabelData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateOocyteCaneLabelDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const oocyteCaneLabelReducer = createSlice({
    name: "oocyteCaneLabeldata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetOocyteCaneLabelState(state, action) {
            state.selectedOocyteCaneLabel = INITIAL_STATE.selectedOocyteCaneLabel;
            state.changeStatusOocyteCaneLabel = INITIAL_STATE.changeStatusOocyteCaneLabel;
        },
        ResetOocyteCaneLabelAllDataState(state, action) {
            state.oocyteCaneLabel = INITIAL_STATE.oocyteCaneLabel;
        },
        ResetOocyteCaneLabelInsertUpdateDetails(state, action) {
            state.insertUpdateOocyteCaneLabel = INITIAL_STATE.insertUpdateOocyteCaneLabel;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateOocyteCaneLabelDisplayOrderData = INITIAL_STATE.updateOocyteCaneLabelDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllOocyteCaneLabel start--------------------

        builder.addCase(getAllOocyteCaneLabel.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.oocyteCaneLabel.data.records = action.payload.data.records;
            state.oocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
            state.oocyteCaneLabel.apiMsg.message = action.payload.message.message;
            state.oocyteCaneLabel.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.oocyteCaneLabel.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllOocyteCaneLabel.pending, (state, action) => {

            state.oocyteCaneLabel.data.records = null;
            state.oocyteCaneLabel.apiMsg.message = action.meta.requestStatus;;
            state.oocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllOocyteCaneLabel.rejected, (state, action) => {

            state.oocyteCaneLabel.apiMsg.message = action.error.message;
            state.oocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllOocyteCaneLabel Action end--------------------

        //   getClient start

        builder.addCase(getOocyteCaneLabelAction.fulfilled, (state, action) => {
            state.selectedOocyteCaneLabel.data = action.payload.data
            state.selectedOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
            state.selectedOocyteCaneLabel.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getOocyteCaneLabelAction.pending, (state, action) => {
            state.selectedOocyteCaneLabel.data = null;
            state.selectedOocyteCaneLabel.apiMsg.message = action.meta.requestStatus;;
            state.selectedOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getOocyteCaneLabelAction.rejected, (state, action) => {
            state.selectedOocyteCaneLabel.apiMsg.message = action.error.message;
            state.selectedOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateOocyteCaneLabelAction start

        builder.addCase(
            insertUpdateOocyteCaneLabelAction.fulfilled,
            (state, action) => {
                state.insertUpdateOocyteCaneLabel.data = action.payload.data.data;
                state.insertUpdateOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateOocyteCaneLabel.apiMsg.message = action.payload.message.message;
                state.insertUpdateOocyteCaneLabel.newRecord = !action.meta.arg.caneLabelID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.oocyteCaneLabel.data.records;


                    const index = existingRecords.findIndex(record => record.caneLabelID === newData.caneLabelID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.oocyteCaneLabel.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.oocyteCaneLabel.data.records = state.oocyteCaneLabel.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.caneLabelID === newData.caneLabelID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateOocyteCaneLabelAction.pending,
            (state, action) => {
                state.insertUpdateOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateOocyteCaneLabelAction.rejected,
            (state, action) => {

                state.insertUpdateOocyteCaneLabel.apiMsg.message = action.error.message;
                state.insertUpdateOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusOocyteCaneLabelAction.fulfilled,
            (state, action) => {

                state.changeStatusOocyteCaneLabel.data = action.payload.data;
                state.changeStatusOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
                state.changeStatusOocyteCaneLabel.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.oocyteCaneLabel.data.records = state.oocyteCaneLabel.data.records.filter(record => record.caneLabelID !== deletedId);
                    state.oocyteCaneLabel.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.oocyteCaneLabel.data.records = state.oocyteCaneLabel.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.caneLabelID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.oocyteCaneLabel.data.records = state.oocyteCaneLabel.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.caneLabelID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.oocyteCaneLabel.data.records = state.oocyteCaneLabel.data.records.map(record => {
                            return {
                                ...record,
                                active: record.caneLabelID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.oocyteCaneLabel.data.records = state.oocyteCaneLabel.data.records.map(record => {
                            return {
                                ...record,
                                active: record.caneLabelID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusOocyteCaneLabelAction.pending,
            (state, action) => {
                state.changeStatusOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusOocyteCaneLabelAction.rejected,
            (state, action) => {
                state.changeStatusOocyteCaneLabel.apiMsg.message = action.error.message;
                state.changeStatusOocyteCaneLabel.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateOocyteCaneLabelDisplayOrder.fulfilled, (state, action) => {
            state.updateOocyteCaneLabelDisplayOrderData.data = action.payload.data
            state.updateOocyteCaneLabelDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateOocyteCaneLabelDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateOocyteCaneLabelDisplayOrder.pending, (state, action) => {
            state.updateOocyteCaneLabelDisplayOrderData.data = null;
            state.updateOocyteCaneLabelDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateOocyteCaneLabelDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateOocyteCaneLabelDisplayOrder.rejected, (state, action) => {
            state.updateOocyteCaneLabelDisplayOrderData.apiMsg.message = action.error.message;
            state.updateOocyteCaneLabelDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingOocyteCaneLabel.fulfilled, (state, action) => {
            state.sortingOocyteCaneLabelData.data.records = action.payload.data.records;
            state.sortingOocyteCaneLabelData.apiMsg.status = action.meta.requestStatus;
            state.sortingOocyteCaneLabelData.apiMsg.message = action.payload.message.message;
            state.sortingOocyteCaneLabelData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingOocyteCaneLabel.pending, (state, action) => {

            state.sortingOocyteCaneLabelData.data.records = null;
            state.sortingOocyteCaneLabelData.apiMsg.message = action.meta.requestStatus;;
            state.sortingOocyteCaneLabelData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingOocyteCaneLabel.rejected, (state, action) => {

            state.sortingOocyteCaneLabelData.apiMsg.message = action.error.message;
            state.sortingOocyteCaneLabelData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetOocyteCaneLabelState, ResetOocyteCaneLabelAllDataState, ResetOocyteCaneLabelInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = oocyteCaneLabelReducer.actions;
export default oocyteCaneLabelReducer.reducer;