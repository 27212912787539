import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../../API/api-urls";
import { apiCall } from "../../../API/index";
import { Enums } from "../../../helper/constants/enums";

export const getAllSettingsEmployees = createAsyncThunk(
    "SettingsEmployees/getAllSettingsEmployees",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                // ClientID: null,
                CenterID: data.centerid ? data.centerid : null,
                LocationID: data.locationid ? data.locationid : null,
                EmpCode: data.employeecode ? data.employeecode : null,
                Name: data.name ? data.name : null,
                UserName: data.username ? data.username : null,
                JobTitleID: data.jobtitleid ? data.jobtitleid : null,
                jobTitle: data.jobTitle ? data.jobTitle : null,
                FromDOB: data.fromdob ? data.fromdob : null,
                ToDOB: data.todob ? data.todob : null,
                Gender: data.gender ? data.gender : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null
            }
        };

        return apiCall(`${apiUrls.settingsEmployees.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getSettingsEmployeesAction = createAsyncThunk(
    "SettingsEmployees/getSettingsEmployees",
    async (data) => {
        let d = {
            Device: 1,
            UserID: data
        };
        return apiCall(`${apiUrls.settingsEmployees.getEmployees}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateSettingsEmployeesAction = createAsyncThunk(
    "SettingsEmployees/settingsEmployeesInsertUpdate",
    async (data) => {
        return apiCall(`${apiUrls.settingsEmployees.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.userID ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusSettingsEmployeesAction = createAsyncThunk(
    "SettingsEmployees/changeStatusSettingsEmployees",
    async (data) => {
        // let d = {
        //     Device: 1,
        //     Id: data.userID,
        //     Statustype: 3
        //   };
        return apiCall(`${apiUrls.settingsEmployees.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingSettingsEmployees = createAsyncThunk(
    "SettingsEmployees/getAllSortingSettingsEmployees",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.settingsEmployees.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSettingsEmployeesFromLocation = createAsyncThunk(
    "SettingsEmployees/getAllSettingsEmployeesFromLocation",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                // ClientID: null,
                CenterID: data.centerid ? data.centerid : null,
                LocationID: data.locationid ? data.locationid : null,
                EmpCode: data.empcode ? data.empcode : null,
                Name: data.name ? data.name : null,
                UserName: data.username ? data.username : null,
                JobTitleID: data.jobtitleid ? data.jobtitleid : null,
                jobTitle: data.jobTitle ? data.jobTitle : null,
                FromDOB: data.fromdob ? data.fromdob : null,
                ToDOB: data.todob ? data.todob : null,
                Gender: data.gender ? data.gender : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null
            }
        };

        return apiCall(`${apiUrls.settingsEmployees.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
