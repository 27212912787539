import React from "react";
import { Routes, Route, Navigate, Switch } from "react-router-dom";
import WithLoading from "./components/shared/WithLoading";

const LoginPage = WithLoading(React.lazy(() => import("./pages/Login/Login")));
const LocationSelectionPage = WithLoading(React.lazy(() => import("./pages/Login/LocationSelection")));

const AuthRoutes = () => {

    return (
        <Routes>

            <Route path="/auth" >
                <Route path="login" element={<LoginPage />} />
            </Route>
            <Route path="*" element={<LoginPage />} />
            <Route path='/locationselection' element={<LocationSelectionPage />} />

        </Routes>
    );


};

export default AuthRoutes;
