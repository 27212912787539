import { createSlice } from "@reduxjs/toolkit";
import { loginAction, logoutACTION } from "../../actions/authAction/index";
import { getLocalStore } from "../../../LocalStorage";
const LOCAL_STORAGE_KEY_USER = process.env.REACT_APP_LOCAL_STORAGE_KEY_USER;
const user = getLocalStore(LOCAL_STORAGE_KEY_USER);

const INITIAL_STATE = user
  ? {
    isLoggedIn: true,
    user: user,
    apiMsg: {
      message: null,
      status: null,
      error: null,
    },
  }
  : {
    isLoggedIn: false,
    user: null,
    apiMsg: {
      message: null,
      status: null,
      error: null,
    },
  };

const authReducer = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    //------------------------------- loginAction start -------------------------

    builder.addCase(loginAction.fulfilled, (state, action) => {
      //  console.log('logIn',action);
      if (!action.payload.hasError) {
        state.isLoggedIn = true;
        state.user = action.payload.data;

      } else {
        state.isLoggedIn = false;
        state.user = null;
      }
    });

    builder.addCase(loginAction.pending, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });
    builder.addCase(loginAction.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    });

    //------------------------------- logoutACTION start -------------------------
    builder.addCase(logoutACTION.fulfilled, (state, action) => {
      // console.log('logoutACTION',action)
      state.isLoggedIn = false;
      state.user = null;
      state.apiMsg.status = null;
      state.apiMsg.message = null;
    });
  },
});

export const { } = authReducer.actions;
export default authReducer.reducer;
