import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSpermCryoTech, getSpermCryoTechAction, insertUpdateSpermCryoTechAction, changeStatusSpermCryoTechAction, updateSpermCryoTechDisplayOrder, getAllSortingSpermCryoTech } from "../../actions/spermSetupAction/cryoTechAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    spermCryoTech: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSpermCryoTech: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSpermCryoTech: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSpermCryoTech: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSpermCryoTechData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateSpermCryoTechDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const spermCryoTechReducer = createSlice({
    name: "spermCryoTechdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSpermCryoTechState(state, action) {
            state.selectedSpermCryoTech = INITIAL_STATE.selectedSpermCryoTech;
            state.changeStatusSpermCryoTech = INITIAL_STATE.changeStatusSpermCryoTech;
        },
        ResetSpermCryoTechAllDataState(state, action) {
            state.spermCryoTech = INITIAL_STATE.spermCryoTech;
        },
        ResetSpermCryoTechInsertUpdateDetails(state, action) {
            state.insertUpdateSpermCryoTech = INITIAL_STATE.insertUpdateSpermCryoTech;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateSpermCryoTechDisplayOrderData = INITIAL_STATE.updateSpermCryoTechDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllSpermCryoTech start--------------------

        builder.addCase(getAllSpermCryoTech.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.spermCryoTech.data.records = action.payload.data.records;
            state.spermCryoTech.apiMsg.status = action.meta.requestStatus;
            state.spermCryoTech.apiMsg.message = action.payload.message.message;
            state.spermCryoTech.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.spermCryoTech.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSpermCryoTech.pending, (state, action) => {

            state.spermCryoTech.data.records = null;
            state.spermCryoTech.apiMsg.message = action.meta.requestStatus;;
            state.spermCryoTech.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSpermCryoTech.rejected, (state, action) => {

            state.spermCryoTech.apiMsg.message = action.error.message;
            state.spermCryoTech.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSpermCryoTech Action end--------------------

        //   getClient start

        builder.addCase(getSpermCryoTechAction.fulfilled, (state, action) => {
            state.selectedSpermCryoTech.data = action.payload.data
            state.selectedSpermCryoTech.apiMsg.status = action.meta.requestStatus;
            state.selectedSpermCryoTech.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSpermCryoTechAction.pending, (state, action) => {
            state.selectedSpermCryoTech.data = null;
            state.selectedSpermCryoTech.apiMsg.message = action.meta.requestStatus;;
            state.selectedSpermCryoTech.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSpermCryoTechAction.rejected, (state, action) => {
            state.selectedSpermCryoTech.apiMsg.message = action.error.message;
            state.selectedSpermCryoTech.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSpermCryoTechAction start

        builder.addCase(
            insertUpdateSpermCryoTechAction.fulfilled,
            (state, action) => {
                state.insertUpdateSpermCryoTech.data = action.payload.data.data;
                state.insertUpdateSpermCryoTech.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSpermCryoTech.apiMsg.message = action.payload.message.message;
                state.insertUpdateSpermCryoTech.newRecord = !action.meta.arg.cryoTechID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.spermCryoTech.data.records;


                    const index = existingRecords.findIndex(record => record.cryoTechID === newData.cryoTechID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.spermCryoTech.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.spermCryoTech.data.records = state.spermCryoTech.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.cryoTechID === newData.cryoTechID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSpermCryoTechAction.pending,
            (state, action) => {
                state.insertUpdateSpermCryoTech.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSpermCryoTechAction.rejected,
            (state, action) => {

                state.insertUpdateSpermCryoTech.apiMsg.message = action.error.message;
                state.insertUpdateSpermCryoTech.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSpermCryoTechAction.fulfilled,
            (state, action) => {

                state.changeStatusSpermCryoTech.data = action.payload.data;
                state.changeStatusSpermCryoTech.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSpermCryoTech.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.spermCryoTech.data.records = state.spermCryoTech.data.records.filter(record => record.cryoTechID !== deletedId);
                    state.spermCryoTech.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.spermCryoTech.data.records = state.spermCryoTech.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.cryoTechID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.spermCryoTech.data.records = state.spermCryoTech.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.cryoTechID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.spermCryoTech.data.records = state.spermCryoTech.data.records.map(record => {
                            return {
                                ...record,
                                active: record.cryoTechID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.spermCryoTech.data.records = state.spermCryoTech.data.records.map(record => {
                            return {
                                ...record,
                                active: record.cryoTechID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSpermCryoTechAction.pending,
            (state, action) => {
                state.changeStatusSpermCryoTech.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSpermCryoTechAction.rejected,
            (state, action) => {
                state.changeStatusSpermCryoTech.apiMsg.message = action.error.message;
                state.changeStatusSpermCryoTech.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateSpermCryoTechDisplayOrder.fulfilled, (state, action) => {
            state.updateSpermCryoTechDisplayOrderData.data = action.payload.data
            state.updateSpermCryoTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateSpermCryoTechDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateSpermCryoTechDisplayOrder.pending, (state, action) => {
            state.updateSpermCryoTechDisplayOrderData.data = null;
            state.updateSpermCryoTechDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateSpermCryoTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateSpermCryoTechDisplayOrder.rejected, (state, action) => {
            state.updateSpermCryoTechDisplayOrderData.apiMsg.message = action.error.message;
            state.updateSpermCryoTechDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingSpermCryoTech.fulfilled, (state, action) => {
            state.sortingSpermCryoTechData.data.records = action.payload.data.records;
            state.sortingSpermCryoTechData.apiMsg.status = action.meta.requestStatus;
            state.sortingSpermCryoTechData.apiMsg.message = action.payload.message.message;
            state.sortingSpermCryoTechData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSpermCryoTech.pending, (state, action) => {

            state.sortingSpermCryoTechData.data.records = null;
            state.sortingSpermCryoTechData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSpermCryoTechData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSpermCryoTech.rejected, (state, action) => {

            state.sortingSpermCryoTechData.apiMsg.message = action.error.message;
            state.sortingSpermCryoTechData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetSpermCryoTechState, ResetSpermCryoTechAllDataState, ResetSpermCryoTechInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = spermCryoTechReducer.actions;
export default spermCryoTechReducer.reducer;