import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../../API/api-urls";
import { apiCall } from "../../../API/index";
import { Enums } from "../../../helper/constants/enums";

export const getAllEmbryoTubingTech = createAsyncThunk(
    "EmbryoTubingTech/getAllEmbryoTubingTech",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                Name: data.name ? data.name : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null,
                DefaultSelected: data.defaultSelected !== undefined && data.defaultSelected !== "" ? JSON.parse(data.defaultSelected) : null
            }
        };

        return apiCall(`${apiUrls.embryoTubingTech.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getEmbryoTubingTechAction = createAsyncThunk(
    "TubingTech/getEmbryoTubingTech",
    async (data) => {
        let d = {
            Device: 1,
            Id: data
        };
        return apiCall(`${apiUrls.embryoTubingTech.getEmbryoTubingTech}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateEmbryoTubingTechAction = createAsyncThunk(
    "TubingTech/embryoTubingTechInsertUpdate",
    async (data) => {
        return apiCall(`${apiUrls.embryoTubingTech.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.tubingTechID ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusEmbryoTubingTechAction = createAsyncThunk(
    "TubingTech/changeStatusEmbryoTubingTech",
    async (data) => {
        // let d = {
        //     Device: 1,
        //     Id: data.tubingTechID,
        //     Statustype: 3
        //   };
        return apiCall(`${apiUrls.embryoTubingTech.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingTubingTech = createAsyncThunk(
    "TubingTech/getAllSortingTubingTech",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.embryoTubingTech.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const updateTubingTechDisplayOrder = createAsyncThunk(
    "TubingTech/insertupdatedisplayorder",
    async (data) => {
        return apiCall(`${apiUrls.embryoTubingTech.updateDisplayOrder}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);