import React from "react";
import { Oval } from 'react-loader-spinner'


const RouteLoader = () => {
    return (<>
        <div className="route-loader">
            <Oval
                height={60}
                width={60}
                color="#2c475a"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#2c475a"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />

        </div>

    </>);
}

export default RouteLoader;