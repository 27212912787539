import { combineReducers } from 'redux'
import clientReducer from './clientReducer';
import centerReducer from './centerReducer';
import locationReducer from './locationReducer';
import authReducer from './authReducer/index';
import embryoPgtResultReducer from './embryoSetupReducer/pgtResultReducer';
import embryoFreezeMethodReducer from "./embryoSetupReducer/freezeMethodReducer";
import embryoDeviceTypeReducer from "./embryoSetupReducer/deviceTypeReducer";
import embryoEggSourceReducer from "./embryoSetupReducer/eggSourceReducer"
import embryoSpermSourceReducer from "./embryoSetupReducer/spermSourceReducer";
import embryoPgtLabReducer from "./embryoSetupReducer/pgtLabReducer";
import embryoCryoTechReducer from "./embryoSetupReducer/cryoTechReducer";
import embryoBXTechReducer from "./embryoSetupReducer/bxTechReducer"
import embryoTubingTechReducer from "./embryoSetupReducer/tubingTechReducer"
import oocyteOocyteStageReducer from './oocyteSetupReducer/oocyteStageReducer';
import oocyteOocyteDayReducer from './oocyteSetupReducer/oocyteDayReducer';
import oocyteEggSourceReducer from './oocyteSetupReducer/eggSourceReducer';
import oocyteFreezeMethodReducer from './oocyteSetupReducer/freezeMethodReducer';
import oocyteDeviceTypeReducer from './oocyteSetupReducer/deviceTypeReducer';
import oocyteCaneLabelReducer from './oocyteSetupReducer/caneLabelReducer';
import oocyteCryoTechReducer from './oocyteSetupReducer/cryoTechReducer';
import spermSampleTypeReducer from './spermSetupReducer/sampleTypeReducer';
import spermSpermSourceReducer from './spermSetupReducer/spermSourceReducer';
import spermFreezeMethodReducer from './spermSetupReducer/freezeMethodReducer';
import spermVialTypeReducer from './spermSetupReducer/vialTypeReducer';
import spermCryoTechReducer from './spermSetupReducer/cryoTechReducer';
import settingPatientLanguageReducer from './settingsReducer/patientLanguageReducer';
import settingSpecimenStatusReducer from './settingsReducer/specimenStatusReducer';
import settingsAdminUsersReducer from './settingsReducer/adminUsersReducer';
import settingsInvoiceReducer from './settingsReducer/insuranceReducer';
import settingsRolesReducer from './settingsReducer/rolesReducer';
import settingsEmployeesReducer from './settingsReducer/EmployeesReducer';
import patientsReducer from './patientsReducer';
import partnersReducer from './partnersReducer';
import settingJobTitleReducer from './settingsReducer/jobTitleReducer';
import settingsCaneReducer from './settingsReducer/caneReducer';
import settingsTankReducer from './settingsReducer/tankReducer';
import settingsCannisterReducer from './settingsReducer/cannisterReducer';

const reducer = {
  auth: authReducer,
  clientReducer,
  centerReducer,
  locationReducer,
  embryoPgtResultReducer,
  embryoEggSourceReducer,
  embryoFreezeMethodReducer,
  embryoDeviceTypeReducer,
  embryoSpermSourceReducer,
  embryoPgtLabReducer,
  embryoCryoTechReducer,
  embryoBXTechReducer,
  embryoTubingTechReducer,
  oocyteOocyteStageReducer,
  oocyteOocyteDayReducer,
  oocyteEggSourceReducer,
  oocyteFreezeMethodReducer,
  oocyteDeviceTypeReducer,
  oocyteCaneLabelReducer,
  oocyteCryoTechReducer,
  spermSampleTypeReducer,
  spermSpermSourceReducer,
  spermFreezeMethodReducer,
  spermVialTypeReducer,
  spermCryoTechReducer,
  settingPatientLanguageReducer,
  settingSpecimenStatusReducer,
  settingsAdminUsersReducer,
  settingsInvoiceReducer,
  settingsRolesReducer,
  settingsEmployeesReducer,
  patientsReducer,
  partnersReducer,
  settingJobTitleReducer,
  settingsCaneReducer,
  settingsTankReducer,
  settingsCannisterReducer,
  
  
}

const appReducer = combineReducers(reducer);


// const rootReducer = (state, action) => {
//   console.log("state",state);
//   console.log("action",action);
//   return appReducer(state, action)

// }

// export default rootReducer;
// // export default combineReducers(reducer);

// export function CLEARSTORE() {
//   return {
//     type: "CLEARSTORE"
//   };
// }

const rootReducer = (state, action) => {

  if (action.type === 'auth/logout/fulfilled') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}


export default rootReducer;
// export default combineReducers(reducer);

export function CLEARSTORE() {
  return {
    type: "CLEARSTORE"
  };
}