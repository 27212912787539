import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../API/api-urls";
import { apiCall } from "../../API/index";
import { Enums } from "../../helper/constants/enums";
import { getLocalStore } from '../../LocalStorage';

export const getAllCenters = createAsyncThunk(
    "Center/getAllCenters",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                clientID : data.clientID ? data.clientID : getLocalStore(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER)?.user.clientID,
                Code: data.code ? data.code : null,
                Name: data.name ? data.name : null,
                Email: data.email ? data.email : null,
                UserName: data.username ? data.username : null,
                PointOfContact: data.pointofcontact ? data.pointofcontact : null,
                TimeZoneID: data.timezoneid ? data.timezoneid : null,
                NPI: data.npi ? data.npi : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null

            }
        };

        return apiCall(`${apiUrls.center.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getCenterAction = createAsyncThunk(
    "Center/getCenter",
    async (data) => {
        let d = {
            Device: 1,
            Id: data
        };
        return apiCall(`${apiUrls.center.getCenter}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateCenterAction = createAsyncThunk(
    "Center/centerInsertUpdate",
    async (data) => {
        return apiCall(`${apiUrls.center.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.centerID ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusCenterAction = createAsyncThunk(
    "Center/changeStatusCenter",
    async (data) => {
        // let d = {
        //     Device: 1,
        //     Id: data.centerID,
        //     Statustype: 3
        //   };
        return apiCall(`${apiUrls.center.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingCenter = createAsyncThunk(
    "Center/getAllSortingCenter",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.center.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllTimeZoneData = createAsyncThunk(
    "masterdata/timezone",
    async (data) => {
      return apiCall("settings/timezone/getall", "GET", {}, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,
              }
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );