import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllCenters, getCenterAction, changeStatusCenterAction, insertUpdateCenterAction, getAllSortingCenter, getAllTimeZoneData } from "../actions/centerActions";
import { Enums } from "../../helper/constants/enums";

const INITIAL_STATE = {
    allCenters: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedCenters: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateCenters: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusCenters: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingCentersData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    allTimezoneData: {
        data: {
            records: null,
            totalRecords: 0
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

}

const centerReducer = createSlice({
    name: "centersdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCenterState(state, action) {
            state.selectedCenters = INITIAL_STATE.selectedCenters;
            state.changeStatusCenters = INITIAL_STATE.changeStatusCenters;
        },
        ResetCenterAllDataState(state, action) {
            state.allCenters = INITIAL_STATE.allCenters;
        },
        ResetCenterInsertUpdateDetails(state, action) {
            state.insertUpdateCenters = INITIAL_STATE.insertUpdateCenters;
        },
        ResetTimeZoneList(state, action) {
            state.allTimezoneData = INITIAL_STATE.allTimezoneData;
        },
    },
    extraReducers: (builder) => {

        //  --------getAllCenters start--------------------

        builder.addCase(getAllCenters.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.allCenters.data.records = action.payload.data.records;
            state.allCenters.apiMsg.status = action.meta.requestStatus;
            state.allCenters.apiMsg.message = action.payload.message.message;
            state.allCenters.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.allCenters.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllCenters.pending, (state, action) => {

            state.allCenters.data.records = null;
            state.allCenters.apiMsg.message = action.meta.requestStatus;;
            state.allCenters.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCenters.rejected, (state, action) => {

            state.allCenters.apiMsg.message = action.error.message;
            state.allCenters.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllCenters Action end--------------------

        //   getCenter start

        builder.addCase(getCenterAction.fulfilled, (state, action) => {
            state.selectedCenters.data = action.payload.data
            state.selectedCenters.apiMsg.status = action.meta.requestStatus;
            state.selectedCenters.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getCenterAction.pending, (state, action) => {
            state.selectedCenters.data = null;
            state.selectedCenters.apiMsg.message = action.meta.requestStatus;;
            state.selectedCenters.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getCenterAction.rejected, (state, action) => {
            state.selectedCenters.apiMsg.message = action.error.message;
            state.selectedCenters.apiMsg.status = action.meta.requestStatus;
        });


        //   getCenter end



        // insertUpdateCenterAction start

        builder.addCase(
            insertUpdateCenterAction.fulfilled,
            (state, action) => {
                state.insertUpdateCenters.data = action.payload.data.data;
                state.insertUpdateCenters.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateCenters.apiMsg.message = action.payload.message.message;
                state.insertUpdateCenters.newRecord = !action.meta.arg.centerID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.allCenters.data.records;


                    const index = existingRecords.findIndex(record => record.centerID === newData.centerID);
                    // console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            code: newData.code,
                            name: newData.name,
                            email: newData.email,
                            pointOfContact: newData.pointOfContact,
                            timeZoneID: newData.timeZoneID,
                            npi: newData.npi,
                            active: newData.active,
                            clientID: newData.clientID,
                        };
                    }
                    else {
                        state.allCenters.data.totalRecords += 1;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateCenterAction.pending,
            (state, action) => {
                state.insertUpdateCenters.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateCenterAction.rejected,
            (state, action) => {

                state.insertUpdateCenters.apiMsg.message = action.error.message;
                state.insertUpdateCenters.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update center action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusCenterAction.fulfilled,
            (state, action) => {

                state.changeStatusCenters.data = action.payload.data;
                state.changeStatusCenters.apiMsg.status = action.meta.requestStatus;
                state.changeStatusCenters.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Center.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.allCenters.data.records = state.allCenters.data.records.filter(record => record.centerID !== deletedId);
                    state.allCenters.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Center.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Center.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Center.ChangeStatus.Active) {
                        state.allCenters.data.records = state.allCenters.data.records.map(record => {
                            return {
                                ...record,
                                active: record.centerID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Center.ChangeStatus.Inactive) {
                        state.allCenters.data.records = state.allCenters.data.records.map(record => {
                            return {
                                ...record,
                                active: record.centerID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusCenterAction.pending,
            (state, action) => {
                state.changeStatusCenters.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusCenterAction.rejected,
            (state, action) => {
                state.changeStatusCenters.apiMsg.message = action.error.message;
                state.changeStatusCenters.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(getAllSortingCenter.fulfilled, (state, action) => {
            state.sortingCentersData.data.records = action.payload.data.records;
            state.sortingCentersData.apiMsg.status = action.meta.requestStatus;
            state.sortingCentersData.apiMsg.message = action.payload.message.message;
            state.sortingCentersData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingCenter.pending, (state, action) => {

            state.sortingCentersData.data.records = null;
            state.sortingCentersData.apiMsg.message = action.meta.requestStatus;;
            state.sortingCentersData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingCenter.rejected, (state, action) => {

            state.sortingCentersData.apiMsg.message = action.error.message;
            state.sortingCentersData.apiMsg.status = action.meta.requestStatus;
        });

        // ----------------------- getalltimezonedata --------------------------//

        builder.addCase(getAllTimeZoneData.fulfilled, (state, action) => {
            state.allTimezoneData.data.records = action.payload.data;
            state.allTimezoneData.apiMsg.status = action.meta.requestStatus;

        });
        builder.addCase(getAllTimeZoneData.pending, (state, action) => {
            state.allTimezoneData.data.records = null;
            state.allTimezoneData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllTimeZoneData.rejected, (state, action) => {
            state.allTimezoneData.apiMsg.status = action.meta.requestStatus;
        });

    },
});
export const { RESET, ResetCenterState, ResetCenterAllDataState, ResetCenterInsertUpdateDetails, ResetTimeZoneList } = centerReducer.actions;
export default centerReducer.reducer;