import { ReducerStateContant } from "../constants/general-const";
import { getLocalStore } from "../../LocalStorage";
import { Menus } from "../constants/enums";

function StateCompleted(stateObject) {
  if (stateObject.apiMsg && stateObject.apiMsg.status && stateObject.apiMsg.status === ReducerStateContant.fulfilled) {
    return true;
  }
  else {
    return false;
  }
}
function StateNotInitate(stateObject) {
  if (stateObject.apiMsg && stateObject.apiMsg.status == null) {
    return true;
  }
  else if (stateObject.apiMsg == null) {
    return true;
  }
  else {
    return false;
  }
}
function StatePending(stateObject) {
  if (stateObject.apiMsg.status === ReducerStateContant.pending) {
    return true;
  }
  else {
    return false;
  }
}
function StateRejected(stateObject) {
  if (stateObject.apiMsg.status === ReducerStateContant.rejected) {
    return true;
  }
  else {
    return false;
  }
}
function HasRecords(obj) {
  // console.log( "has record length",obj.data.records)
  if (obj && obj.data && obj.data.records && obj.data.records.length) {

    return true;
  }
  else {
    return false;
  }
}
function StateHasData(obj) {
  // console.log( "has record length",obj.data.records)
  if (obj && obj.data) {

    return true;
  }
  else {
    return false;
  }
}
function GetTimeSlot() {

  // configure array for time dropdown
  const timeSlot = [];
  {
    Array.from(Array(24), (time, i) => {
      let displayTime = i <= 9 ? `0${i}` : `${i}`;
      let displayMin = 0;
      Array.from(Array(12), (time, j) => {
        displayMin = parseInt(displayMin);
        if (j > 0 && displayMin <= 55) {
          displayMin = displayMin < 5 ? `0${displayMin + 5}` : `${displayMin + 5}`;
        }
        else {
          displayMin = `00`;
        }


        let displayTimeInfo = `${displayTime}:${displayMin}:00`;
        timeSlot.push(displayTimeInfo);
      })

    })
  }

  return timeSlot;
}
function CheckPermission(permissionName) {
  const LOCAL_STORAGE_KEY_USER = process.env.REACT_APP_LOCAL_STORAGE_KEY_USER;
  const user = getLocalStore(LOCAL_STORAGE_KEY_USER).user;
  if (user.roles && user.roles.length && user.roles.indexOf("Admin") != "-1") {
    return true;
  }
  else {
    // console.log("user.permissions permissionName", permissionName, user.permissions && user.permissions.indexOf(permissionName))
    if (user.permissions && user.permissions.indexOf(permissionName) != "-1") {
      return true;
    }
    else {
      return false;
    }
  }
}
function GetMappedDataSourceForSelect(dataSoruce, valueKey, labelKey, viewAll) {
  var selectSource = [];
  if (dataSoruce && dataSoruce.length) {
    if (viewAll) {
      selectSource.push({ value: 'all', label: 'View All' })
    }
    dataSoruce.map((r) => {
      selectSource.push({
        value: r[valueKey],
        label: r[labelKey]
      })
    })
  }

  return selectSource;
}


function allowViewMenu(menu) {
  let permissionList = getLocalStore(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER)?.user.permissions;
  let activemenu = menu && Object.values(Menus).find(menuitem => menuitem.route === menu).pageName;
  // console.log(activemenu);
  let filteredPermissions = permissionList && permissionList.length ? permissionList.filter(permission => permission.isView === true) : null;
  if (menu) {
    const isMenuViewAllowed = filteredPermissions && filteredPermissions.length ? filteredPermissions.some((listmenu) => listmenu.pageName.toLowerCase() === activemenu.toLowerCase()) : null;
    if (isMenuViewAllowed || (activemenu && activemenu === "/dashboard")) {
      return true;
    } else {
      return false;
    }
  }
  else {
    return true;
  }
}

function allowModifyMenus(menu) {
  let permissionList = getLocalStore(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER)?.user.permissions;

  let filteredPermissions = permissionList && permissionList.length ? permissionList.filter(permission => permission.isModify === true) : null;

  const isMenuAllowed = filteredPermissions && filteredPermissions.length ? filteredPermissions.some((listmenu) => listmenu.pageName === menu) : null;

  if (isMenuAllowed) {
    return true;
  } else {
    return false;
  }

}

function allowDeleteMenus(menu) {
  let permissionList = getLocalStore(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER)?.user.permissions;

  let filteredPermissions = permissionList && permissionList.length ? permissionList.filter(permission => permission.isDelete === true) : null;

  const isMenuAllowed = filteredPermissions && filteredPermissions.length ? filteredPermissions.some((listmenu) => listmenu.pageName === menu) : null;

  if (isMenuAllowed) {
    return true;
  } else {
    return false;
  }

}

function allowViewMenusInGrid(menu) {
  let permissionList = getLocalStore(process.env.REACT_APP_LOCAL_STORAGE_KEY_USER)?.user.permissions;

  let filteredPermissions = permissionList && permissionList.length ? permissionList.filter(permission => permission.isView === true) : null;

  const isMenuAllowed = filteredPermissions && filteredPermissions.length ? filteredPermissions.some((listmenu) => listmenu.pageName === menu) : null;

  if (isMenuAllowed) {
    return true;
  } else {
    return false;
  }

}

function DEANumberValidator(DEANumber) {
  // var DEANumber = "BB1388568";
  if (parseInt(DEANumber.length) === 9) {


    if (
      DEANumber.charCodeAt(0) >= 65 &&
      DEANumber.charCodeAt(0) <= 90 &&
      DEANumber.charCodeAt(1) >= 65 &&
      DEANumber.charCodeAt(1) <= 90
    ) {
      const isDigits = DEANumber.slice(2, 8)
        .split("")
        .every((char) => char >= "0" && char <= "9");

      if (isDigits) {
        const digits = DEANumber.slice(2, 8).split("");
        const firstThirdFiftDigitSum =
          parseInt(digits[0]) + parseInt(digits[2]) + parseInt(digits[4]);
        const secondFourthSixthDigitSum =
          parseInt(digits[1]) + parseInt(digits[3]) + parseInt(digits[5]);
        var ans = (firstThirdFiftDigitSum + 2 * secondFourthSixthDigitSum).toString();
        if (ans[ans.length - 1] === DEANumber[DEANumber.length - 1]) {
          return true;
        }
        else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  else {
    return false;
  }
}


export { StateCompleted, StateNotInitate, StatePending, StateRejected, GetTimeSlot, HasRecords, StateHasData, CheckPermission, GetMappedDataSourceForSelect, allowModifyMenus, allowDeleteMenus, allowViewMenusInGrid, allowViewMenu, DEANumberValidator };