import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingsJobTitle, getSettingsJobTitleAction, insertUpdatesJobTitleAction, changeStatusSettingsJobTitleAction, updateSettingsJobTitleDisplayOrder, getAllSortingSettingsJobTitle } from "../../actions/settingsAction/jobTitleAction";
import { Enums } from "../../../helper/constants/enums";


const INITIAL_STATE = {
    settingJobTitle: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingJobTitle: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingJobTitle: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingJobTitle: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingJobTitleData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateSettingJobTitleDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const settingJobTitleReducer = createSlice({
    name: "settingjobtitledata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingJobTitleState(state, action) {
            state.selectedSettingJobTitle = INITIAL_STATE.selectedSettingJobTitle;
            state.changeStatusSettingJobTitle = INITIAL_STATE.changeStatusSettingJobTitle;
        },
        ResetSettingJobTitleAllDataState(state, action) {
            state.settingJobTitle = INITIAL_STATE.settingJobTitle;
        },
        ResetSettingJobTitleInsertUpdateDetails(state, action) {
            state.insertUpdateSettingJobTitle = INITIAL_STATE.insertUpdateSettingJobTitle;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateSettingJobTitleDisplayOrderData = INITIAL_STATE.updateSettingJobTitleDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllSettingsJobTitle start--------------------

        builder.addCase(getAllSettingsJobTitle.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingJobTitle.data.records = action.payload.data.records;
            state.settingJobTitle.apiMsg.status = action.meta.requestStatus;
            state.settingJobTitle.apiMsg.message = action.payload.message.message;
            state.settingJobTitle.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingJobTitle.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsJobTitle.pending, (state, action) => {

            state.settingJobTitle.data.records = null;
            state.settingJobTitle.apiMsg.message = action.meta.requestStatus;;
            state.settingJobTitle.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsJobTitle.rejected, (state, action) => {

            state.settingJobTitle.apiMsg.message = action.error.message;
            state.settingJobTitle.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingsJobTitle Action end--------------------

        //   getClient start

        builder.addCase(getSettingsJobTitleAction.fulfilled, (state, action) => {
            state.selectedSettingJobTitle.data = action.payload.data
            state.selectedSettingJobTitle.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingJobTitle.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsJobTitleAction.pending, (state, action) => {
            state.selectedSettingJobTitle.data = null;
            state.selectedSettingJobTitle.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingJobTitle.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsJobTitleAction.rejected, (state, action) => {
            state.selectedSettingJobTitle.apiMsg.message = action.error.message;
            state.selectedSettingJobTitle.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdatesJobTitleAction start

        builder.addCase(
            insertUpdatesJobTitleAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingJobTitle.data = action.payload.data.data;
                state.insertUpdateSettingJobTitle.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingJobTitle.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingJobTitle.newRecord = !action.meta.arg.jobTitleID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingJobTitle.data.records;


                    const index = existingRecords.findIndex(record => record.jobTitleID === newData.jobTitleID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active
                            // defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.settingJobTitle.data.totalRecords += 1;
                    }


                    // if (newData.defaultSelected === true) {
                    //     state.settingJobTitle.data.records = state.settingJobTitle.data.records.map(record => ({
                    //         ...record,
                    //         defaultSelected: record.jobTitleID === newData.jobTitleID
                    //     }));
                    // }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdatesJobTitleAction.pending,
            (state, action) => {
                state.insertUpdateSettingJobTitle.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatesJobTitleAction.rejected,
            (state, action) => {

                state.insertUpdateSettingJobTitle.apiMsg.message = action.error.message;
                state.insertUpdateSettingJobTitle.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsJobTitleAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingJobTitle.data = action.payload.data;
                state.changeStatusSettingJobTitle.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingJobTitle.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.settingJobTitle.data.records = state.settingJobTitle.data.records.filter(record => record.jobTitleID !== deletedId);
                    state.settingJobTitle.data.totalRecords -= 1;
                }
                // else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                //     if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                //         state.settingJobTitle.data.records = state.settingJobTitle.data.records.map(record => {
                //             return {
                //                 ...record,
                //                 defaultSelected: record.jobTitleID === action.meta.arg.Id ? true : false
                //             };
                //         });
                //     }
                    // else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                    //     state.settingJobTitle.data.records = state.settingJobTitle.data.records.map(record => {
                    //         return {
                    //             ...record,
                    //             defaultSelected: record.jobTitleID === action.meta.arg.Id ? false : record.defaultSelected
                    //         };
                    //     });
                    // }
                // }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.settingJobTitle.data.records = state.settingJobTitle.data.records.map(record => {
                            return {
                                ...record,
                                active: record.jobTitleID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.settingJobTitle.data.records = state.settingJobTitle.data.records.map(record => {
                            return {
                                ...record,
                                active: record.jobTitleID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsJobTitleAction.pending,
            (state, action) => {
                state.changeStatusSettingJobTitle.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsJobTitleAction.rejected,
            (state, action) => {
                state.changeStatusSettingJobTitle.apiMsg.message = action.error.message;
                state.changeStatusSettingJobTitle.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateSettingsJobTitleDisplayOrder.fulfilled, (state, action) => {
            state.updateSettingJobTitleDisplayOrderData.data = action.payload.data
            state.updateSettingJobTitleDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateSettingJobTitleDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateSettingsJobTitleDisplayOrder.pending, (state, action) => {
            state.updateSettingJobTitleDisplayOrderData.data = null;
            state.updateSettingJobTitleDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateSettingJobTitleDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateSettingsJobTitleDisplayOrder.rejected, (state, action) => {
            state.updateSettingJobTitleDisplayOrderData.apiMsg.message = action.error.message;
            state.updateSettingJobTitleDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingSettingsJobTitle.fulfilled, (state, action) => {
            state.sortingSettingJobTitleData.data.records = action.payload.data.records;
            state.sortingSettingJobTitleData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingJobTitleData.apiMsg.message = action.payload.message.message;
            state.sortingSettingJobTitleData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsJobTitle.pending, (state, action) => {

            state.sortingSettingJobTitleData.data.records = null;
            state.sortingSettingJobTitleData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingJobTitleData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsJobTitle.rejected, (state, action) => {

            state.sortingSettingJobTitleData.apiMsg.message = action.error.message;
            state.sortingSettingJobTitleData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetSettingJobTitleState, ResetSettingJobTitleAllDataState, ResetSettingJobTitleInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = settingJobTitleReducer.actions;
export default settingJobTitleReducer.reducer;