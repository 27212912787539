import { Menus } from "./enums";

const MainMenus = {
    Dashboard: Menus.Dashboard.routeName,
    Clients: Menus.Clients.routeName,
    Centers: Menus.Centers.routeName,
    Patients : Menus.Patients.routeName,
    Locations : Menus.Locations.routeName,
    SampleMaster : Menus.SampleMaster.routeName,
    InventoryManagement : Menus.InventoryManagement.routeName,
    LongTermFacility : Menus.LongTermFacility.routeName,
    ManageShipment : Menus.ManageShipment.routeName,
    Settings: Menus.Settings.routeName,
    EmbryoSetup : Menus.EmbryoSetup.routeName,
    OocyteSetup : Menus.OocyteSetup.routeName,
    SpermSetup : Menus.SpermSetup.routeName,
    Configuration : Menus.Configuration.routeName,
    Reports : Menus.Reports.routeName,
};

const SampleMasterMenus = {
    sampleCollection: {
        Name: "Sample Collection",
        Order: 1,
        ParentId: MainMenus.SampleMaster,
        MenuId: Menus.SampleCollection.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-flask smb-icon",
    },
    sampleConfirmation: {
        Name: "Sample Confirmation",
        Order: 2,
        ParentId: MainMenus.SampleMaster,
        MenuId: Menus.SampleConfirmation.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-vial-circle-check smb-icon",
    },
    landingScreen: {
        Name: "Landing Screen",
        Order: 3,
        ParentId: MainMenus.SampleMaster,
        MenuId: Menus.LandingScreen.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-vials smb-icon",
    },
    manageInventory: {
        Name: "Manage Inventory",
        Order: 4,
        ParentId: MainMenus.SampleMaster,
        MenuId: Menus.ManageInventory.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-vial-circle-check smb-icon",
    },
};

const InventoryManagementMenus = {
    embryo: {
        Name: "Embryo",
        Order: 1,
        ParentId: MainMenus.InventoryManagement,
        MenuId: Menus.InventoryManagementEmbryo.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-glass-water smb-icon",
    },
    oocyte: {
        Name: "Oocyte",
        Order: 2,
        ParentId: MainMenus.InventoryManagement,
        MenuId: Menus.InventoryManagementOoctye.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-fill-drip smb-icon",
    },
    sperm: {
        Name: "Sperm",
        Order: 3,
        ParentId: MainMenus.InventoryManagement,
        MenuId: Menus.InventoryManagementSperm.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-prescription-bottle-medical smb-icon",
    },
};

const SettingsMenus = {
    users: {
        Name: "Admin Users",
        Order: 1,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsAdminUsers.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-user-tie smb-icon",
    },
    cane: {
        Name: "Cane",
        Order: 2,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsCane.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-glass-water smb-icon",
    },
    tank: {
        Name: "Tank",
        Order: 3,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsTank.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-fill-drip smb-icon",
    },
    cannister: {
        Name: "Canister",
        Order: 4,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsCannister.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-prescription-bottle-medical smb-icon",
    },
    patientLanguage: {
        Name: "Patient Language",
        Order: 5,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsPatientLanguage.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-language smb-icon",
    },
    specimenTypeStatus: {
        Name: "Specimen Type Status",
        Order: 6,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsSpecimenTypeStatus.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    insurance: {
        Name: "Insurance",
        Order: 7,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsInsurance.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-shield-halved smb-icon",
    },
    jobTitle: {
        Name: "Job Title",
        Order: 8,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsJobTitle.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-language smb-icon",
    },
    roles: {
        Name: "Roles",
        Order: 9,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsRoles.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-user-tag smb-icon",
    },
    employee: {
        Name: "Employee",
        Order: 10,
        ParentId: MainMenus.Settings,
        MenuId: Menus.SettingsEmployee.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-user-tie smb-icon",
    },
};

const EmbryoSetupMenus = {
    pgtResult: {
        Name: "PGT Result",
        Order: 1,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupPGTResult.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    eggSource: {
        Name: "Egg Source",
        Order: 2,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupEGGSource.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    spermSource: {
        Name: "Sperm Source",
        Order: 3,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupSpermSource.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    freezeMethod: {
        Name: "Freeze Method",
        Order: 4,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupFreezeMethod.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    deviceType: {
        Name: "Device Type",
        Order: 5,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupDeviceType.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    pgtLab: {
        Name: "PGT Lab",
        Order: 6,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupPGTLab.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    cryoTech: {
        Name: "Cryo Tech",
        Order: 7,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupCryoTech.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    bxTech: {
        Name: "BX Tech",
        Order: 8,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupBXTech.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    tubingTech: {
        Name: "Tubing Tech",
        Order: 9,
        ParentId: MainMenus.EmbryoSetup,
        MenuId: Menus.EmbryoSetupTubingTech.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
};

const OocyteSetupMenus = {
    oocyteStage: {
        Name: "Oocyte Stage",
        Order: 1,
        ParentId: MainMenus.OocyteSetup,
        MenuId: Menus.OocyteSetupOocyteStage.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    oocyteDay: {
        Name: "Oocyte Day",
        Order: 2,
        ParentId: MainMenus.OocyteSetup,
        MenuId: Menus.OocyteSetupOocyteDay.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    eggSource: {
        Name: "EGG Source",
        Order: 3,
        ParentId: MainMenus.OocyteSetup,
        MenuId: Menus.OocyteSetupEggSource.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    freezeMethod: {
        Name: "Freeze Method",
        Order: 4,
        ParentId: MainMenus.OocyteSetup,
        MenuId: Menus.OocyteSetupFreezeMethod.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    deviceType: {
        Name: "Device Type",
        Order: 5,
        ParentId: MainMenus.OocyteSetup,
        MenuId: Menus.OocyteSetupDeviceType.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    caneLabel: {
        Name: "Cane Label",
        Order: 6,
        ParentId: MainMenus.OocyteSetup,
        MenuId: Menus.OocyteSetupCaneLabel.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    cryoTech: {
        Name: "Cryo Tech",
        Order: 7,
        ParentId: MainMenus.OocyteSetup,
        MenuId: Menus.OocyteSetupCryoTech.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
};

const SpermSetupMenus = {
    sampleType: {
        Name: "Sample Type",
        Order: 1,
        ParentId: MainMenus.SpermSetup,
        MenuId: Menus.SpermSetupSampleType.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    spermSouurce: {
        Name: "Sperm Source",
        Order: 2,
        ParentId: MainMenus.SpermSetup,
        MenuId: Menus.SpermSetupSpermSource.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    freezeMethod: {
        Name: "Freeze Method",
        Order: 3,
        ParentId: MainMenus.SpermSetup,
        MenuId: Menus.SpermSetupFreezeMethod.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    vialType: {
        Name: "Vial Type",
        Order: 4,
        ParentId: MainMenus.SpermSetup,
        MenuId: Menus.SpermSetupVialType.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
    cryoTech: {
        Name: "Cryo Tech",
        Order: 5,
        ParentId: MainMenus.SpermSetup,
        MenuId: Menus.SpermSetupCryoTech.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-gear smb-icon",
    },
};

const configurationMenus = {
    lookupMaster: {
        Name: "Lookup Master",
        Order: 1,
        ParentId: MainMenus.Configuration,
        MenuId: Menus.ConfigurationLookupMaster.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-table smb-icon",
    },
};

const ReportsMenus = {
    auditLogReport: {
        Name: "Audit Log Report",
        Order: 1,
        ParentId: MainMenus.Reports,
        MenuId: Menus.ReportsAuditLogReport.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-clock-rotate-left smb-icon",
    },
    inventory: {
        Name: "Inventory",
        Order: 2,
        ParentId: MainMenus.Reports,
        MenuId: Menus.ReportsInventory.routeName,
        HasSubMenu: false,
        icon : "fa-solid fa-clock-rotate-left smb-icon",
    },
};

export const menuList = [
    {
        Name: "Dashboard",
        Order: 1,
        ParentId: null,
        MenuId: MainMenus.Dashboard,
        HasSubMenu: false,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-gauge smb-icon"
    },
    {
        Name: "Clients",
        Order: 2,
        ParentId: null,
        MenuId: MainMenus.Clients,
        HasSubMenu: false,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-users smb-icon",
    },
    {
        Name: "Centers",
        Order: 3,
        ParentId: null,
        MenuId: MainMenus.Centers,
        HasSubMenu: false,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-house-chimney-medical smb-icon",
    },
    {
        Name: "Patients",
        Order: 4,
        ParentId: null,
        MenuId: MainMenus.Patients,
        HasSubMenu: false,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-hospital-user smb-icon",
    },
    {
        Name: "Locations",
        Order: 5,
        ParentId: null,
        MenuId: MainMenus.Locations,
        HasSubMenu: false,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-location-crosshairs fa-fw",
    },
    // {
    //     Name: "Sample Master",
    //     Order: 6,
    //     ParentId: null,
    //     MenuId: MainMenus.SampleMaster,
    //     HasSubMenu: true,
    //     DisplayInSideMenu: true,
    //     icon : "fa-solid fa-vial smb-icon",
    //     SubMenu: [SampleMasterMenus.sampleCollection, SampleMasterMenus.sampleConfirmation, SampleMasterMenus.landingScreen, SampleMasterMenus.manageInventory],
    // },
    // {
    //     Name: "Inventory Management",
    //     Order: 7,
    //     ParentId: null,
    //     MenuId: MainMenus.InventoryManagement,
    //     HasSubMenu: true,
    //     DisplayInSideMenu: true,
    //     icon : "fa-solid fa-warehouse smb-icon",
    //     SubMenu: [InventoryManagementMenus.embryo, InventoryManagementMenus.oocyte, InventoryManagementMenus.sperm],
    // },
    // {
    //     Name: "Long-Term Facility",
    //     Order: 8,
    //     ParentId: null,
    //     MenuId: MainMenus.LongTermFacility,
    //     HasSubMenu: false,
    //     DisplayInSideMenu: true,
    //     icon : "fa-solid fa-hospital smb-icon",
    // },
    // {
    //     Name: "Manage Shipment",
    //     Order: 9,
    //     ParentId: null,
    //     MenuId: MainMenus.ManageShipment,
    //     HasSubMenu: false,
    //     DisplayInSideMenu: true,
    //     icon : "fa-solid fa-truck-medical smb-icon",
    // },
    {
        Name: "Settings",
        Order: 10,
        ParentId: null,
        MenuId: MainMenus.Settings,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-gear smb-icon",
        SubMenu: [SettingsMenus.users,SettingsMenus.employee,SettingsMenus.cane,SettingsMenus.tank, SettingsMenus.cannister, SettingsMenus.patientLanguage, SettingsMenus.specimenTypeStatus, SettingsMenus.insurance,SettingsMenus.jobTitle, SettingsMenus.roles],
    },
    {
        Name: "Embryo Setup",
        Order: 11,
        ParentId: null,
        MenuId: MainMenus.EmbryoSetup,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-screwdriver-wrench smb-icon",
        SubMenu: [EmbryoSetupMenus.pgtResult, EmbryoSetupMenus.eggSource, EmbryoSetupMenus.spermSource, EmbryoSetupMenus.freezeMethod, EmbryoSetupMenus.deviceType, EmbryoSetupMenus.pgtLab, EmbryoSetupMenus.cryoTech, EmbryoSetupMenus.bxTech, EmbryoSetupMenus.tubingTech],
    },
    {
        Name: "Oocyte Setup",
        Order: 12,
        ParentId: null,
        MenuId: MainMenus.OocyteSetup,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-screwdriver-wrench smb-icon",
        SubMenu: [OocyteSetupMenus.oocyteStage, OocyteSetupMenus.oocyteDay, OocyteSetupMenus.eggSource, OocyteSetupMenus.freezeMethod, OocyteSetupMenus.deviceType, OocyteSetupMenus.caneLabel, OocyteSetupMenus.cryoTech],
    },
    {
        Name: "Sperm Setup",
        Order: 13,
        ParentId: null,
        MenuId: MainMenus.SpermSetup,
        HasSubMenu: true,
        DisplayInSideMenu: true,
        icon : "fa-solid fa-screwdriver-wrench smb-icon",
        SubMenu: [SpermSetupMenus.sampleType, SpermSetupMenus.spermSouurce, SpermSetupMenus.freezeMethod, SpermSetupMenus.vialType, SpermSetupMenus.cryoTech],
    },
    // {
    //     Name: "Configuration",
    //     Order: 14,
    //     ParentId: null,
    //     MenuId: MainMenus.Configuration,
    //     HasSubMenu: true,
    //     DisplayInSideMenu: true,
    //     icon : "fa-solid fa-screwdriver-wrench smb-icon",
    //     SubMenu: [configurationMenus.lookupMaster],
    // },
    // {
    //     Name: "Reports",
    //     Order: 15,
    //     ParentId: null,
    //     MenuId: MainMenus.Reports,
    //     HasSubMenu: true,
    //     DisplayInSideMenu: true,
    //     icon : "fa-solid fa-file-lines smb-icon",
    //     SubMenu: [ReportsMenus.auditLogReport, ReportsMenus.inventory],
    // },

];