import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { changeStatusEmbryoEggSourceAction, getAllEmbryoEggSource, getEmbryoEggSourceAction, insertUpdateEmbryoEggSourceAction, getAllSortingEggSource, updateEggSourceDisplayOrder } from "../../actions/embryoSetupAction/eggSourceAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    embryoEggSource: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedEmbryoEggSource: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateEmbryoEggSource: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusEmbryoEggSource: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    sortingEggSourceData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateEggSourceDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const embryoEggSourceReducer = createSlice({
    name: "EmbryoEggSource",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetEmbryoEggSourceState(state, action) {
            state.selectedEmbryoEggSource = INITIAL_STATE.selectedEmbryoEggSource;
            state.changeStatusEmbryoEggSource = INITIAL_STATE.changeStatusEmbryoEggSource
        },
        ResetEmbryoEggSourceAllDataState(state, action) {
            state.embryoEggSource = INITIAL_STATE.embryoEggSource;
        },
        ResetEggSourceInsertUpdateDetails(state, action) {
            state.insertUpdateEmbryoEggSource = INITIAL_STATE.insertUpdateEmbryoEggSource;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateEggSourceDisplayOrderData = INITIAL_STATE.updateEggSourceDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllEmbryoEggSource start--------------------

        builder.addCase(getAllEmbryoEggSource.fulfilled, (state, action) => {
            state.embryoEggSource.data.records = action.payload.data.records;
            state.embryoEggSource.apiMsg.status = action.meta.requestStatus;
            state.embryoEggSource.apiMsg.message = action.payload.message.message;
            state.embryoEggSource.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllEmbryoEggSource.pending, (state, action) => {

            state.embryoEggSource.data.records = null;
            state.embryoEggSource.apiMsg.message = action.meta.requestStatus;;
            state.embryoEggSource.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllEmbryoEggSource.rejected, (state, action) => {

            state.embryoEggSource.apiMsg.message = action.error.message;
            state.embryoEggSource.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllEmbryoEggSource Action end--------------------

        //   getEmbryoEggSourceAction start

        builder.addCase(getEmbryoEggSourceAction.fulfilled, (state, action) => {
            state.selectedEmbryoEggSource.data = action.payload.data
            state.selectedEmbryoEggSource.apiMsg.status = action.meta.requestStatus;
            state.selectedEmbryoEggSource.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getEmbryoEggSourceAction.pending, (state, action) => {
            state.selectedEmbryoEggSource.data = null;
            state.selectedEmbryoEggSource.apiMsg.message = action.meta.requestStatus;
            state.selectedEmbryoEggSource.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getEmbryoEggSourceAction.rejected, (state, action) => {
            state.selectedEmbryoEggSource.apiMsg.message = action.error.message;
            state.selectedEmbryoEggSource.apiMsg.status = action.meta.requestStatus;
        });


        //   getEmbryoEggSourceAction end



        // insertUpdateEmbryoEggSourceAction start

        builder.addCase(
            insertUpdateEmbryoEggSourceAction.fulfilled,
            (state, action) => {
                state.insertUpdateEmbryoEggSource.data = action.payload.data.data;
                state.insertUpdateEmbryoEggSource.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateEmbryoEggSource.apiMsg.message = action.payload.message.message;
                state.insertUpdateEmbryoEggSource.newRecord = !action.meta.arg.eggSourceID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.embryoEggSource.data.records;


                    const index = existingRecords.findIndex(record => record.eggSourceID === newData.eggSourceID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.embryoEggSource.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.embryoEggSource.data.records = state.embryoEggSource.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.eggSourceID === newData.eggSourceID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateEmbryoEggSourceAction.pending,
            (state, action) => {
                state.insertUpdateEmbryoEggSource.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateEmbryoEggSourceAction.rejected,
            (state, action) => {

                state.insertUpdateEmbryoEggSource.apiMsg.message = action.error.message;
                state.insertUpdateEmbryoEggSource.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insertUpdateEmbryoEggSourceAction end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusEmbryoEggSourceAction.fulfilled,
            (state, action) => {

                state.changeStatusEmbryoEggSource.data = action.payload.data;
                state.changeStatusEmbryoEggSource.apiMsg.status = action.meta.requestStatus;
                state.changeStatusEmbryoEggSource.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.embryoEggSource.data.records = state.embryoEggSource.data.records.filter(record => record.eggSourceID !== deletedId);
                    state.embryoEggSource.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.embryoEggSource.data.records = state.embryoEggSource.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.eggSourceID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.embryoEggSource.data.records = state.embryoEggSource.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.eggSourceID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.embryoEggSource.data.records = state.embryoEggSource.data.records.map(record => {
                            return {
                                ...record,
                                active: record.eggSourceID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.embryoEggSource.data.records = state.embryoEggSource.data.records.map(record => {
                            return {
                                ...record,
                                active: record.eggSourceID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusEmbryoEggSourceAction.pending,
            (state, action) => {
                state.changeStatusEmbryoEggSource.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusEmbryoEggSourceAction.rejected,
            (state, action) => {
                state.changeStatusEmbryoEggSource.apiMsg.message = action.error.message;
                state.changeStatusEmbryoEggSource.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

//--------------------- update EggSource Display Order --------------------------//

builder.addCase(updateEggSourceDisplayOrder.fulfilled, (state, action) => {
    state.updateEggSourceDisplayOrderData.data = action.payload.data
    state.updateEggSourceDisplayOrderData.apiMsg.status = action.meta.requestStatus;
    state.updateEggSourceDisplayOrderData.apiMsg.message = action.payload.message.message;

    toast.success(action.payload.message.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "success"
    });
});

builder.addCase(updateEggSourceDisplayOrder.pending, (state, action) => {
    state.updateEggSourceDisplayOrderData.data = null;
    state.updateEggSourceDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
    state.updateEggSourceDisplayOrderData.apiMsg.status = action.meta.requestStatus;
});

builder.addCase(updateEggSourceDisplayOrder.rejected, (state, action) => {
    state.updateEggSourceDisplayOrderData.apiMsg.message = action.error.message;
    state.updateEggSourceDisplayOrderData.apiMsg.status = action.meta.requestStatus;
    toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "error"
    });
});


//--------------------- Get All Sorting Egg Source --------------------------//


builder.addCase(getAllSortingEggSource.fulfilled, (state, action) => {
    state.sortingEggSourceData.data.records = action.payload.data.records;
    state.sortingEggSourceData.apiMsg.status = action.meta.requestStatus;
    state.sortingEggSourceData.apiMsg.message = action.payload.message.message;
    state.sortingEggSourceData.data.totalRecords = action.payload.data.totalRecords;

});
builder.addCase(getAllSortingEggSource.pending, (state, action) => {

    state.sortingEggSourceData.data.records = null;
    state.sortingEggSourceData.apiMsg.message = action.meta.requestStatus;;
    state.sortingEggSourceData.apiMsg.status = action.meta.requestStatus;
});
builder.addCase(getAllSortingEggSource.rejected, (state, action) => {

    state.sortingEggSourceData.apiMsg.message = action.error.message;
    state.sortingEggSourceData.apiMsg.status = action.meta.requestStatus;
});



    },
});
export const { RESET, ResetEmbryoEggSourceState, ResetDisplayOrderUpdateDetails, ResetEggSourceInsertUpdateDetails, ResetEmbryoEggSourceAllDataState } = embryoEggSourceReducer.actions;
export default embryoEggSourceReducer.reducer;