import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingSpecimenStatus, getSettingsSpecimenStatusAction, insertUpdateSettingsSpecimenStatusAction, changeStatusSettingsSpecimenStatustAction, updateSettingsSpecimenStatusDisplayOrder, getAllSortingSettingsSpecimenStatus, getAllSettingSpecimenStatusDropdownList } from "../../actions/settingsAction/specimenStatusAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    settingSpecimenStatus: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    settingSpecimenStatusDropdownList: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingSpecimenStatus: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingSpecimenStatus: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingSpecimenStatus: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingSpecimenStatusData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateSettingSpecimenStatusDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const settingSpecimenStatusReducer = createSlice({
    name: "settingSpecimenStatusdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingSpecimenStatusState(state, action) {
            state.selectedSettingSpecimenStatus = INITIAL_STATE.selectedSettingSpecimenStatus;
            state.changeStatusSettingSpecimenStatus = INITIAL_STATE.changeStatusSettingSpecimenStatus;
        },
        ResetSettingSpecimenStatusAllDataState(state, action) {
            state.settingSpecimenStatus = INITIAL_STATE.settingSpecimenStatus;
        },
        ResetSettingSpecimenStatusInsertUpdateDetails(state, action) {
            state.insertUpdateSettingSpecimenStatus = INITIAL_STATE.insertUpdateSettingSpecimenStatus;
        },
        ResetDisplayOrderUpdateDetails(state, action) {
            state.updateSettingSpecimenStatusDisplayOrderData = INITIAL_STATE.updateSettingSpecimenStatusDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllSettingSpecimenStatus start--------------------

        builder.addCase(getAllSettingSpecimenStatus.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingSpecimenStatus.data.records = action.payload.data.records;
            state.settingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
            state.settingSpecimenStatus.apiMsg.message = action.payload.message.message;
            state.settingSpecimenStatus.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingSpecimenStatus.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingSpecimenStatus.pending, (state, action) => {

            state.settingSpecimenStatus.data.records = null;
            state.settingSpecimenStatus.apiMsg.message = action.meta.requestStatus;;
            state.settingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingSpecimenStatus.rejected, (state, action) => {

            state.settingSpecimenStatus.apiMsg.message = action.error.message;
            state.settingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingSpecimenStatus Action end--------------------

        //  --------getAllSettingSpecimenStatusDropdownList start--------------------

        builder.addCase(getAllSettingSpecimenStatusDropdownList.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingSpecimenStatusDropdownList.data.records = action.payload.data.records;
            state.settingSpecimenStatusDropdownList.apiMsg.status = action.meta.requestStatus;
            state.settingSpecimenStatusDropdownList.apiMsg.message = action.payload.message.message;
            state.settingSpecimenStatusDropdownList.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingSpecimenStatusDropdownList.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingSpecimenStatusDropdownList.pending, (state, action) => {

            state.settingSpecimenStatusDropdownList.data.records = null;
            state.settingSpecimenStatusDropdownList.apiMsg.message = action.meta.requestStatus;;
            state.settingSpecimenStatusDropdownList.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingSpecimenStatusDropdownList.rejected, (state, action) => {

            state.settingSpecimenStatusDropdownList.apiMsg.message = action.error.message;
            state.settingSpecimenStatusDropdownList.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingSpecimenStatusDropdownList Action end--------------------


        //   getClient start

        builder.addCase(getSettingsSpecimenStatusAction.fulfilled, (state, action) => {
            state.selectedSettingSpecimenStatus.data = action.payload.data
            state.selectedSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingSpecimenStatus.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsSpecimenStatusAction.pending, (state, action) => {
            state.selectedSettingSpecimenStatus.data = null;
            state.selectedSettingSpecimenStatus.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsSpecimenStatusAction.rejected, (state, action) => {
            state.selectedSettingSpecimenStatus.apiMsg.message = action.error.message;
            state.selectedSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsSpecimenStatusAction start

        builder.addCase(
            insertUpdateSettingsSpecimenStatusAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingSpecimenStatus.data = action.payload.data.data;
                state.insertUpdateSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingSpecimenStatus.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingSpecimenStatus.newRecord = !action.meta.arg.statusMasterID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingSpecimenStatus.data.records;


                    const index = existingRecords.findIndex(record => record.statusMasterID === newData.statusMasterID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            statusID : newData.statusID,
                            name: newData.name,
                            displayName: newData.displayName,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.settingSpecimenStatus.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.settingSpecimenStatus.data.records = state.settingSpecimenStatus.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.statusMasterID === newData.statusMasterID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsSpecimenStatusAction.pending,
            (state, action) => {
                state.insertUpdateSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsSpecimenStatusAction.rejected,
            (state, action) => {

                state.insertUpdateSettingSpecimenStatus.apiMsg.message = action.error.message;
                state.insertUpdateSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsSpecimenStatustAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingSpecimenStatus.data = action.payload.data;
                state.changeStatusSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingSpecimenStatus.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.settingSpecimenStatus.data.records = state.settingSpecimenStatus.data.records.filter(record => record.statusMasterID !== deletedId);
                    state.settingSpecimenStatus.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.DefaultSelectedOn) {

                        state.settingSpecimenStatus.data.records = state.settingSpecimenStatus.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.statusMasterID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.DefaultSelectedOff) {
                        state.settingSpecimenStatus.data.records = state.settingSpecimenStatus.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.statusMasterID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.Active) {
                        state.settingSpecimenStatus.data.records = state.settingSpecimenStatus.data.records.map(record => {
                            return {
                                ...record,
                                active: record.statusMasterID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.SpecimenTypeStatus.ChangeStatus.Inactive) {
                        state.settingSpecimenStatus.data.records = state.settingSpecimenStatus.data.records.map(record => {
                            return {
                                ...record,
                                active: record.statusMasterID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsSpecimenStatustAction.pending,
            (state, action) => {
                state.changeStatusSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsSpecimenStatustAction.rejected,
            (state, action) => {
                state.changeStatusSettingSpecimenStatus.apiMsg.message = action.error.message;
                state.changeStatusSettingSpecimenStatus.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateSettingsSpecimenStatusDisplayOrder.fulfilled, (state, action) => {
            state.updateSettingSpecimenStatusDisplayOrderData.data = action.payload.data
            state.updateSettingSpecimenStatusDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateSettingSpecimenStatusDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateSettingsSpecimenStatusDisplayOrder.pending, (state, action) => {
            state.updateSettingSpecimenStatusDisplayOrderData.data = null;
            state.updateSettingSpecimenStatusDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateSettingSpecimenStatusDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateSettingsSpecimenStatusDisplayOrder.rejected, (state, action) => {
            state.updateSettingSpecimenStatusDisplayOrderData.apiMsg.message = action.error.message;
            state.updateSettingSpecimenStatusDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingSettingsSpecimenStatus.fulfilled, (state, action) => {
            state.sortingSettingSpecimenStatusData.data.records = action.payload.data.records;
            state.sortingSettingSpecimenStatusData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingSpecimenStatusData.apiMsg.message = action.payload.message.message;
            state.sortingSettingSpecimenStatusData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsSpecimenStatus.pending, (state, action) => {

            state.sortingSettingSpecimenStatusData.data.records = null;
            state.sortingSettingSpecimenStatusData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingSpecimenStatusData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsSpecimenStatus.rejected, (state, action) => {

            state.sortingSettingSpecimenStatusData.apiMsg.message = action.error.message;
            state.sortingSettingSpecimenStatusData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetSettingSpecimenStatusState, ResetSettingSpecimenStatusAllDataState, ResetSettingSpecimenStatusInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = settingSpecimenStatusReducer.actions;
export default settingSpecimenStatusReducer.reducer;