import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllPartners, getPartnersAction, changeStatusPartnersAction, insertUpdatePartnersAction, getAllSortingPartners } from "../actions/partnersAction";
import { Enums } from "../../helper/constants/enums";
import moment from "moment";

const INITIAL_STATE = {
    allPartners: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPartners: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdatePartners: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusPartners: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingPartnersData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const partnersReducer = createSlice({
    name: "partnersdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPartnersState(state, action) {
            state.selectedPartners = INITIAL_STATE.selectedPartners;
            state.changeStatusPartners = INITIAL_STATE.changeStatusPartners;
        },
        ResetPartnersAllDataState(state, action) {
            state.allPartners = INITIAL_STATE.allPartners;
        },
        ResetPartnersInsertUpdateDetails(state, action) {
            state.insertUpdatePartners = INITIAL_STATE.insertUpdatePartners;
        },
    },
    extraReducers: (builder) => {

        //  --------getAllPartners start--------------------

        builder.addCase(getAllPartners.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.allPartners.data.records = action.payload.data.records;
            state.allPartners.apiMsg.status = action.meta.requestStatus;
            state.allPartners.apiMsg.message = action.payload.message.message;
            state.allPartners.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.allPartners.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllPartners.pending, (state, action) => {

            state.allPartners.data.records = null;
            state.allPartners.apiMsg.message = action.meta.requestStatus;;
            state.allPartners.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPartners.rejected, (state, action) => {

            state.allPartners.apiMsg.message = action.error.message;
            state.allPartners.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllPartners Action end--------------------

        //   getPatients start

        builder.addCase(getPartnersAction.fulfilled, (state, action) => {
            state.selectedPartners.data = action.payload.data
            state.selectedPartners.apiMsg.status = action.meta.requestStatus;
            state.selectedPartners.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPartnersAction.pending, (state, action) => {
            state.selectedPartners.data = null;
            state.selectedPartners.apiMsg.message = action.meta.requestStatus;;
            state.selectedPartners.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPartnersAction.rejected, (state, action) => {
            state.selectedPartners.apiMsg.message = action.error.message;
            state.selectedPartners.apiMsg.status = action.meta.requestStatus;
        });


        //   getPatients end



        // insertUpdatePartnersAction start

        builder.addCase(
            insertUpdatePartnersAction.fulfilled,
            (state, action) => {
                state.insertUpdatePartners.data = action.payload.data.data;
                state.insertUpdatePartners.apiMsg.status = action.meta.requestStatus;
                state.insertUpdatePartners.apiMsg.message = action.payload.message.message;
                state.insertUpdatePartners.newRecord = !action.meta.arg.patientID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.allPartners.data.records;


                    const index = existingRecords.findIndex(record => record.patientID === newData.patientID);
                    // console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            clientID: newData.clientID,
                            centerID : newData.centerID,
                            locationID: newData.locationID,
                            languageID : newData.languageID,
                            userID : newData.userID,
                            provider : newData.provider,
                            firstName : newData.firstName,
                            middleName : newData.middleName,
                            lastName : newData.lastName,
                            dob : moment(newData.dob).format("MM/DD/YYYY"),
                            gender : newData.gender,
                            genderIdentity :newData.genderIdentity,
                            emailID : newData.emailID,
                            phone : newData.phone,
                            fax : newData.fax,
                            cell : newData.cell,
                            barcode : newData.barcode,
                            otherDetails : newData.otherDetails,
                            address : newData.address,
                            city : newData.city,
                            state : newData.state,
                            zipCode : newData.zipCode,
                            mailAddressDifferentAsAddress : newData.mailAddressDifferentAsAddress,
                            maillAddress : newData.mailAddress,
                            mailCity : newData.mailCity,
                            mailState : newData.mailState,
                            mailZipCode : newData.mailZipCode,
                            fromDate : moment(newData.fromDate).format("MM/DD/YYYY"),
                            toDate : newData.toDate ? moment(newData.toDate).format("MM/DD/YYYY") : null,
                            parentPatientID : newData.parentPatientID,
                            active: newData.active,
                        };
                    }
                    else {
                        state.allPartners.data.totalRecords += 1;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdatePartnersAction.pending,
            (state, action) => {
                state.insertUpdatePartners.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePartnersAction.rejected,
            (state, action) => {

                state.insertUpdatePartners.apiMsg.message = action.error.message;
                state.insertUpdatePartners.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update patient action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusPartnersAction.fulfilled,
            (state, action) => {

                state.changeStatusPartners.data = action.payload.data;
                state.changeStatusPartners.apiMsg.status = action.meta.requestStatus;
                state.changeStatusPartners.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.allPartners.data.records = state.allPartners.data.records.filter(record => record.patientID !== deletedId);
                    state.allPartners.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Active) {
                        state.allPartners.data.records = state.allPartners.data.records.map(record => {
                            return {
                                ...record,
                                active: record.patientID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Inactive) {
                        state.allPartners.data.records = state.allPartners.data.records.map(record => {
                            return {
                                ...record,
                                active: record.patientID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusPartnersAction.pending,
            (state, action) => {
                state.changeStatusPartners.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusPartnersAction.rejected,
            (state, action) => {
                state.changeStatusPartners.apiMsg.message = action.error.message;
                state.changeStatusPartners.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(getAllSortingPartners.fulfilled, (state, action) => {
            state.sortingPartnersData.data.records = action.payload.data.records;
            state.sortingPartnersData.apiMsg.status = action.meta.requestStatus;
            state.sortingPartnersData.apiMsg.message = action.payload.message.message;
            state.sortingPartnersData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingPartners.pending, (state, action) => {

            state.sortingPartnersData.data.records = null;
            state.sortingPartnersData.apiMsg.message = action.meta.requestStatus;;
            state.sortingPartnersData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingPartners.rejected, (state, action) => {

            state.sortingPartnersData.apiMsg.message = action.error.message;
            state.sortingPartnersData.apiMsg.status = action.meta.requestStatus;
        });


    },
});
export const { RESET, ResetPartnersState, ResetPartnersAllDataState, ResetPartnersInsertUpdateDetails } = partnersReducer.actions;
export default partnersReducer.reducer;