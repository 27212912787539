import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingsInsurance, getSettingsInsuranceAction, insertUpdateSettingsInsuranceAction, changeStatusSettingsInsuranceAction, updateSettingsInsuranceDisplayOrder, getAllSortingSettingsInsurance } from "../../actions/settingsAction/insuranceAction";
import { Enums } from "../../../helper/constants/enums";

const INITIAL_STATE = {
    settingsInsurance: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingsInsurance: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingsInsurance: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingsInsurance: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingsInsuranceData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateSettingsInvoiceDisplayOrderData: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
}

const settingsInsuranceReducer = createSlice({
    name: "settingsInsurancedata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingsInvoiceState(state, action) {
            state.selectedSettingsInsurance = INITIAL_STATE.selectedSettingsInsurance;
            state.changeStatusSettingsInsurance = INITIAL_STATE.changeStatusSettingsInsurance;
        },
        ResetSettingsInvoiceAllDataState(state, action) {
            state.settingsInsurance = INITIAL_STATE.settingsInsurance;
        },
        ResetSettingsInvoiceInsertUpdateDetails(state, action) {
            state.insertUpdateSettingsInsurance = INITIAL_STATE.insertUpdateSettingsInsurance;
        },
        ResetDisplayOrderUpdateDetails(state,action){
            state.updateSettingsInvoiceDisplayOrderData = INITIAL_STATE.updateSettingsInvoiceDisplayOrderData

        }


    },
    extraReducers: (builder) => {

        //  --------getAllSettingsInsurance start--------------------

        builder.addCase(getAllSettingsInsurance.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingsInsurance.data.records = action.payload.data.records;
            state.settingsInsurance.apiMsg.status = action.meta.requestStatus;
            state.settingsInsurance.apiMsg.message = action.payload.message.message;
            state.settingsInsurance.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsInsurance.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsInsurance.pending, (state, action) => {

            state.settingsInsurance.data.records = null;
            state.settingsInsurance.apiMsg.message = action.meta.requestStatus;;
            state.settingsInsurance.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsInsurance.rejected, (state, action) => {

            state.settingsInsurance.apiMsg.message = action.error.message;
            state.settingsInsurance.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingsInsurance Action end--------------------

        //   getClient start

        builder.addCase(getSettingsInsuranceAction.fulfilled, (state, action) => {
            state.selectedSettingsInsurance.data = action.payload.data
            state.selectedSettingsInsurance.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingsInsurance.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsInsuranceAction.pending, (state, action) => {
            state.selectedSettingsInsurance.data = null;
            state.selectedSettingsInsurance.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingsInsurance.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsInsuranceAction.rejected, (state, action) => {
            state.selectedSettingsInsurance.apiMsg.message = action.error.message;
            state.selectedSettingsInsurance.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsInsuranceAction start

        builder.addCase(
            insertUpdateSettingsInsuranceAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingsInsurance.data = action.payload.data.data;
                state.insertUpdateSettingsInsurance.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingsInsurance.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingsInsurance.newRecord = !action.meta.arg.insuranceID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingsInsurance.data.records;


                    const index = existingRecords.findIndex(record => record.insuranceID === newData.insuranceID);
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            name: newData.name,
                            active: newData.active,
                            defaultSelected: newData.defaultSelected
                        };
                    }
                    else {
                        state.settingsInsurance.data.totalRecords += 1;
                    }


                    if (newData.defaultSelected === true) {
                        state.settingsInsurance.data.records = state.settingsInsurance.data.records.map(record => ({
                            ...record,
                            defaultSelected: record.insuranceID === newData.insuranceID
                        }));
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsInsuranceAction.pending,
            (state, action) => {
                state.insertUpdateSettingsInsurance.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsInsuranceAction.rejected,
            (state, action) => {

                state.insertUpdateSettingsInsurance.apiMsg.message = action.error.message;
                state.insertUpdateSettingsInsurance.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsInsuranceAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingsInsurance.data = action.payload.data;
                state.changeStatusSettingsInsurance.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingsInsurance.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.settingsInsurance.data.records = state.settingsInsurance.data.records.filter(record => record.insuranceID !== deletedId);
                    state.settingsInsurance.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOn) {

                        state.settingsInsurance.data.records = state.settingsInsurance.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.insuranceID === action.meta.arg.Id ? true : false
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.DefaultSelectedOff) {
                        state.settingsInsurance.data.records = state.settingsInsurance.data.records.map(record => {
                            return {
                                ...record,
                                defaultSelected: record.insuranceID === action.meta.arg.Id ? false : record.defaultSelected
                            };
                        });
                    }
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Active) {
                        state.settingsInsurance.data.records = state.settingsInsurance.data.records.map(record => {
                            return {
                                ...record,
                                active: record.insuranceID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Settings.ChangeStatus.Inactive) {
                        state.settingsInsurance.data.records = state.settingsInsurance.data.records.map(record => {
                            return {
                                ...record,
                                active: record.insuranceID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsInsuranceAction.pending,
            (state, action) => {
                state.changeStatusSettingsInsurance.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsInsuranceAction.rejected,
            (state, action) => {
                state.changeStatusSettingsInsurance.apiMsg.message = action.error.message;
                state.changeStatusSettingsInsurance.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(updateSettingsInsuranceDisplayOrder.fulfilled, (state, action) => {
            state.updateSettingsInvoiceDisplayOrderData.data = action.payload.data
            state.updateSettingsInvoiceDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            state.updateSettingsInvoiceDisplayOrderData.apiMsg.message = action.payload.message.message;

            toast.success(action.payload.message.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "success"
            });
        });

        builder.addCase(updateSettingsInsuranceDisplayOrder.pending, (state, action) => {
            state.updateSettingsInvoiceDisplayOrderData.data = null;
            state.updateSettingsInvoiceDisplayOrderData.apiMsg.message = action.meta.requestStatus;;
            state.updateSettingsInvoiceDisplayOrderData.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(updateSettingsInsuranceDisplayOrder.rejected, (state, action) => {
            state.updateSettingsInvoiceDisplayOrderData.apiMsg.message = action.error.message;
            state.updateSettingsInvoiceDisplayOrderData.apiMsg.status = action.meta.requestStatus;
            toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                position: toast.POSITION.TOP_CENTER,
                toastId: "error"
            });
        });



        builder.addCase(getAllSortingSettingsInsurance.fulfilled, (state, action) => {
            state.sortingSettingsInsuranceData.data.records = action.payload.data.records;
            state.sortingSettingsInsuranceData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingsInsuranceData.apiMsg.message = action.payload.message.message;
            state.sortingSettingsInsuranceData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsInsurance.pending, (state, action) => {

            state.sortingSettingsInsuranceData.data.records = null;
            state.sortingSettingsInsuranceData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingsInsuranceData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsInsurance.rejected, (state, action) => {

            state.sortingSettingsInsuranceData.apiMsg.message = action.error.message;
            state.sortingSettingsInsuranceData.apiMsg.status = action.meta.requestStatus;
        });



    },
});
export const { RESET, ResetSettingsInvoiceState, ResetSettingsInvoiceAllDataState, ResetSettingsInvoiceInsertUpdateDetails, ResetDisplayOrderUpdateDetails } = settingsInsuranceReducer.actions;
export default settingsInsuranceReducer.reducer;