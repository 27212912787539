import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../../API/api-urls";
import { apiCall } from "../../../API/index";
import { Enums } from "../../../helper/constants/enums";

export const getAllSettingSpecimenStatus = createAsyncThunk(
    "SettingsSpecimenStatus/getAllSettingSpecimenStatus",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize:data.pageSize !== undefined && data.pageSize !== "" ? JSON.parse(data.pageSize)  : Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                StatusID : data.statusID ? data.statusID : null,
                DisplayName: data.displayName ? data.displayName : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null,
                DefaultSelected: data.defaultSelected !== undefined && data.defaultSelected !== "" ? JSON.parse(data.defaultSelected) : null,
                RequireDefaultStatus : data.requireDefaultStatus ? data.requireDefaultStatus : null
            }
        };

        return apiCall(`${apiUrls.settingsSpecimenStatus.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSettingSpecimenStatusDropdownList = createAsyncThunk(
    "SettingsSpecimenStatus/getAllSettingSpecimenStatusDropdownList",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize:data.pageSize !== undefined && data.pageSize !== "" ? JSON.parse(data.pageSize)  : Enums.PaginationSettings.DefaultPageSize,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                ClientID: null,
                StatusID : data.statusID ? data.statusID : null,
                DisplayName: data.displayName ? data.displayName : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null,
                DefaultSelected: data.defaultSelected !== undefined && data.defaultSelected !== "" ? JSON.parse(data.defaultSelected) : null,
                RequireDefaultStatus : data.requireDefaultStatus ? data.requireDefaultStatus : null
            }
        };

        return apiCall(`${apiUrls.settingsSpecimenStatus.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getSettingsSpecimenStatusAction = createAsyncThunk(
    "SettingsSpecimenStatus/getSettingsSpecimenStatus",
    async (data) => {
        let d = {
            Device: 1,
            Id: data
        };
        return apiCall(`${apiUrls.settingsSpecimenStatus.getSettingsSpecimenStatus}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const insertUpdateSettingsSpecimenStatusAction = createAsyncThunk(
    "SettingsSpecimenStatus/settingsSpecimenStatusInsertUpdate",
    async (data) => {
        return apiCall(`${apiUrls.settingsSpecimenStatus.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.statusMasterID ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusSettingsSpecimenStatustAction = createAsyncThunk(
    "SettingsSpecimenStatus/changeStatusSettingsSpecimenStatus",
    async (data) => {
        // let d = {
        //     Device: 1,
        //     Id: data.statusMasterID,
        //     Statustype: 3
        //   };
        return apiCall(`${apiUrls.settingsSpecimenStatus.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingSettingsSpecimenStatus = createAsyncThunk(
    "SettingsSpecimenStatus/getAllSortingSettingsSpecimenStatus",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.settingsSpecimenStatus.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const updateSettingsSpecimenStatusDisplayOrder = createAsyncThunk(
    "SettingsSpecimenStatus/insertupdatedisplayorder",
    async (data) => {
        return apiCall(`${apiUrls.settingsSpecimenStatus.updateDisplayOrder}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);