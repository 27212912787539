import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../../API/api-urls";
import { apiCall } from "../../../API/index";
import { Enums } from "../../../helper/constants/enums";

export const getAllEmbryoDeviceTypes = createAsyncThunk(
    "EmbryoDeviceType/getAllEmbryoDeviceTypes",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,
            PageSize: Enums.PaginationSettings.DefaultPageSize,
            // PageNo: data && data.PageNo != null ? data.PageNo : 1,
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
                Name: data.name ? data.name : null,
                Active: data.active !== undefined && data.active !== "" ? JSON.parse(data.active) : null,
                DefaultSelected: data.defaultSelected !== undefined && data.defaultSelected !== "" ? JSON.parse(data.defaultSelected) : null
            }
        };
        return apiCall(`${apiUrls.embryoDeviceType.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    // console.log(response,'device type response');
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getEmbryoDeviceTypeAction = createAsyncThunk(
    "EmbryoDeviceType/getEmbryoPDeviceType",
    async (data) => {
        let d = {
            Device: 1,
            Id: data
        };
        return apiCall(`${apiUrls.embryoDeviceType.getEmbryoDeviceType}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const insertUpdateEmbryoDeviceTypeAction = createAsyncThunk(
    "EmbryoDeviceType/embryoDeviceTypeInsertUpdate",
    async (data) => {
        return apiCall(`${apiUrls.embryoDeviceType.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.deviceTypeID ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusEmbryoDeviceTypeAction = createAsyncThunk(
    "EmbryoDeviceType/changeStatusEmbryoDeviceType",
    async (data) => {
        // let d = {
        //     Device: 1,
        //     Id: data.deviceTypeID,
        //     Statustype: 3
        //   };
        return apiCall(`${apiUrls.embryoDeviceType.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getAllSortingDeviceType = createAsyncThunk(
    "EmbryoDeviceType/getAllSortingDeviceType",
    async (data) => {
        let d = {
            Device: 1,
            PageNo: data.pageNo ? data.pageNo : 1,  
            SortBy: data.sortBy,
            SortOrder: data.sortOrder,
            Filter: {
            }
        };
        return apiCall(`${apiUrls.embryoDeviceType.getAll}`, "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const updateDeviceTypeDisplayOrder = createAsyncThunk(
    "EmbryoDeviceType/insertupdatedisplayorder",
    async (data) => {
        return apiCall(`${apiUrls.embryoDeviceType.updateDisplayOrder}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        // console.log(response,'api response');
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
