import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllPatients, getPatientsAction, changeStatusPatientsAction, insertUpdatePatientsAction, getAllSortingPatients } from "../actions/patientsAction";
import { Enums } from "../../helper/constants/enums";
import moment from "moment";

const INITIAL_STATE = {
    allPatients: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedPatients: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdatePatients: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusPatients: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingPatientsData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const patientsReducer = createSlice({
    name: "patientsdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPatientsState(state, action) {
            state.selectedPatients = INITIAL_STATE.selectedPatients;
            state.changeStatusPatients = INITIAL_STATE.changeStatusPatients;
        },
        ResetPatientsAllDataState(state, action) {
            state.allPatients = INITIAL_STATE.allPatients;
        },
        ResetPatientsInsertUpdateDetails(state, action) {
            state.insertUpdatePatients = INITIAL_STATE.insertUpdatePatients;
        },
    },
    extraReducers: (builder) => {

        //  --------getAllPatients start--------------------

        builder.addCase(getAllPatients.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.allPatients.data.records = action.payload.data.records;
            state.allPatients.apiMsg.status = action.meta.requestStatus;
            state.allPatients.apiMsg.message = action.payload.message.message;
            state.allPatients.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.allPatients.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllPatients.pending, (state, action) => {

            state.allPatients.data.records = null;
            state.allPatients.apiMsg.message = action.meta.requestStatus;;
            state.allPatients.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPatients.rejected, (state, action) => {

            state.allPatients.apiMsg.message = action.error.message;
            state.allPatients.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllPatients Action end--------------------

        //   getPatients start

        builder.addCase(getPatientsAction.fulfilled, (state, action) => {
            state.selectedPatients.data = action.payload.data
            state.selectedPatients.apiMsg.status = action.meta.requestStatus;
            state.selectedPatients.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPatientsAction.pending, (state, action) => {
            state.selectedPatients.data = null;
            state.selectedPatients.apiMsg.message = action.meta.requestStatus;;
            state.selectedPatients.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPatientsAction.rejected, (state, action) => {
            state.selectedPatients.apiMsg.message = action.error.message;
            state.selectedPatients.apiMsg.status = action.meta.requestStatus;
        });


        //   getPatients end



        // insertUpdatePatientsAction start

        builder.addCase(
            insertUpdatePatientsAction.fulfilled,
            (state, action) => {
                state.insertUpdatePatients.data = action.payload.data.data;
                state.insertUpdatePatients.apiMsg.status = action.meta.requestStatus;
                state.insertUpdatePatients.apiMsg.message = action.payload.message.message;
                state.insertUpdatePatients.newRecord = !action.meta.arg.patientID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.allPatients.data.records;


                    const index = existingRecords.findIndex(record => record.patientID === newData.patientID);
                    // console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            clientID: newData.clientID,
                            centerID : newData.centerID,
                            locationID: newData.locationID,
                            languageID : newData.languageID,
                            userID : newData.userID,
                            provider : newData.provider,
                            firstName : newData.firstName,
                            middleName : newData.middleName,
                            lastName : newData.lastName,
                            dob : moment(newData.dob).format("MM/DD/YYYY"),
                            gender : newData.gender,
                            genderIdentity :newData.genderIdentity,
                            emailID : newData.emailID,
                            phone : newData.phone,
                            fax : newData.fax,
                            cell : newData.cell,
                            barcode : newData.barcode,
                            otherDetails : newData.otherDetails,
                            address : newData.address,
                            city : newData.city,
                            state : newData.state,
                            zipCode : newData.zipCode,
                            mailAddressDifferentAsAddress : newData.mailAddressDifferentAsAddress,
                            maillAddress : newData.mailAddress,
                            mailCity : newData.mailCity,
                            mailState : newData.mailState,
                            mailZipCode : newData.mailZipCode,
                            fromDate : moment(newData.fromDate).format("MM/DD/YYYY"),
                            toDate : moment(newData.toDate).format("MM/DD/YYYY"),
                            parentPatientID : newData.parentPatientID,
                            active: newData.active,
                        };
                    }
                    else {
                        state.allPatients.data.totalRecords += 1;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdatePatientsAction.pending,
            (state, action) => {
                state.insertUpdatePatients.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePatientsAction.rejected,
            (state, action) => {

                state.insertUpdatePatients.apiMsg.message = action.error.message;
                state.insertUpdatePatients.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update patient action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusPatientsAction.fulfilled,
            (state, action) => {

                state.changeStatusPatients.data = action.payload.data;
                state.changeStatusPatients.apiMsg.status = action.meta.requestStatus;
                state.changeStatusPatients.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.allPatients.data.records = state.allPatients.data.records.filter(record => record.patientID !== deletedId);
                    state.allPatients.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Active) {
                        state.allPatients.data.records = state.allPatients.data.records.map(record => {
                            return {
                                ...record,
                                active: record.patientID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Patients.ChangeStatus.Inactive) {
                        state.allPatients.data.records = state.allPatients.data.records.map(record => {
                            return {
                                ...record,
                                active: record.patientID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusPatientsAction.pending,
            (state, action) => {
                state.changeStatusPatients.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusPatientsAction.rejected,
            (state, action) => {
                state.changeStatusPatients.apiMsg.message = action.error.message;
                state.changeStatusPatients.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(getAllSortingPatients.fulfilled, (state, action) => {
            state.sortingPatientsData.data.records = action.payload.data.records;
            state.sortingPatientsData.apiMsg.status = action.meta.requestStatus;
            state.sortingPatientsData.apiMsg.message = action.payload.message.message;
            state.sortingPatientsData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingPatients.pending, (state, action) => {

            state.sortingPatientsData.data.records = null;
            state.sortingPatientsData.apiMsg.message = action.meta.requestStatus;;
            state.sortingPatientsData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingPatients.rejected, (state, action) => {

            state.sortingPatientsData.apiMsg.message = action.error.message;
            state.sortingPatientsData.apiMsg.status = action.meta.requestStatus;
        });


    },
});
export const { RESET, ResetPatientsState, ResetPatientsAllDataState, ResetPatientsInsertUpdateDetails } = patientsReducer.actions;
export default patientsReducer.reducer;