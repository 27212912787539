import { useSelector, useDispatch } from "react-redux";
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import { StateCompleted, StateRejected } from './helper/functions/general-func';
import { UserType } from "./helper/constants/enums";


function App() {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  // console.log(isLoggedIn,'islogin');
  return (
    <>
      {
        isLoggedIn ? <AppRoutes active={isLoggedIn} /> : <AuthRoutes />
      }
    </>
  );
}

export default App;
