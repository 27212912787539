import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { LoadingProvider } from './components/shared/LoadingContext';

import "./css/App.css"
import "./css/style.css"


import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const fadeInTopTransition = cssTransition({
  enter: "fade-in-top",
  exit: "fade-out-top"
});

root.render(

  <Provider store={store}>
    <LoadingProvider>
      <BrowserRouter>
        <App />
        <ToastContainer transition={fadeInTopTransition} autoClose={3000} />
      </BrowserRouter>
    </LoadingProvider>
  </Provider>

);