import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllSettingsEmployees, getAllSortingSettingsEmployees, insertUpdateSettingsEmployeesAction, changeStatusSettingsEmployeesAction, getSettingsEmployeesAction, getAllSettingsEmployeesFromLocation } from "../../actions/settingsAction/EmployeesAction";
import { Enums } from "../../../helper/constants/enums";
import moment from "moment";
import { DateFormatConstant } from "../../../helper/constants/general-const";

const INITIAL_STATE = {
    settingsEmployees: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedSettingsEmployee: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateSettingsEmployee: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    changeStatusSettingsEmployee: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    sortingSettingsEmployeesData: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    settingsEmployeesfromLocation: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

}

const settingsEmployeesReducer = createSlice({
    name: "settingsEmployeesdata",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetSettingsEmployeesState(state, action) {
            state.selectedSettingsEmployee = INITIAL_STATE.selectedSettingsEmployee;
            state.changeStatusSettingsEmployee = INITIAL_STATE.changeStatusSettingsEmployee;
        },
        ResetSettingsEmployeesAllDataState(state, action) {
            state.settingsEmployees = INITIAL_STATE.settingsEmployees;
        },
        ResetSettingsEmployeesInsertUpdateDetails(state, action) {
            state.insertUpdateSettingsEmployee = INITIAL_STATE.insertUpdateSettingsEmployee;
        },
        ResetEmployeeFromLocationState(state, action) {
            state.settingsEmployeesfromLocation = INITIAL_STATE.settingsEmployeesfromLocation
        }
    },
    extraReducers: (builder) => {

        //  --------getAllSettingsEmployees start--------------------

        builder.addCase(getAllSettingsEmployees.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingsEmployees.data.records = action.payload.data.records;
            state.settingsEmployees.apiMsg.status = action.meta.requestStatus;
            state.settingsEmployees.apiMsg.message = action.payload.message.message;
            state.settingsEmployees.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsEmployees.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsEmployees.pending, (state, action) => {

            state.settingsEmployees.data.records = null;
            state.settingsEmployees.apiMsg.message = action.meta.requestStatus;;
            state.settingsEmployees.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsEmployees.rejected, (state, action) => {

            state.settingsEmployees.apiMsg.message = action.error.message;
            state.settingsEmployees.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllSettingsEmployees Action end--------------------

        //   getClient start

        builder.addCase(getSettingsEmployeesAction.fulfilled, (state, action) => {
            state.selectedSettingsEmployee.data = action.payload.data
            state.selectedSettingsEmployee.apiMsg.status = action.meta.requestStatus;
            state.selectedSettingsEmployee.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getSettingsEmployeesAction.pending, (state, action) => {
            state.selectedSettingsEmployee.data = null;
            state.selectedSettingsEmployee.apiMsg.message = action.meta.requestStatus;;
            state.selectedSettingsEmployee.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getSettingsEmployeesAction.rejected, (state, action) => {
            state.selectedSettingsEmployee.apiMsg.message = action.error.message;
            state.selectedSettingsEmployee.apiMsg.status = action.meta.requestStatus;
        });


        //   getClient end



        // insertUpdateSettingsEmployeesAction start

        builder.addCase(
            insertUpdateSettingsEmployeesAction.fulfilled,
            (state, action) => {
                state.insertUpdateSettingsEmployee.data = action.payload.data.data;
                state.insertUpdateSettingsEmployee.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingsEmployee.apiMsg.message = action.payload.message.message;
                state.insertUpdateSettingsEmployee.newRecord = !action.meta.arg.userID;

                if (action.meta.arg) {
                    const newData = action.meta.arg;
                    const existingRecords = state.settingsEmployees.data.records;


                    const index = existingRecords.findIndex(record => record.userID === newData.userID);
                    // console.log(newData, 'reducer call');
                    if (index !== -1) {
                        existingRecords[index] = {
                            ...existingRecords[index],
                            firstName: newData.firstName,
                            lastName: newData.lastName,
                            userName: newData.userName,
                            emailID: newData.emailID,
                            active: newData.active,
                            jobTitle: newData.jobTitle,

                            middleName: newData.middleName,
                            gender: newData.gender,
                            dob: moment(newData.dob).format("MM/DD/YYYY"),
                            jobTitleID: newData.jobTitleID,
                            npi: newData.npi,
                            dea: newData.dea,
                            phone: newData.phone,
                            fax: newData.fax,
                            inFavourOf: newData.inFavourOf,
                            note: newData.note,
                            userLocation: newData.userLocation,
                        };
                    }
                    else {
                        state.settingsEmployees.data.totalRecords += 1;
                    }

                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            insertUpdateSettingsEmployeesAction.pending,
            (state, action) => {
                state.insertUpdateSettingsEmployee.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateSettingsEmployeesAction.rejected,
            (state, action) => {

                state.insertUpdateSettingsEmployee.apiMsg.message = action.error.message;
                state.insertUpdateSettingsEmployee.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );


        //   insert update client action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            changeStatusSettingsEmployeesAction.fulfilled,
            (state, action) => {

                state.changeStatusSettingsEmployee.data = action.payload.data;
                state.changeStatusSettingsEmployee.apiMsg.status = action.meta.requestStatus;
                state.changeStatusSettingsEmployee.apiMsg.message = action.payload.message.message;

                if (action.meta.arg && action.meta.arg.StatusType === Enums.Employees.ChangeStatus.Delete) {
                    const deletedId = action.meta.arg.Id;
                    state.settingsEmployees.data.records = state.settingsEmployees.data.records.filter(record => record.userID !== deletedId);
                    state.settingsEmployees.data.totalRecords -= 1;
                }
                else if ((action.meta.arg && action.meta.arg.StatusType === Enums.Employees.ChangeStatus.Active) || (action.meta.arg && action.meta.arg.StatusType === Enums.Employees.ChangeStatus.Inactive)) {
                    if (action.meta.arg.StatusType === Enums.Employees.ChangeStatus.Active) {
                        state.settingsEmployees.data.records = state.settingsEmployees.data.records.map(record => {
                            return {
                                ...record,
                                active: record.userID === action.meta.arg.Id ? true : record.active
                            };
                        });
                    }
                    else if (action.meta.arg.StatusType === Enums.Employees.ChangeStatus.Inactive) {
                        state.settingsEmployees.data.records = state.settingsEmployees.data.records.map(record => {
                            return {
                                ...record,
                                active: record.userID === action.meta.arg.Id ? false : record.active
                            };
                        });
                    }
                }

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "success"
                });

            }
        );

        builder.addCase(
            changeStatusSettingsEmployeesAction.pending,
            (state, action) => {
                state.changeStatusSettingsEmployee.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            changeStatusSettingsEmployeesAction.rejected,
            (state, action) => {
                state.changeStatusSettingsEmployee.apiMsg.message = action.error.message;
                state.changeStatusSettingsEmployee.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.TOP_CENTER,
                    toastId: "error"
                });
            }
        );

        builder.addCase(getAllSortingSettingsEmployees.fulfilled, (state, action) => {
            state.sortingSettingsEmployeesData.data.records = action.payload.data.records;
            state.sortingSettingsEmployeesData.apiMsg.status = action.meta.requestStatus;
            state.sortingSettingsEmployeesData.apiMsg.message = action.payload.message.message;
            state.sortingSettingsEmployeesData.data.totalRecords = action.payload.data.totalRecords;

        });
        builder.addCase(getAllSortingSettingsEmployees.pending, (state, action) => {

            state.sortingSettingsEmployeesData.data.records = null;
            state.sortingSettingsEmployeesData.apiMsg.message = action.meta.requestStatus;;
            state.sortingSettingsEmployeesData.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSortingSettingsEmployees.rejected, (state, action) => {

            state.sortingSettingsEmployeesData.apiMsg.message = action.error.message;
            state.sortingSettingsEmployeesData.apiMsg.status = action.meta.requestStatus;
        });


        //  --------settingsEmployeesfromLocation start--------------------

        builder.addCase(getAllSettingsEmployeesFromLocation.fulfilled, (state, action) => {
            // console.log(action,'getall reducer action');
            state.settingsEmployeesfromLocation.data.records = action.payload.data.records;
            state.settingsEmployeesfromLocation.apiMsg.status = action.meta.requestStatus;
            state.settingsEmployeesfromLocation.apiMsg.message = action.payload.message.message;
            state.settingsEmployeesfromLocation.data.totalRecords = action.payload.data.totalRecords;
            // console.log(state.settingsEmployees.data.totalrecords,'getall reducer action');

        });
        builder.addCase(getAllSettingsEmployeesFromLocation.pending, (state, action) => {

            state.settingsEmployeesfromLocation.data.records = null;
            state.settingsEmployeesfromLocation.apiMsg.message = action.meta.requestStatus;;
            state.settingsEmployeesfromLocation.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllSettingsEmployeesFromLocation.rejected, (state, action) => {

            state.settingsEmployeesfromLocation.apiMsg.message = action.error.message;
            state.settingsEmployeesfromLocation.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- settingsEmployeesfromLocation Action end--------------------



    },
});
export const { RESET, ResetSettingsEmployeesState,ResetEmployeeFromLocationState, ResetSettingsEmployeesAllDataState, ResetSettingsEmployeesInsertUpdateDetails } = settingsEmployeesReducer.actions;
export default settingsEmployeesReducer.reducer;