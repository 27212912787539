const ReducerStateContant ={
    fulfilled:'fulfilled',
    pending:'pending',
    rejected:'rejected'
}
 const ValidationExpression = {
    Email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
    URL: /^(https?:\/\/(?:www\.)?)?([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?$/
};

const Dimensions = {
    H200W200:"200x200",
    H400W400:"400x400",
    H600W600:"600x600",
    H800W800:"800x800"
};

 const DateFormatConstant = {
    APIDateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSS[Z]',
    APITimeFormat: 'HH:mm',
    DisplayDateFormat: 'DD/MM/YYYY',
    DisplayDateFormatWithHourAndMinAndSec: 'DD/MM/YYYY HH:mm:ss',
    APIDateTimeFormat:'YYYY-MM-DDT00:00:00'
}

export {ReducerStateContant,ValidationExpression,Dimensions, DateFormatConstant};